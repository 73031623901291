.hero {
  position: relative;
  min-height: 250px;
  z-index: 4;
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0.2)),to(rgba(0,0,0,0.2))),-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0.2)),to(transparent));
    background: linear-gradient(180deg,rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.2) 100%),linear-gradient(180deg,rgba(0,0,0,0.2) 0%,transparent 100%);
    z-index: 1;
  }

  .carousel-item {
    height: 500px;
    z-index: 10 !important;
    cursor: pointer;
  }
 
  h1, h2 {
    color: white;
  }

  .container {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    z-index: 2;
  }

  &--slide {
    height: 100%;

    &--background {
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: -1;

      img {
        min-height: 100%;
        min-height: 100%;
      }
      
    }

    &--video {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }

    &--content {
      margin: auto auto;
      text-align: center;
      // max-width: 900px;

      .search {
        display: flex;
        justify-content: center;
      }
    }
    
    &--title {
      font-size: 32px;
      text-shadow: 0 0 1px rgba(0 , 0, 0, 0.6);
      font-weight: 600;
      padding: 0;
      margin: 0;
    }

    &--sub-title {
      font-size: 1.4rem;
    }
  }

}

.search {
  &--auto-complete {
    width: 80%;
    .ant-input-search {
      height: 50px;
      .ant-input-suffix {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}
.carousel-caption {
  display: flex;
  bottom: 39%;
  left: 0%;
  right: 0%;
}


@media screen and (max-width: 668px) {
  .carousel-caption {
    top: 15%;
    left: 4%;
    right: 4%;
  }
  .hero--slide--title {
    font-size: 24px;
    font-weight: 400;
  }

}
@media screen and (min-width: 1400px) {
  .carousel-caption {
    width: fit-content;
    margin: auto
  }
}
@media screen and (max-width: 800px) {
  .carousel-item {
    cursor:default !important;
  }
}
@media screen and (max-width: 992px) {
  .carousel-caption {
   top: 10%;
   left: 5%;
   right: 5%;
  }
  .hero--slide--title {
    font-weight: 400;
  }
}
.top-search {
  background-color: rgba(0,0,0,0.35);
  border-radius: 100px;
  display: flex;
  margin: 5px 7px;
  cursor: pointer;
  height:35px;
}
.top-search svg {
  width: 18px;
  height: 24px;
  margin: 5px 0px 5px 5px;
  stroke: #ffffff !important;
  fill: #ffffff !important;
  padding-left: 3px;
}
.top-search p {
  appearance: none;
  padding: 6px;
  padding-right: 18px;
  padding-left: 5px;
  border: none;
  background: none;
  font: inherit;
  width: auto;
  outline: none;
  color: #ffffff;
}
