.chip {
  display: inline-flex;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  border-radius: 25px;
  background-color: #eee;
  margin-right: 16px;
  margin-top: 16px;
  white-space: nowrap;
  align-items: center;
}

.chip:hover {
  cursor: pointer;
  font-weight: 600;
}

.chipLabel {
  color: #444;
  font: 14px arial, sans-serif;
  margin-right: 5px;
}

.chipClose {
  color: #878585;
  margin-left: 5px;
  margin-right: 5px;
}

.chipDeleteContainer {
  padding: 2px;
  border: 0;
  background-color: transparent;
}

.chipClose:hover, .chipDeleteContainer:focus .chipClose {
  color: #51a3fc;
}
