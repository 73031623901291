button:focus {
  outline: none;
}

a:hover {
  cursor: pointer;
}

.round {
  &-1 {
    border-radius: 3px !important;
  }
}

.loading-wrapper {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.7;
  display: flex;
  justify-content: center;
}

.btn-primary {
  box-shadow: unset !important;
}

img.img-background {
  min-width: 100%;
  min-height: 100%;
}

.lazy-load-placeholder {
  padding-bottom: 56.25%; // 100 / (16/9)
}

.hover {
  &:hover {
    cursor: pointer;
  }

  &-primary {
    &:hover {
      color: $primary;
    }
  }

  &-secondary {
    &:hover {
      color: $secondary;
    }
  }

  &-danger {
    &:hover {
      color: $danger !important;
    }
  }

  &-premium {
    color: $premium;

    &:hover {
      color: $premium-hover;
    }
  }

  &-underline {
    &:hover {
      text-decoration: underline;
    }
  }

  &-blue {
    &:hover {
      color: $blue;
    }
  }

  &-bg {
    &-secondary {
      &:hover {
        background-color: $gray-200;
      }
    }
  }
}


.text-blue {
  color: $blue !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-premium {
  color: $premium;
}

.text-gray {
  // color: #374957;
  color: $gray-700;
}

.tiny {
  font-size: 0.75rem;
}

.bold {
  font-weight: 600 !important;
}

.btn.btn-outline-premium {
  border: 1px solid $gray-400;

  &:hover {
    border: 1px solid $premium;
    background: $premium;
    color: $white;
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;


  &-2 {
    @extend .ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    line-height: 1;
    -webkit-box-orient: vertical;
    white-space: initial;
  }
}

.bg {
  &-gray-200 {
    background-color: $gray-200;
  }
}

ul {
  margin-bottom: 0;
}

.text-bold-container {
  &::after {
    content: attr(data-text);
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: var(--bold);
    display: block;
  }
}