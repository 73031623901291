.list-top-dl-tags {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;

  a {
    width: fit-content;
  }

  @include md {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include lg {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}