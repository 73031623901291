.pagination {
  .page-link {
    border: none;
    // border-radius: 4px;
    // margin: 0 5px;
    // width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-weight: 600;
  }

  &-sm {
    .page-link {
      width: 30px;
      height: 30px;
    }
  }
}