// @import './font';
// Set unique-id ver
$ver 			: unique_id();

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

// Primary color
// $primary        : #2387f3 !default;
// $blue:    #007bff !default;
$blue:    #2387f3 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

// $primary:       $blue !default;
$primary:       #3c9275 !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
// $dark:          $gray-800 !default;
$dark:          #000 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

// Theme color
$theme-color 	: #cc9900 !default; // #cc9900
$theme			: $theme-color !default;
$premium: #3c9275;
$premium-hover: #fff;
$premium-hover-background: #3c9275;

// Background variables light
$light-primary : lighten($primary, 25%);
$light-success : lighten($success, 25%);
$light-warning : lighten($warning, 25%);
$light-danger : lighten($danger, 25%);
$light-info : lighten($info, 25%);
$light-dark : lighten($dark, 25%);
$light-theme : lighten($theme, 25%);
$light-secondary : lighten($secondary, 25%);

// Background variables dark
$dark-primary : darken($primary, 28%);
$dark-success : darken($success, 28%);
$dark-warning : darken($warning, 28%);
$dark-danger : darken($danger, 28%);
$dark-info : darken($info, 15%);
$dark-secondary : darken($secondary, 15%);
$dark-mute : darken($dark, 45%);
$dark-theme : darken($theme, 32%);

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;

// Font sizes
$font-size-xl:                ($font-size-base * 1.55) !default;
$font-size-xs:                ($font-size-base * .475) !default;

$dropdown-border-width: 0;
$dropdown-item-padding-y: 0.5rem;
// $dropdown-item-padding-x

// $font-family-base: Poppins, sans-serif;
// $font-family-base: Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, sans-serif;
$font-family-base: "メイリオ",Meiryo,"Lucida Grande","Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3","ＭＳ Ｐゴシック",Geneva,Arial,Verdana,sans-serif; 
// $font-family-base: "メイリオ",Meiryo,"Lucida Grande","Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3","ＭＳ Ｐゴシック",Geneva,Arial,Verdana,sans-serif;
$font-size-base: 0.875rem;

$navbar-dark-color: rgba($white, .65);
$navbar-light-color: rgba($black, .85);
$navbar-nav-link-padding-x: 1rem;
$navbar-toggler-padding-y:  0;
$navbar-toggler-padding-x:  0;
// $navbar-dark-toggler-border-color: $dark;
// $navbar-dark-active-color: $dark;
$navbar-light-hover-color: rgba($black, .85);
$navbar-toggler-border-radius: 0;
$transition-collapse: none; 

$link-color: inherit;
$link-hover-color: inherit;
$link-hover-decoration: none;


$btn-border-radius: 4px;
$btn-line-height: 1rem;
$breadcrumb-divider: quote(">");
$breadcrumb-bg: white;

$grid-gutter-width: 15px;

// $body-bg: $gray-100;

$breadcrumb-padding-x: 0;

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px
// );

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px
// ) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 572px,
  md: 992px,
  lg: 1232px,
  xl: 1432px
);

$container-max-widths: (
  sm: 540px,
  md: 1124px,
  lg: 1200px,
  xl: 1400px
) !default;

$font-weight-bold: 600;
// $grid-gutter-width: 32px;

$alert-bg-level: -8;

.w-5{width:5%!important}
.w-10{width:10%!important}
.w-15{width:15%!important}
.w-20{width:20%!important}
.w-30{width:30%!important}
.w-35{width:35%!important}
.w-40{width:40%!important}
.w-45{width:45%!important}
.w-60{width:60%!important}
.w-65{width:65%!important}
.w-70{width:70%!important}
.w-75{width:75%!important}
.w-80{width:80%!important}
.w-85{width:85%!important}
.w-90{width:90%!important}
.w-95{width:95%!important}

.left-0{left: 0!important}
.top-0{top: 0!important}
.bottom-0{bottom: 0!important}
.right-0{right: 0!important}
.min-width-50 {
  min-width: 50px!important;
}