$border-search: 1px solid #dee2e6;
$search-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
$search-box-shadow-result: 0 5px 6px 0 rgba(32, 33, 36, 0.28);
$search-border-radius: 5px;

.topContainer {
  width: 100%;
  position: relative;
  z-index: 3;

  &-lg {
    font-size: 16px;
  }
}

.searchContainer {
  background: #fff;
  display: flex;
  /* border-radius: 5px; */
  border: $border-search;
  // border-radius: $search-border-radius;
  height: 40px;
  overflow: hidden;
}

.searchContainerExpanded {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.searchContainerExpandedShadow,
.searchContainerShadow:hover {
  border-color: transparent;
  box-shadow: $search-box-shadow;
}

.flexContainer {
  flex: 1;
  display: flex;
  // padding-left: 12px;
}

.searchIconContainer {
  display: flex;
  align-items: center;
  padding-right: 8px;
}

.spinnerContainer {
  display: flex;
  align-items: center;
  padding-left: 8px;
  margin-top: 8px;
  align-self: end;
}

.spinnerContainer.small {
  display: flex;
  align-items: center;
  padding-left: 8px;
  margin-top: 2px;
  align-self: end;
}

.searchIcon {
  color: #9aa0a6;
  height: 20px;
  width: 20px;
}

.clearButton {
  color: #9aa0a6;
  width: 48px;
  height: 100%;
  border: none;
  background: transparent;
  border-left: $border-search;
  position: relative;
  left: -1px;

  &:hover {
    background-color: $primary;
    color: white;
  }
}

.moreSearchButton {
  font-size: 12px;
  color: #666;
  width: 80px;
  height: 100%;
  border: none;
  background: transparent;
  border-left: $border-search;
}

.inputField {
  color: #444;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  outline: none;
  display: flex;
  flex: 100%;
  width: 100%;

  &::placeholder {
    color: #999;
  }
}

.inputField.small {
  font: 14px arial, sans-serif;
  margin-top: -41px;
}

.inputContainer {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.inputFieldContainer {
  color: transparent;
  flex: 100%;
  white-space: pre;
  height: 34px;
}

.inputFieldContainer span {
  padding: 0;
}

.resultContainer {
  text-align: left;
  z-index: 900;
  cursor: default;
  position: absolute;
  width: 100%;
  background: #fff;
  // border-radius: 0 0 $search-border-radius $search-border-radius;
}

.resultContainer em {
  font-weight: 600;
  font-style: normal;
}

.resultListContainer {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: 300px;
}

.resultListContainer.small {
  border-radius: 0 0 16px 16px;
}

.resultListContainerExpandedShadow {
  border-color: transparent;
  box-shadow: $search-box-shadow-result;
}

.resultList {
  padding: 0;
  margin: 10px 0;
}

.resultSeperatorContainer {
  border: 0;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.resultSeperatorContainerShadow {
  border: 0;
}

.resultSeperator {
  border-top: 1px solid #e8eaed;
  margin: 0 14px;
}

.resultListItem {
  display: flex;
  align-items: center;
  min-width: 0;
  max-height: none;
  padding: 0;
}

.resultListItem:hover {
  background-color: lightgrey;
  cursor: pointer;
}

.resultItem {
  margin: 6px 12px 6px 12px;
  width: 100%;
}

.resultItem.small {
  margin: 5px;
}

.resultItemControl {
  margin-right: 14px;
}

.resultItemLabel {
  display: inline-block;
  margin-left: 25px;
  text-indent: -31px;
  color: #444;
}

.linkChoice {
  text-decoration: none;
  color: #444;
  padding: 4px;
  padding-left: 12px;
  width: 100%;
  display: inline-block;
}

.chipContainer {
  padding: 10px 12px;
}
.advsearcharea {
  position: absolute;
  width: 46%;
  right: 13px;
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 9999;
  top: 45px;
}
.advsearcharea:after {
  content: "";
  position: absolute;
  border: 12px solid transparent;
  border-bottom-color: #fff;
  border-top-width: 0;
  right: 75px;
  top: -10px;
}
.text-dark {
  color: #343a40!important;
}
.advsearcharea .ui-dropdownchecklist {
  height: auto;
  position: relative;
  width: 100%!important;
  outline: unset;
}
.ui-widget {
  font-family: Lucida Grande,Lucida Sans,Arial,sans-serif;
  font-size: 1.1em;
}
.ui-dropdownchecklist {
  height: 50px;
  border-right: 0;
  // background: #fff;
}
.advsearcharea .ui-dropdownchecklist .ui-dropdownchecklist-selector {
  border: 1px solid #ced4da!important;
  padding: 4px 16px!important;
  font-size: initial;
  width: 100%!important;
  height: 100%!important;
  overflow: unset!important;
  outline: unset;
  display: flex!important;
  align-items: center;
  border-radius: 0.25rem;
}
.advsearcharea .ui-dropdownchecklist-selector:before {
  // content: "\f107";
  font: normal normal normal 14px/1 FontAwesome;
  right: 12px;
}
.ui-dropdownchecklist-selector::before {
  position: absolute;
  right: 50px;
  content: "▼";
  padding-left: 10px;
  cursor: pointer;
  border-left: 1px solid rgb(204, 204, 204);
}
 .ui-dropdownchecklist-text {
  height: auto !important;
  font-size: 14px !important;
  width: auto !important;
  margin: 0 6px 0 !important;
  width: 75% !important;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.advsearcharea .ui-dropdownchecklist-dropcontainer-wrapper {
  left: auto!important;
  right: auto!important;
  width: 100%!important;
}
.advsearcharea .ui-dropdownchecklist-dropcontainer {
  overflow-x: hidden;
  max-height: 200px;
  margin-top: 15px;
  border: 1px solid #ced4da;
  padding: 8px 0;
  border-radius: 0.25rem;
}
.advsearcharea .ui-widget-content {
  background: #fff!important;
}
.advsearcharea .ui-state-default {
  padding: 0 16px;
}
.advsearcharea .ui-dropdownchecklist-item {
  margin: 0 0 8px !important;
  width: 100% !important;
  display: flex;
}
@media only screen and (max-width: 1000px) {
  .advsearcharea {
    width: 80%;
  }
}
@media only screen and (max-width: 575px) {
  .mb-option-sidebar {
    position: fixed!important;
    left: 0;
    top: 0!important;
    max-width: 350px;
    margin: 0!important;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    // transform: translateX(-100%);
    // transition: .3s;
    overflow-y: auto;
    // display: block;
}
}
.mb-option-sidebar__area .ui-dropdownchecklist {
  height: auto;
  position: relative;
  width: 100%!important;
  outline: unset;
}
.mb-option-sidebar__area .ui-dropdownchecklist-selector .ui-dropdownchecklist-text {
  width: 75% !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px!important;
  font-size: 14px!important;
}
.mb-option-sidebar__area .ui-dropdownchecklist .ui-dropdownchecklist-selector {
  border: 1px solid #ced4da!important;
  padding: 4px 16px!important;
  font-size: initial;
  width: 100%!important;
  height: 100%!important;
  overflow: unset!important;
  outline: unset;
  display: flex!important;
  align-items: center;
  border-radius: 0.25rem;
}
.mb-option-sidebar__area .ui-dropdownchecklist .ui-dropdownchecklist-selector::before {
  right: 10px;
}
.mb-option-sidebar__area .ui-dropdownchecklist-dropcontainer {
  overflow-x: hidden;
  max-height: 200px;
  margin-top: 8px;
  border: 1px solid #ced4da;
  padding: 8px 9px;
  border-radius: 0.25rem;
}
.ui-dropdownchecklist-selector:hover {
  background-color: #eee;
  color: #1d74b3
}
.ui-dropdownchecklist-item:hover {
  background-color: #eee;
  color: #1d74b3
}
.ui-dropdownchecklist-item label {
  margin-top: 10px;
}
.btn-search:hover {
  background-color: #15A87C !important;
}
@media only screen and (max-width: 575px) {
  .advsearcharea-lg-screen  {
    display: none;
  }
  .advsearcharea-sm-screen {
    display: block !important;
  }
}
.tag-container-search {
  border: 1px solid #d1d1d1;
  min-height: 10px;
  border-radius: 4px;

  .input-tag {
    width: 100%;
    border: none; 
    outline: none; 
  }

  .input-tag:focus {
    border: none; 
    outline: none;
  }
}

.tag-container-search ul {
  padding: 4px 17px;
  flex-flow: row;
  display: flex;
  flex-wrap: wrap;
}
.filter-category-search-menu {
  width: 300px;
  right: unset;
  z-index: 9999;
}
.filter-category-search-menu::after {
  display: none;
}
.ui-dropdownchecklist-dropcontainer-search {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  width: 800px;
  z-index: 10000;
}
.ui-dropdownchecklist-item-search:hover {
  background-color: unset;
  color: #1d74b3;
}
.filter-mimetype-search-menu {
  position: absolute;
  width: 100px;
  height: 100px;
  background: #fff;
  padding: 10px !important;
  z-index: 9999;
}
.filter-kw-exclude-search-menu {
  position: absolute;
  background: #fff;
  padding: 20px !important;
  z-index: 9999;
  width: 300px;
}
.filter-creator-name-search-menu {
  position: absolute;
  background: #fff;
  padding: 20px !important;
  z-index: 9999;
  width: 300px;
}
.filter-sort-search-menu {
  font-size: 14px;
  position: absolute;
  background: #fff;
  padding: 10px 10px 0px 10px !important;
  z-index: 8;
  width: 150px;
}
.filter-category-search:hover {
  .filter-category-search-menu {
    display: block !important;
  }
}
.filter-mimetype-search:hover {
  .filter-mimetype-search-menu {
    display: block !important;
  }
}
.filter-kw-exclude-search:hover {
  .filter-kw-exclude-search-menu {
    display: block !important;
  }
}
.filter-creator-name-search:hover {
  .filter-creator-name-search-menu {
    display: block !important;
  }
}
.filter-sort-search:hover {
  .filter-sort-search-menu {
    display: block !important;
  }
}
.filter-category-search-button {
  background-color: white;
}
.filter-category-search-button:hover {
  background-color: #EFEFEF;
}
.filter-kw-exclude-button {
  background-color: white;
}
.filter-kw-exclude-button:hover {
  background-color: #EFEFEF;
}
.filter-creator-name-search-button {
  background-color: white;
}
.filter-creator-name-search-button:hover {
  background-color: #EFEFEF;
}
.filter-mimetype-search-button {
  background-color: white;
}
.filter-mimetype-search-button:hover {
  background-color: #EFEFEF;
}
.filter-kw-exclude-search-button
 {
  background-color: white;
}
.filter-kw-exclude-search-button:hover {
  background-color: #EFEFEF;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.search-button-show {
  display: none;
}
@media only screen and (max-width: 574px) {
  .header-pagination-sort-head {
    display: none !important;
  }
  .search-button-show {
    display: block;
  }
  .header-pagination-sort-container {
    display: none;
  }
}
.text-777777 {
  color: #777777;
}
.delete-tag-search:hover {
  color: #e8384d;
}
.react-multiple-carousel__arrow {
  z-index: 1 !important;
}