.pv {
  margin-bottom: 18px;
}

.pt, .pb>span {
  display: block;
  margin-bottom: 12px;
}

ol.ol-second {
  & > li {
    list-style: none;
    counter-increment: list; 
  }
  & > li:before {
    counter-increment: item;
    content: "(" counter(list) ") ";
  }
}