 .sidebar {
     position: fixed;
     top: 0;
     bottom: 0;
     left: 0;
     min-height: 100vh !important;
     z-index: 100;
     padding: 48px 0 0;
     box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
 }

 #sidebar-wrapper {
     min-height: 100vh !important;
     width: 100vw;
     margin-left: -1rem;
     -webkit-transition: margin .25s ease-out;
     -moz-transition: margin .25s ease-out;
     -o-transition: margin .25s ease-out;
     transition: margin .25s ease-out;
 }

 #sidebar-wrapper .sidebar-heading {
     padding: 0.875rem 1.25rem;
     font-size: 1.2rem;
 }

 #page-content-wrapper {
     min-width: 0;
     width: 100%;
     color: #333333 !important;
 }

 .card {
     -webkit-box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
     box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
     border: 1 px solid #eaeaea;
 }

 .ac-navigation-link li ul.sub-menu {
     display: none;
     left: 100%;
     max-width: 220 px;
     list-style: none;
     padding: 0;
 }
 .ac-navigation-link {
     padding: 0;
 }

 .menu-onhover:hover>.sub-menu {
     display: block;
 }

 .sub-menu:hover>.sub-menu {
     display: block;
 }
.ac-btn-video{
    background-color: #3c9275;
    color: white;
}
.ac-btn-video:hover {
    color: #fff;
}

.ac-btn-secondary{
    background-color: #5e7680 !important;
}

// React tag
.react-tags {
    position: relative;
    padding: 6px 0 0 6px;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    min-height: 150px;
    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;
  
    /* clicking anywhere will focus the input */
    cursor: text;
  }
  
  .react-tags.is-focused {
    border-color: #b1b1b1;
  }
  
  .react-tags__selected {
    display: inline;
  }
  
  .react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    color: #46799b;;
    // border: 1px solid #d1d1d1;
    border: none;
    border-radius: 2px;
    background: #e0eaf1;
  
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
  }
  
  .react-tags__selected-tag:after {
    content: "\2715";
    color: #46799b;;
    margin-left: 8px;
  }
  
  .react-tags__selected-tag:hover,
  .react-tags__selected-tag:focus {
    border-color: #b1b1b1;
  }
  
  .react-tags__search {
    display: inline-block;
  
    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;
  
    /* prevent autoresize overflowing the container */
    max-width: 100%;
  }
  
  @media screen and (min-width: 30em) {
    .react-tags__search {
      /* this will become the offsetParent for suggestions */
      position: relative;
    }
  }
  
  .react-tags__search input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;
  
    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
  
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
  }
  
  .react-tags__search input::-ms-clear {
    display: none;
  }
  
  .react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }
  
  @media screen and (min-width: 30em) {
    .react-tags__suggestions {
      width: 240px;
    }
  }
  
  .react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  
  .react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
  }
  
  .react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  
  .react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
  }
  
  .react-tags__suggestions li.is-active {
    background: #b7cfe0;
  }
  
  .react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
  }
  .react-tags__search-input::placeholder {
    color:transparent;
  }

.max-width-600 {
    max-width: 600px!important;
}
.max-width-500 {
  max-width: 500px!important;
}

small {
    font-size: 80%;
    font-weight: 400;
}

.ac-text-danger-creator-list {
  color: #D63A4F;
  font-size: 12px;
  font-weight: normal;
}
.ac-text-blue-creator-list {
  color:#1d74b3;
}
.ac-custom-select-creator-list {
  border-radius: 4px !important;
}
.ac-footer h4 {
  font-size: 0.95rem;
  border-bottom: 1px solid #333;
}
.list-menu-item {
  list-style-type: none;
  padding:0;
}
.dropdown-menu-list-video {
  min-width: 600px !important;
  transform: translate(-338px, 40px) !important;
}
@media screen and (max-width: 1289px) {
  .dropdown-menu-list-video {
    min-width: 550px !important;
  }
}
@media screen and (max-width: 1231px) {
  .dropdown-menu-list-video {
    min-width: 500px !important;
    transform: translate(-353px, 40px) !important;
  }
}
@media screen and (max-width: 1177px) {
  .dropdown-menu-list-video {
    min-width: 400px !important;
  }
}
@media screen and (max-width: 1044px) {
  .dropdown-menu-list-video {
    min-width: 350px !important;
  }
}
@media screen and (max-width: 991px) {
  .dropdown-menu-list-video {
    transform: translate(-71px, 60px) !important;
  }
}
@media screen and (max-width: 677px) {
  .dropdown-menu-list-video {
    transform: translate(-2px, 40px) !important;
  }
}
@media screen and (max-width: 571px) {
  .dropdown-menu-list-video {
    transform: translate(-2px, 77px) !important;
  }
}
.text-table {
  color: #333333 !important;
}
.modal-list-video, .tag-delete-modal {
  .modal-content {
    padding: 0 !important;
  }
  .modal-header {
    border-bottom: 0px !important;
  }
  .modal-body {
    padding-top: 0;
    text-align: center;
    font-weight: 600;
  }
  .modal-body button {
    border-radius: 4px;
  }
  .btn-secondary {
    box-shadow: none;
  }
}
.tag-delete-editor .react-tags {
  min-height:0px;
}
.modal-list-video {
  .btn-secondary {
    box-shadow: none;
  }
}



.arrow-steps-wrapper {
  max-width: 100%;
  .arrow-steps .step {
    font-size: 14px;
    text-align: center;
    color: #666;
    cursor: default;
    padding: 10px 10px 10px 30px;
    //   width: 450px;
    width: 32.5%;
    height: 87px;
    float: left;
    position: relative;
    background-color: #e6e6e6;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 0.2s ease;
  }
  .arrow-steps .step:first-child {
    background-color: #3c9275;
  }
  
  .arrow-steps .step:first-child:after,
  .arrow-steps .step:first-child:before {
    border-left: 40px solid #3c9275;
  }
  .arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 38px solid #fff;
    z-index: 0;
  }
  .arrow-steps .step:first-child:before {
    border: none;
  }
  .arrow-steps .step:first-child span {
    color: #333333;
  }
  .arrow-steps .step span {
    position: relative;
    font-size: 24px;
  }
  .arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -20px;
  }
  .arrow-steps .step.done span:before {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
  }
  .arrow-steps .step.current {
    color: #fff;
    background-color: #23468c;
  }
  .arrow-steps .step.current:after {
    border-left: 17px solid #23468c;
  }
  .arrow-steps .step:last-child:after {
    border: none;
    margin: 0;
  }
}
.arrow-steps-wrapper-mobile {
  .arrow-steps .step {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 20px;
  }
}
.arrow-steps-wrapper-2 {
  max-width: 100%;
  .arrow-steps .step {
    font-size: 14px;
    text-align: center;
    color: #666;
    cursor: default;
    padding: 10px 10px 10px 30px;
    //   width: 450px;
    width: 32.5%;
    height: 87px;
    float: left;
    position: relative;
    background-color: #e6e6e6;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 0.2s ease;
  }
  .arrow-steps .step:nth-child(2) {
    background-color: #3c9275 !important;
  }
  .arrow-steps .step:after,
  .arrow-steps .step:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -40px;
    width: 0;
    height: 0;
    border-top: 40px solid transparent;
    border-bottom: 47px solid transparent;
    border-left: 40px solid #e6e6e6;
    z-index: 1;
    transition: border-color 0.2s ease;
    bottom: 0;
    margin: auto;
  }
  .arrow-steps .step:nth-child(2):after
  {
    border-left: 40px solid #3c9275;
  }
  .arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 38px solid #fff;
    z-index: 0;
  }
  .arrow-steps .step:first-child:before {
    border: none;
  }
  // .arrow-steps .step:first-child span {
  //   color: #333333;
  // }
  .arrow-steps .step span {
    position: relative;
    font-size: 24px;
  }
  .arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -20px;
  }
  .arrow-steps .step.done span:before {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
  }
  .arrow-steps .step.current {
    color: #fff;
    background-color: #23468c;
  }
  .arrow-steps .step.current:after {
    border-left: 17px solid #23468c;
  }
  .arrow-steps .step:last-child:after {
    border: none;
    margin: 0;
  }
}
.arrow-steps-wrapper-mobile-2 {
  .arrow-steps .step {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 20px;
  }
}
.label-form {
  font-size: 14px;
  font-weight: normal;
}
.text-danger {
  font-size:12px !important;
  font-weight: normal;
}
.h-36px {
  height: 36px;
}
.w-36px {
  width: 36px;
}
.h-26px {
  height: 26px;
}
.w-26px {
  width: 26px;
}
.text-E6E6E6 {
  color: #e6e6e6;
}
// @media (max-width: 991px) {
//   .steps-title {
//     font-size: 0.7rem;
//   }
//   .arrow-steps-wrapper {
//     .arrow-steps {
//       .step {
//         width: 100%;
//         height: 60px;
//         border-radius: 4px;
//         margin-bottom: 20px;
//         justify-content: start !important;
//       }
//       .step::before {
//         display: none;
//       }
//       .step::after {
//         top: 200%;
//         right: 45%;
//         border-left: 20px solid transparent;
//         border-right: 20px solid transparent;
//         border-top: 20px solid #e6e6e6;
//       }
//       .step:first-child::after {
//         border-left: 20px solid transparent;
//         border-top: 20px solid #3c9275;
//       }
//     }
//   }
//   .arrow-steps-wrapper-2 {
//     .arrow-steps {
//       .step {
//         width: 100%;
//         height: 60px;
//         border-radius: 4px;
//         margin-bottom: 20px;
//         justify-content: start !important;
//       }
//       .step::before {
//         display: none;
//       }
//       .step::after {
//         top: 200%;
//         right: 45%;
//         border-left: 20px solid transparent;
//         border-right: 20px solid transparent;
//         border-top: 20px solid #e6e6e6;
//       }
//       .step:nth-child(2)::after {
//         border-left: 20px solid transparent;
//         border-top: 20px solid #3c9275;
//       }
//     }
//   }
// }

.bg-808080 {
    background: #808080!important;
}
.max-width-600 {
    max-width: 600px!important;
}
.max-width-800 {
    max-width: 800px!important;
}
.text-left {
    text-align: left!important;
}
.upload-img-overlay {
  opacity: 0;
  transition: 0.3s;
}
.upload-img-overlay:hover {
  opacity: 1;
  transition: 0.3s;
}
.upload-img-overlay {
  opacity: 0;
  transition: 0.3s;
}
.upload-img-overlay:hover {
  opacity: 1;
  transition: 0.3s;
}
.bg-black-opacity-50 {
  background-color: rgba(0, 0, 0, 0.5);
}
.object-cover {
  object-fit: cover;
}
.top-0 {
  top: 0!important;
}
.left-0 {
  left: 0!important;
}
.h-100 {
  height: 100%!important;
}
.w-100 {
  width: 100%!important;
}
.bg-dark {
  background-color: #343a40!important;
}
.ac-z-index-9 {
  z-index: 9!important;
}
.font-size-11 {
  font-size: 11px!important;
}
#avatar {
  display: none;
}
.font-size-30{
  font-size: 30px;
}
.font-size-40 {
  font-size: 40px!important;
}
.border-dashed {
  border-style: dashed!important;
}
.text-secondary {
  color: #6c757d!important;
}
.bg-has-line {
  background: url(https://www.photo-ac.com/assets/profileimages/line.png) top left;
  line-height: 30px;
}
.modal-recommend {
  .modal-content {
    padding:0 !important;
  }
  .modal-header-recommend {
    padding:20px 0px !important;
    display:flex !important
  }
  .modal-header-title {
    margin-left: 11rem;
    font-size:16px !important;
    font-weight: bold;
  }
  button {
    border-radius: 5px;
  }
  .modal-header .close {
    padding: 0rem 1.5rem;
  }
}
.upload-img-overlay {
  .dropzone {
    border: none !important;
  }
}
.arrow-steps-wrapper-2 {
  max-width: 100%;
  .arrow-steps .step {
    font-size: 14px;
    text-align: center;
    color: #666;
    cursor: default;
    padding: 10px 10px 10px 30px;
    //   width: 450px;
    width: 32.5%;
    height: 87px;
    float: left;
    position: relative;
    background-color: #e6e6e6;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 0.2s ease;
  }
  .arrow-steps .step:nth-child(2) {
    background-color: #3c9275 !important;
  }
  .arrow-steps .step:after,
  .arrow-steps .step:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -40px;
    width: 0;
    height: 0;
    border-top: 40px solid transparent;
    border-bottom: 47px solid transparent;
    border-left: 40px solid #e6e6e6;
    z-index: 1;
    transition: border-color 0.2s ease;
    bottom: 0;
    margin: auto;
  }
  .arrow-steps .step:nth-child(2):after
  {
    border-left: 40px solid #3c9275;
  }
  .arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 38px solid #fff;
    z-index: 0;
  }
  .arrow-steps .step:first-child:before {
    border: none;
  }
  // .arrow-steps .step:first-child span {
  //   color: #333333;
  // }
  .arrow-steps .step span {
    position: relative;
    font-size: 24px;
  }
  .arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -20px;
  }
  .arrow-steps .step.done span:before {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
  }
  .arrow-steps .step.current {
    color: #fff;
    background-color: #23468c;
  }
  .arrow-steps .step.current:after {
    border-left: 17px solid #23468c;
  }
  .arrow-steps .step:last-child:after {
    border: none;
    margin: 0;
  }
}
.bg-black-opacity-50 {
  background-color: rgba(0, 0, 0, 0.5);
}
.object-cover {
  object-fit: cover;
}
.top-0 {
  top: 0!important;
}
.left-0 {
  left: 0!important;
}
.h-100 {
  height: 100%!important;
}
.w-100 {
  width: 100%!important;
}
.bg-dark {
  background-color: #343a40!important;
}
.ac-z-index-9 {
  z-index: 9!important;
}
.font-size-11 {
  font-size: 11px!important;
}
#avatar {
  display: none;
}
.font-size-30{
  font-size: 30px;
}
.font-size-40 {
  font-size: 40px!important;
}
.border-dashed {
  border-style: dashed!important;
}
.text-secondary {
  color: #6c757d!important;
}
.bg-has-line {
  background: url(https://www.photo-ac.com/assets/profileimages/line.png) top left;
  line-height: 30px;
}
.modal-recommend {
  .modal-content {
    padding:0 !important;
  }
  .modal-header-recommend {
    padding:20px 0px !important;
    display:flex !important
  }
  .modal-header-title {
    margin-left: 11rem;
    font-size:16px !important;
    font-weight: bold;
  }
  button {
    border-radius: 5px;
  }
  .sidebar-creator {
    display: none !important;
  }
}
.delete-recommend-video {
  width: 20px;
  height: 20px;
  z-index: 3;
  right: -5px;
  top: -13px;
  color: #333;
  cursor: pointer;
}
.modal-body {
  table, th, td {
    border: 1px solid black;
    text-align: center;
    padding: 15px;
  }
}
.modal-status {
  .modal-title {
    margin-left: auto;
  }
  .modal-header .close {
    padding: 0rem 1.5rem;
  }
}
.upload-img-overlay {
  .dropzone {
    border: none !important;
  }
}
.object-scale {
  object-fit: scale-down;
}
.fan-list .item {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}
.fan-list .remove-fan {
  top: -13px;
  right: -8px;
  height: 20px;
  width: 20px;
}
.fan-list .item .nickname {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}



div.ReactTags__tags {
  position: relative;
  padding: 12px 0 0 6px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  min-height: 150px;
  font-size: 1em;
  line-height: 1.2;
  cursor: text;
}

/* Styles for the input */
div.ReactTags__tagInput {
  max-width: 100%;
  border-radius: 2px;
  display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: inherit;
  line-height: inherit;
}
input.ReactTags__tagInputField::placeholder {
  color: transparent;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  color: #46799b;
  border: none;
  border-radius: 2px;
  background: #e0eaf1;
  font-size: inherit;
  line-height: inherit;
}
.ReactTags__remove {
  color: #46799b;
  cursor: pointer;
  border: none;
  background-color: #e0eaf1;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0,0,0,.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}
.fa-cloud-upload:before {
  content: "\f0ee";
}
.fa-cloud-upload {
  color: #3c9275;
}
.upload-model-portrait:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #3c9275;
}
.digital-modal {
  .modal-content {
    padding-top: 0;
    padding-bottom: 0;
  }
  .modal-header {
    font-weight: bold;
    background-color: #EBE8E6;
    padding-left: 30%;
  }
  .modal-body {
    padding-bottom: 0px;
  }
  
  .modal-digital-footer {
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .modal-digital-footer button {
    border-radius: 5px;
    margin: 0px 2px;
  }
}
@media (max-width: 900px) {
  .modal-content {
    width: 100% !important;
  }
}

.bg-fefed4 {
  background-color: #fefed4 !important;
}
.fs-40 {
  font-size: 40px;
}
.sigCanvas {
  border: 1px solid #dee2e6;
}
.item-digital-active {
  background-color: #ccc;
  cursor: pointer;
}.avatar-modal {
  .modal-content {
    padding-top: 0px;
  }
  .modal-title {
    margin-left: 50px;
  }
  .modal-header {
    background-color: #3c9275 !important;
    color: #fff;
  }
}
.avatar-default-link {
  cursor: pointer;
}
.avatar-default-link:hover {
  color: #3c9275;
}
.link-back-model-release {
  color: #3c9275;
}
.link-back-model-release:hover {
  color: #3c9275;
}
.react-date-picker-custom {
  width: 100%;
  .react-date-picker-custom {
    border-radius: 15px;
    border: thin solid #CED4DA;
  }
  .react-date-picker__inputGroup{
    padding: 5px;
  }
}
.datepicker-input-signature {
  .react-date-picker__wrapper {
    border: none;
  }
}
@media (max-width: 990px) {
  .sidebar-creator {
   display: none !important;
  }
}
@media (min-width: 991px){
  .sidebar-creator {
      flex: 0 0 auto;
      max-width: 316px;
  }
  #page-content-wrapper {
    flex: 0 0 auto;
    max-width: calc(100% - 324px);
  }
}
.modal-copyright-link {
  .modal-header {
    padding-left: 0 !important;
    border-radius: 5px;
  }
  .modal-dialog {
    max-width: 650px;
    width: 100%;
    border-radius: 5px;
  }
  .modal-body table, .modal-body th, .modal-body td {
    border-color: #dee2e6;
  }
}

.tag-container {
  border: 1px solid #d1d1d1;
  min-height: 150px;
}
.tag-container ul {
  padding: 0.5rem 1rem;
  flex-flow: row;
  display: flex;
  flex-wrap: wrap;
}
.list-group-item {
  user-select: none;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  color: #46799b;
  border: none;
  border-radius: 2px;
  background: #e0eaf1;
  font-size: inherit;
  line-height: inherit;
  padding: 2px !important;
  display:flex;
  align-items: center;
  justify-content:space-between;
}
.tag-delete {
  font-weight: bold;
  font-size:12px;
  cursor: pointer;
  border: none;
  background: #e0eaf1;
  color: #46799b;
  margin-top: 2px;
  margin-left: 5px;
}
.input-tag {
  border: none;
}
.form-check-AI-link .form-check-label {
  color: #dc3545 !important
}
.text-D73A4E {
  color: #D73A4E !important;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 13px;
}
.text-notification-upload {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 13px;
}
.modal-signature {
  .modal-content {
    padding: 0;
  }
  .modal-header {
    background-color: white;
    border-bottom: none;
  }
}
.mw-100 {
  max-width: 100%!important;
}
.url-text {
  color: #3c9275;
}
.url-text:hover{
  color: #3c9275;
  text-decoration: underline;
}
.modal-exchange-status {
  .modal-content {
    padding: 0 !important;
  }
}
.identity-link:hover{
  text-decoration: underline;
}
.modal-feedback-user {
  .modal-content {
    padding: 0 !important;
    border-radius: 10px;
    width: 50%;
  }
  .modal-title {
    font-size: 16px;
  }
  .modal-header {
    padding-left: 28%;
    border-top-right-radius: 10px;  
    border-top-left-radius: 10px;  
  }
  .modal-footer {
    button {
      border-radius: 5px;
    }
  }
}
.modal-feedback-user-capcha {
  .modal-content {
    padding: 0 !important;
    border-radius: 10px;
    width: 50%;
  }
  .modal-header {
    border-radius: 10px;
    background-color: white;
    border-bottom: unset;
    padding: 5px;
  }
}
.arrow-down-exchange {
  width: 0;
  height: 0;
  border-left: 45px solid transparent;
  border-right: 45px solid transparent;
  border-top: 30px solid #e1e1e1;
}
.bg-f2f2f2 {
  background-color: #f2f2f2;
}
.border-bottom-width {
  text-decoration: underline;
}
.news-content {
  a {
    color: #1d74b3;
  }
  a:hover{
    text-decoration: underline;
  }
}
// .modal-first-creator-login {
//   .modal-content {
//     display: contents !important;
//     width: 100% !important;
//   }
//   .modal-body {
//     background-color: #333;
//     position: relative;
//     padding: 0 !important;
//     border-radius: 10px;
//     span {
//       position: absolute;
//       right: 8px;
//       cursor: pointer;
//       font-size: 25px;
//     }
//   }
//   @media screen and (max-width: 722px) {
//     .modal-body img {
//       width: 100% !important;
//     }
//   }
// }
.color-018489 {
  color: #018489 !important;
}
// .modal-first-creator-login {
//   .window-screen .pr-designac-modal__icon_mr_b p {
//     bottom: 4.5em !important;
//     font-size: 10px;
//     left: 13px;
//   }
//   @media only screen and (max-width: 959px) {
//     .window-screen .pr-designac-modal__icon_mr_b p {
//         bottom: 15em !important;
//         left: 21px;
//     }
//   }
//   @media only screen and (min-width: 960px) {
//     .pr-designac-modal__container__main img.dac-template {
//         width: 355px;
//     }
// }
//   @media only screen and (max-width: 784px) {
//     .window-screen .pr-designac-modal__icon_mr_b p {
//         bottom: 13.5em !important;
//         left: 21px;
//     }
//   }
//   @media only screen and (max-width: 644px) {
//     .window-screen .pr-designac-modal__icon_mr_b p {
//       bottom: 13em !important;
//       left: 1.5em;
//       font-size: 8px;
//     }
//   }
//   @media only screen and (max-width: 480px) {
//     .window-screen .pr-designac-modal__icon_mr_b p {
//         bottom: 17.5em !important;
//         left: 2.7em;
//         font-size: 5px;
//     }
//   }
//   p {
//     margin-bottom: 0;
//   }
//   .modal-dialog {
//     max-width: 652px;
//     width: 100%;
//   }
//   .modal-body {
//     padding: 0;
//   }
//   .modal-content {
//     -webkit-border-radius: 5px !important;
//     -moz-border-radius: 5px !important;
//     border-radius: 10px !important; 
//     overflow: hidden;
//   }
//   .close-button {
//     position: absolute;
//     top: 4px;
//     right: 4px;
//     background-color: #333; /* Màu nền */
//     color: #fff; /* Màu của dấu X */
//     border: none;
//     border-radius: 50%; /* Để tạo hình tròn */
//     width: 25px; /* Kích thước nút */
//     height: 25px;
//     font-size: 24px; /* Kích thước của dấu X */
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     z-index: 1;
//     padding-bottom: 5px;
//     cursor: pointer; /* Hiển thị con trỏ khi hover */
//   }
//   .pr-designac-modal__icon_mr_b p {
//     bottom: 3.5em !important;
//     left: 3px;
//     font-size: 12px;
//   }
// @media only screen and (max-width: 959px) {
//   .pr-designac-modal__icon_mr_b p {
//       bottom: 12.5em !important;
//       left: 16px;
//       font-size: 12px;
//   }
// }
// @media only screen and (min-width: 959px) {
//       .pr-designac-modal__footer__link:hover {
//         text-decoration: underline;
//       }
// }
// @media only screen and (max-width: 870px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 37.5%;
//   }
// }
// @media only screen and (max-width: 783px) {
//   .pr-designac-modal__icon_mr_b p {
//       bottom: 13.5em !important;
//       left: 2em;
//       font-size: 10px;
//   }
// }
// @media only screen and (max-width: 645px) {
//   .pr-designac-modal__icon_mr_b p {
//       bottom: 13em !important;
//       left: 2em;
//       font-size: 8px;
//   }
// }
// @media only screen and (max-width: 611px) {
//   .pr-designac-modal__icon_mr_b p {
//     bottom: 21.5em !important;
//     left: 3em;
//     font-size: 6px;
//   }
// }
// @media only screen and (max-width: 608px) {
//   .pr-designac-modal__icon_mr_b p {
//     bottom: 17.5em !important;
//     left: 3em;
//     font-size: 6px;
//   }
// }
// @media only screen and (max-width: 580px) {
//   .pr-designac-modal__icon_mr_b p {
//       bottom: 17em !important;
//       left: 2em;
//       font-size: 6px;
//   }
//   .modal-dialog {
//     margin: 0;
//   }
//   .pr-designac-modal__icon_mr_b {
//     bottom: 43%;
//   }
// }
// @media only screen and (max-width: 480px) {
//   .pr-designac-modal__icon_mr_b p {
//       bottom: 15em !important;
//       left: 2em;
//       font-size: 6px;
//   }
// }
// @media only screen and (max-width: 783px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 36.7%;
//   }
// }
// @media only screen and (max-width: 767px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 41%;
//   }
// }
// @media only screen and (max-width: 730px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 41%;
//   }
// }
// @media only screen and (max-width: 690px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 41%;
//   }
// }
// @media only screen and (max-width: 660px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 41%;
//   }
// }
// @media only screen and (max-width: 611px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 40.5%;
//       right: 1em;
//   }
  
// }
// @media only screen and (max-width: 580px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 40%;
//   }
// }
// @media only screen and (max-width: 570px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 44%;
//   }
// }
// @media only screen and (max-width: 480px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 43.2%;
//   }
//   .pr-designac-modal__container__main img.dac-template {
//       width: 285px;
//   }
// }
// @media only screen and (max-width: 430px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 43.5%;
//   }
// }
// @media only screen and (max-width: 414px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 44.1%;
//       right: 0%;
//   }
// }
// @media only screen and (max-width: 375px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 44%;
//   }
// }
// @media only screen and (max-width: 390px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 45%;
//   }
// }
// }
.modal-download-ads {
  .modal-content {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
  }
  .modal-body {
    background-color: #3c9275;
    color: white;
    padding: 3rem;
    border-radius: 5px;
  }
  .modal-title-text {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  h5 {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  span {
    font-size: 2rem;
    font-weight: bold;
  }
  .progress-bar {
    display: flex;
    gap: 5px;
    margin: 10px 0;
  }
  
  .progress-box {
    width: 30px;
    height: 15px;
    background-color: #fff;
    border-radius: 3px;
  }
  
  .progress-box.filled {
    background-color: #4caf50; /* Màu xanh cho phần đã nạp */
  }
} 
@media screen and (max-width: 422px) {
  .text-type-name {
    font-size: 10px !important;
  }
  .text-byte {
    font-size: 14px !important;
  }
  .text-size {
    font-size: 11px !important;
  }
}
.text-1d74b3 {
  color: #1d74b3;
}
.text-container-wrap {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  word-break: break-word;
  overflow-wrap: break-word;
  text-align: left;
}
.font-size-12 {
  font-size: 12px;
}
.modal-first-creator-login {
  .modal-dialog {
    max-width: 565px;
    width: 100%;
  }
  @media only screen and (max-width: 653px) {
    .modal-dialog {
        max-width: 434px !important;
        margin: 0 auto;
    }
  }
  @media only screen and (max-width: 510px) {
    .modal-dialog {
        max-width: 304px !important;
    }
  }
  .modal-body {
    padding: 0;
  }
  .modal-content {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 10px !important; 
    width: 100% !important;
  }
}