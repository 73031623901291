.message-modal-alert {
  .modal-content {
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 4px;
  }
  .modal-header {
    padding-left: 0 !important;
  }
  .alert {
    margin: 0;
    border: 0;
    padding-right: 4rem;
    border-radius: 4px;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

#creatorRegContainer {
  color: #333333;
  max-width: 1070px;

  .text-dark {
    color: #333333 !important;
  }

  .form-check-label {
    color: black !important;
  }

  .was-validated .form-control:valid,
  .form-control.is-valid {
    // Not showing check mark for fields in the OK state
    background-image: unset;
    border-color: #CFD4D8;
  }

  #form-title {
    font-size: 32px;
    width: fit-content;
    margin: 50px auto;
    line-height: 39px;
    font-weight: 400;
  }

  .reg-method-title {
    font-size: 23px;
    line-height: 29px;
    font-weight: 400;
    margin: 0;
  }

  .brand-icon {
    position: absolute;
    top: 14px;
    left: 19px;
    width: 22px;
    height: 22px;
    border-radius:50%;
  }
  .brand-icon-twitter {
    position: absolute;
    top: 14px;
    left: 19px;
    width: 22px;
    height: 22px;
  }

  .btn {
    width: 100%;
    padding: 12px 0;
    border: none;
    border-radius: 4px;
    // margin: 5px 20px;
    margin: 5px auto;
    opacity: 0.85;
    display: inline-block;
    font-size: 17px;
    line-height: 20px;
    text-decoration: none; /* remove underline from anchors */
  }
  .btn:hover {
    opacity: 1;
  }
  .rounded-circle {
    border-radius: 50%!important;
  }
  .fb {
    border: 1px solid gray;
    border-radius: 20px;
    height:40px;
    display: flex;
    justify-content: center;
    align-items:center;
  }

  .twitter {
    border: 1px solid gray;
    border-radius: 20px;
    height:40px;
    display: flex;
    justify-content: center;
    align-items:center;
  }

  .google {
    border: 1px solid gray;
    border-radius: 20px;
    height:40px;
    display: flex;
    justify-content: center;
    align-items:center;
  }

  .google:hover , .twitter:hover ,.fb:hover  {
    background-color: #F1F1F1;
  }

  .font-bold {
    font-weight: bold;
    // margin: 0.5rem;
  }

  .steps-title {
    margin: 3rem auto 1rem;
    text-align: center;
    font-size: x-large;
  }

  .form-title {
    margin: 1.5rem 0 0.5rem;
  }

  #building-note {
    color: #333333;
    font-size: 11px;
  }

  .form-control:focus {
    // color: #495057;
    // background-color: #fff;
    border-color: #8EBBF9 !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #CCDDFD;
    // box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }

  p {
    margin: 0;
  }

  .h-36px {
    height: 36px;
  }
  .w-36px {
    width: 36px;
  }
  .h-26px {
    height: 26px;
  }
  .w-26px {
    width: 26px;
  }
  .text-E6E6E6 {
    color: #e6e6e6;
  }
  .select2-container--default .select2-selection--single {
    height: 40px;
    padding-top: 4px;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    top: 6px;
  }
  .form-control::placeholder {
    color: #cececf;
  }
  .max-height-25 {
    max-height: 25px;
  }

  .arrow-steps-wrapper {
    margin: auto;
    text-align: center;
    max-width: 80%;

    .arrow-steps .step {
      font-size: 14px;
      text-align: center;
      color: #666;
      cursor: default;
      padding: 10px;
      //   width: 450px;
      width: 32.5%;
      height: 87px;
      float: left;
      position: relative;
      background-color: #e6e6e6;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      transition: background-color 0.2s ease;
    }
    .arrow-steps .step:first-child {
      background-color: #3c9275;
    }
    .arrow-steps .step:first-child:after,
    .arrow-steps .step:first-child:before {
      border-left: 40px solid #3c9275;
    }
    .arrow-steps .step:before {
      right: auto;
      left: 0;
      border-left: 38px solid #fff;
      z-index: 0;
    }
    .arrow-steps .step:first-child:before {
      border: none;
    }
    .arrow-steps .step:first-child span {
      color: #333333;
    }
    .arrow-steps .step span {
      position: relative;
      font-size: 24px;
    }
    .arrow-steps .step span:before {
      opacity: 0;
      content: "✔";
      position: absolute;
      top: -2px;
      left: -20px;
    }
    .arrow-steps .step.done span:before {
      opacity: 1;
      -webkit-transition: opacity 0.3s ease 0.5s;
      -moz-transition: opacity 0.3s ease 0.5s;
      -ms-transition: opacity 0.3s ease 0.5s;
      transition: opacity 0.3s ease 0.5s;
    }
    .arrow-steps .step.current {
      color: #fff;
      background-color: #23468c;
    }
    .arrow-steps .step.current:after {
      border-left: 17px solid #23468c;
    }
    .arrow-steps .step:last-child:after {
      border: none;
      margin: 0;
    }
  }

  .arrow-steps-wrapper-mobile {
    .arrow-steps .step {
      width: 100%;
      border-radius: 4px;
      margin-bottom: 20px;
    }
  }
  .label-form {
    font-size: 16px;
    font-weight: normal;
  }
  .text-danger {
    font-size:12px !important;
    font-weight: normal;
  }

  #creatorRegForm {
    // margin: auto;
    border-radius: 4px;
    max-width: 1200px;
    padding: 26px 29px 0px;

    input,
    select {
      border-radius: 4px;
    }

    .row {
      margin-bottom: 1rem;
    }

    .special-background-color {
      background-color: #e9f0fd;
    }

    // div {
    //   font-weight: bold;
    // }

    .address-right-col div {
      font-weight: normal;
    }

    button[type="submit"] {
      margin: 0;
      opacity: 1;
      background-color: #3c9275;
    }

    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker {
      display: none;
    }

    .row > div:first-child {
      font-weight: bold;
    }
  }

  @media screen and (max-width: 990px) {
    .brand-icon {
      left: 20px;
    }
    #form-title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
    .steps-title {
      font-size: 0.7rem;
    }
    .arrow-steps-wrapper {
      // max-width: 100%;

      // .wrapper.container{
      //   padding: 0;
      // }

      .arrow-steps {
        .step {
          width: 100%;
          height: 60px;
          border-radius: 4px;
          margin-bottom: 20px;
          justify-content: start !important;
        }
        .step::before {
          display: none;
        }
        .step::after {
          top: 200%;
          right: 45%;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid #e6e6e6;
        }
        .step:first-child::after {
          border-left: 20px solid transparent;
          border-top: 20px solid #3c9275;
        }
      }
    }

    // .mobile-mg-top {
    //   margin-top: 10px;
    // }
  }
}
.reg-fieldset {
  text-align: center;
  border-width: 2px;
  border-style: solid;
  border-color: #3c9275 !important;
  border-image: initial;
  height: 185px !important;
  width: 99%;
}
.reg-legend {
  width: 70%;
  font-size: 15px !important;
  font-weight: 400;
  line-height: 24px;
}
.reg-blog-wrapper {
  display: flex;
  cursor: pointer;
  width: 97%;
  // height: 141px;
  // border: 2px solid #3C9275;
  margin-left: 10px;
  margin-bottom: 10px;
}
.reg-blog-wrapper-content {
  text-align: left;
  margin-right: 5px;
}
.reg-blog-wrapper-content-title {
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  margin-left: 20px !important;
}
.reg-blog-wrapper-content-title:hover {
  text-decoration: underline;
}
.reg-blog-wrapper-content-sub-title {
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  margin-left: 10px !important;
}
.reg-blog-wrapper-content-content {
  margin-top: 10px !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #A8A8A8;
  margin-left: 20px !important;
}
.link-component-reg {
  display: flex;
}
@media screen and (max-width: 1541px) and (min-width: 1171px) {
  .reg-blog-wrapper-content-title {
    padding-left: 59px !important;
  }
  .reg-blog-wrapper-content-content {
    padding-left: 59px !important;
  }
}
@media screen and (max-width: 1457px) {
  .reg-fieldset {
    height: 245px !important;
  }
}
@media screen and (max-width: 1170px) and (min-width: 992px) {
  .reg-blog-wrapper-content-title {
    padding-left: 65px !important;
  }
  .reg-blog-wrapper-content-content {
    padding-left: 65px !important;
  }
  .reg-blog-wrapper {
    height: 184px;
  }
}
@media screen and (max-width: 1200px) {
  .sns-login-google-text {
    display: none !important;
  }
  .sns-login-facebook-text {
    display: none !important;
  }
  .sns-login-X-text {
    display: none !important;
  }
  .sns-login-X-text-sm-green {
    display: block !important;
  }
  .sns-login-facebook-text-sm-green {
    display: block !important;
  }
  .sns-login-google-text-sm-green {
    display: block !important;
  }
}
@media screen and (max-width: 991px) {
  .link-component-reg {
    display: block !important;
  }
  .reg-fieldset {
    height: 380px !important;
  }
}
@media screen and (max-width: 638px) and (min-width: 583px) {
  .reg-fieldset {
    height: 415px !important;
  }
  .reg-blog-wrapper-content {
    margin-left: 15px !important;
  }
  // .reg-blog-wrapper {
  //   width: 92% !important;
  // }
}
@media screen and (max-width: 582px) and (min-width: 572px) {
  .reg-fieldset {
    height: 415px !important;
  }
}
@media screen and (max-width: 571px) and (min-width: 560px) {
  .reg-fieldset {
    height: 592px !important;
  }
  .reg-blog-wrapper {
    width: 92% !important;
  }
}
@media screen and (max-width: 559px) and (min-width: 406px) {
  .reg-blog-wrapper {
    width: 92% !important;
  }
  .reg-fieldset {
    height: 592px !important;
  }
  #form-title {
    font-size: 20px !important;
  }
  .step-title {
    font-size: 20px !important;
  }
  .arrow-steps {
    font-size: 14px !important;
  }
  .text-dark {
    font-size: 14px !important;
  }
  .arrow-steps-wrapper .container {
    padding: 0 0 !important;
  }
}
@media screen and (max-width: 406px) {
  .reg-blog-wrapper {
    width: 92% !important;
  }
  .reg-fieldset {
    height: 655px !important;
  }
  #form-title {
    font-size: 20px !important;
  }
  .step-title {
    font-size: 20px !important;
  }
  .arrow-steps {
    font-size: 14px !important;
  }
  .text-dark {
    font-size: 14px !important;
  }
  .arrow-steps-wrapper .container {
    padding: 0 0 !important;
  }
}
.reg-blog-wrapper-image {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  margin-left: 2px !important;
}
@media screen and (max-width: 500px) {
  .text-arrow-steps {
    width: 71% !important;
  }
}
.arrow-wrapper-triangle {
  width: 0;
  height: 0;
  border-left: 19.715px solid transparent;
  border-right: 19.715px solid transparent;
  border-bottom: 34.015px solid #3c9275;
  transform: rotate(90deg);
}
.arrow-wrapper-reg-mobile {
  display: none !important;
  .arrow-wrapper-square {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 150px;
    height: 70px;
    background-color: #D9D9D9;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    text-align: center;
    padding-left: 10px;
  }
  .arrow-wrapper-square-active {
    display: flex;
    justify-content: start;
    padding-left: 10px;
    align-items: center;
    width: 100px;
    height: 70px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    background-color: #3c9275;
    text-align: center;
  }
  .arrow-wrapper-triangle {
    width: 0;
    height: 0;
    border-left: 19.715px solid transparent;
    border-right: 19.715px solid transparent;
    border-bottom: 34.015px solid #D9D9D9;
    transform: rotate(180deg);
    margin: 10px auto;
  }
  .arrow-wrapper-triangle-active {
    width: 0;
    height: 0;
    border-left: 19.715px solid transparent;
    border-right: 19.715px solid transparent;
    border-bottom: 34.015px solid #3c9275;
    transform: rotate(180deg);
    margin: 10px auto;
  }
}
@media screen and (max-width: 992px) {
  .arrow-wrapper-reg-mobile {
    display: block !important;
  }
}
@media screen and (max-width: 1221px) {
  .text-arrow-steps {
    font-size: 10px;
  }
}
.arrow-steps-badge {
  border-radius: 50%;
}


