/*=== ac-typography ====*/
body.modal-open header,
body.modal-open footer,
body.modal-open #ac-navigation-left,
body.modal-open #ac-main-content *,
body.modal-open #top_slide_area,
body.modal-open #secondary-slider-top,
body.modal-open .search-box-top-fixed-area {
  filter: blur(3px);
}

body.modal-open .modal-backdrop {
  opacity: 1;
  background-color: rgba(8, 25, 43, 0.85);
  filter: unset;
}

.modal,
.modal *,
.tooltip,
.tooltip *,
.popover,
.popover *,
ins,
ins *,
.card,
.card-body,
.non-blur {
  filter: unset !important;
}

body {
  font-size: 14px !important;
}

.ac-heading-1 {
  font-size: 36px;
}

.ac-heading-2 {
  font-size: 32px;
}

.ac-heading-3 {
  font-size: 28px;
}

.ac-heading-4 {
  font-size: 24px;
}

.ac-heading-5 {
  font-size: 20px;
}

.ac-heading-6 {
  font-size: 16px;
}

.ac-heading-1,
.ac-heading-2,
.ac-heading-3,
.ac-heading-4,
.ac-heading-5,
.ac-heading-6 {
  float: none;
  color: inherit;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  text-align: left;
}

.ac-section-title,
.ac-section-title {
  background-color: #333;
  color: #fff;
  padding: 12px 16px;
}

.ac-section-title h1,
.ac-section-title h2 {
  color: #fff;
  font-size: 18px;
  padding: 0;
  margin: 0;
  height: auto;
  padding: 0;
  text-align: left;
  width: auto;
  float: none;
}

/*=== ac-theme-color ====*/
.ac-text-red,
.ac-text-danger,
label.error {
  color: #d73a4e !important;
}

.ac-text-blue,
.ac-text-photo {
  color: #3C9275 !important;
}

.ac-text-sil {
  color: #2ea8ad !important;
}

/*=== ac-theme-bg ====*/
.ac-bg-red,
.ac-bg-danger {
  background-color: #d73a4e !important;
}

.ac-bg-photo {
  background-color: #3C9275 !important;
}

.ac-bg-slh {
  background-color: #2ea8ad !important;
}

/*=== ac-border-color-main ====*/
.ac-border-danger {
  border-color: #d73a4e !important;
}

.ac-border-photo {
  border-color: #3C9275 !important;
}

.ac-border-slh {
  border-color: #2ea8ad !important;
}

/*=== ac-color-main ====*/
.ac-text-red-10 {
  color: #ff767c !important;
}

.ac-text-red-20 {
  color: #fb4b53 !important;
}

.ac-text-red-30 {
  color: #da1e28 !important;
}

.ac-text-red-40 {
  color: #a51920 !important;
}

/*=== ac-bg-main ====*/
.ac-bg-red-10 {
  background-color: #ff767c !important;
}

.ac-bg-red-20 {
  background-color: #fb4b53 !important;
}

.ac-bg-red-30 {
  background-color: #da1e28 !important;
}

.ac-bg-red-40 {
  background-color: #a51920 !important;
}

/*=== ac-color-gray ====*/
.ac-text-gray-10 {
  color: #f7f3f1 !important;
}

.ac-text-gray-20 {
  color: #e0dbda !important;
}

.ac-text-gray-30 {
  color: #c1bcbb !important;
}

.ac-text-gray-40 {
  color: #a7a2a2 !important;
}

.ac-text-gray-50 {
  color: #8f8b8b !important;
}

.ac-text-gray-60 {
  color: #726e6e !important;
}

.ac-text-gray-70 {
  color: #595555 !important;
}

.ac-text-gray-80 {
  color: #403c3c !important;
}

.ac-text-gray-90 {
  color: #2b2828 !important;
}

.ac-text-gray-100 {
  color: #1a1717 !important;
}

/*=== ac-bg-gray ====*/
.ac-bg-gray-10 {
  background-color: #f7f3f1 !important;
}

.ac-bg-gray-20 {
  background-color: #e0dbda !important;
}

.ac-bg-gray-30 {
  background-color: #c1bcbb !important;
}

.ac-bg-gray-40 {
  background-color: #a7a2a2 !important;
}

.ac-bg-gray-50 {
  background-color: #8f8b8b !important;
}

.ac-bg-gray-60 {
  background-color: #726e6e !important;
}

.ac-bg-gray-70 {
  background-color: #595555 !important;
}

.ac-bg-gray-80 {
  background-color: #403c3c !important;
}

.ac-bg-gray-90 {
  background-color: #2b2828 !important;
}

.ac-bg-gray-100 {
  background-color: #1a1717 !important;
}

/*=== ac-alert ====*/
.ac-alert {
  position: relative;
  padding: 8px 16px;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.ac-alert-primary {
  background-color: #2f6bff;
  border-color: #2f6bff;
  color: #fff;
}

.ac-alert-success {
  background-color: #40a629;
  border-color: #40a629;
  color: #fff;
}

.ac-alert-warning {
  background-color: #fdd13a;
  border-color: #fdd13a;
  color: #856404;
}

.ac-alert-warning-text {
  color: #856404;
}

.ac-alert-danger {
  background-color: #da1e28;
  border-color: #da1e28;
  color: #fff;
}

.ac-alert .close {
  background-image: none;
  padding: 0;
  margin: auto;
  bottom: 0;
  right: 16px;
  font-size: 100%;
  width: auto;
  height: auto;
}

/*=== ac-z-index ====*/
.ac-z-index-9 {
  z-index: 9 !important;
}

.ac-z-index-99 {
  z-index: 99 !important;
}

.ac-z-index-999 {
  z-index: 999 !important;
}

.ac-z-index-9999 {
  z-index: 9999 !important;
}

.ac-z-index-99999 {
  z-index: 99999 !important;
}

/*=== ac-accordion ====*/
.ac-accordion .card-header button::after {
  content: "\f107";
  right: 16px;
  margin-left: 16px;
  margin: auto;
  position: absolute;
  font-family: "FontAwesome";
  font-size: 16px;
  line-height: 1;
  transition: 0.3s;
}

.ac-accordion .card-header button[aria-expanded="true"]::after {
  transform: rotate(-180deg);
  transition: 0.3s;
}

/*=== ac-breadcrumb ====*/
.ac-breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 8px;
}

.ac-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  padding-right: 8px;
}

/*=== ac-button ====*/
[class*="ac-btn"] {
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 100%;
  line-height: 1.5;
  padding: 8px 16px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: solid 1px transparent;
  outline: none !important;
  box-shadow: none !important;
  transition: 0.3s;
  border-radius: 0.25rem;
}

[class*="ac-btn"].disabled {
  opacity: 0.65;
  cursor: not-allowed;
  pointer-events: none;
}

[class*="ac-btn"]:hover {
  text-decoration: none;
  transition: 0.3s;
}

.ac-btn {
  padding: 4px 16px;
}

.ac-btn-xs {
  padding: 4px 8px;
}

.ac-btn-sm {
  padding: 8px 16px;
}

.ac-btn-md {
  padding: 12px 24px;
}

.ac-btn-lg {
  padding: 16px 32px;
}

.ac-btn-danger {
  border: solid 1px #d73a4f !important;
  background-color: #d73a4f !important;
  color: #fff !important;
}

.ac-btn-danger:hover {
  background-color: #c82333 !important;
  color: #fff !important;
}

.ac-btn-outline-danger {
  border: solid 1px #d73a4f !important;
  color: #d73a4f !important;
  background-color: #fff !important;
}

.ac-btn-outline-danger:hover {
  border: solid 1px #d73a4f !important;
  color: #fff !important;
  background-color: #d73a4f !important;
}

.ac-btn-secondary {
  border: solid 1px #6c757d !important;
  background-color: #6c757d !important;
  color: #fff !important;
}

.ac-btn-secondary:hover {
  background-color: #5a6268 !important;
  color: #fff !important;
}

.ac-btn-outline-secondary {
  border: solid 1px #6c757d;
  color: #6c757d !important;
  background-color: #fff !important;
}

.ac-btn-outline-secondary:hover {
  border: solid 1px #6c757d !important;
  color: #fff !important;
  background-color: #6c757d !important;
}

.ac-btn-photo {
  border: solid 1px #3c9275 !important;
  background-color: #3c9275 !important;
  color: #fff !important;
}

.ac-btn-photo[disabled] {
  border: solid 1px #6e767f !important;
  background-color: #6e767f !important;
  color: #fff !important;
}

.ac-btn-photo[disabled]:hover {
  border: solid 1px #6e767f !important;
  background-color: #6e767f !important;
  color: #fff !important;
}

.ac-btn-photo:hover {
  border: solid 1px #3c9275 !important;
  background-color: #3c9275 !important;
  color: #fff !important;
}

.ac-btn-outline-photo {
  border: solid 1px #3C9275 !important;
  color: #3C9275 !important;
  background-color: #fff !important;
}

.ac-btn-outline-photo:hover {
  border: solid 1px #3C9275 !important;
  color: #fff !important;
  background-color: #3C9275 !important;
}

.ac-btn-sil {
  border: solid 1px #2ea8ad !important;
  background-color: #2ea8ad !important;
  color: #fff !important;
}

.ac-btn-sil:hover {
  border: solid 1px #008687 !important;
  background-color: #008687 !important;
  color: #fff !important;
  border: solid 1px #008687 !important;
}

.ac-btn-outline-sil {
  border: solid 1px #2ea8ad !important;
  color: #2ea8ad !important;
  background-color: #fff !important;
}

.ac-btn-outline-sil:hover {
  border: solid 1px #2ea8ad !important;
  color: #fff !important;
  background-color: #2ea8ad !important;
}

.ac-btn-ededed {
  background-color: #ededed !important;
  color: #333 !important;
}

.ac-btn-ededed:hover {
  background-color: #333333 !important;
  color: #fff !important;
}

.ac-btn-ededed.disabled {
  opacity: 0.6 !important;
  cursor: not-allowed;
}

.ac-btn-ededed.disabled:hover {
  background-color: #ededed !important;
  color: #333 !important;
}

.ac-btn-outline-white {
  border: solid 1px #fff !important;
  color: #fff !important;
  background-color: transparent !important;
}

.ac-btn-outline-white:hover {
  border: solid 1px #fff !important;
  color: #000 !important;
  background-color: #fff !important;
}

/*=== ac-checkbox ====*/
/*input.ac-checkbox{height: 20px;width: 20px}*/
.custom-control {
  min-height: auto;
}

.custom-control-input,
.custom-control-label {
  cursor: pointer;
}

.ac-checkbox .custom-control-label::before,
.ac-checkbox .custom-control-label::after {
  top: 0.1rem;
  border-radius: 4px;
}

.ac-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #333;
  background-color: #333;
}

.ac-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

/*=== ac-select ====*/
.ac-custom-select {
  padding: 7px 32px 4px 16px;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGc+DQoJCTxwYXRoIGQ9Ik01MDYuMTU3LDEzMi4zODZjLTcuODAzLTcuODE5LTIwLjQ2NS03LjgzMS0yOC4yODUtMC4wMjlsLTIwNy43MywyMDcuMjk5Yy03Ljc5OSw3Ljc5OC0yMC40ODYsNy43OTctMjguMjk5LTAuMDE1DQoJCQlMMzQuMTI4LDEzMi4zNTdjLTcuODE5LTcuODAzLTIwLjQ4MS03Ljc5LTI4LjI4NSwwLjAyOWMtNy44MDIsNy44MTktNy43ODksMjAuNDgyLDAuMDI5LDI4LjI4NGwyMDcuNzAxLDIwNy4yNw0KCQkJYzExLjcwMSwxMS42OTksMjcuMDY2LDE3LjU0Nyw0Mi40MzMsMTcuNTQ3YzE1LjM1OCwwLDMwLjcxOS01Ljg0Niw0Mi40MDUtMTcuNTMzTDUwNi4xMjgsMTYwLjY3DQoJCQlDNTEzLjk0NiwxNTIuODY4LDUxMy45NTksMTQwLjIwNSw1MDYuMTU3LDEzMi4zODZ6Ii8+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=)
  no-repeat right 16px center/8px 8px;
  font-size: 14px;
  height: auto;
  border-radius: 4px;
}

/*=== ac-table ====*/
.ac-table.table td,
.ac-table.table th {
  padding: 16px;
}

/*=== ac-table ====*/
.ac-form-control {
  padding: 4px 16px;
  height: auto;
  font-size: inherit;
}

.ac-form-control::-webkit-input-placeholder {
  color: #c1bcbb !important;
}

/*=== ac-dropdown-toggle ====*/
.ac-dropdown-toggle::after {
  display: inline-block;
  margin-left: 16px;
  content: "";
  border-top: 0.4rem solid;
  border-right: 0.4rem solid transparent;
  border-bottom: 0;
  border-left: 0.4rem solid transparent;
}

.font-inherit {
  font-size: inherit !important;
}

/*=== ac-spinner-border ====*/
.ac-spinner-border {
  border-right-color: #fff;
  width: 1.5rem;
  height: 1.5rem;
}

/*=== ac-list ====*/
.ac-list-ordered,
.ac-list-disc,
.ac-list-circle {
  padding-left: 12px;
}

.ac-list-ordered > li {
  list-style-type: decimal;
}

.ac-list-disc > li {
  list-style-type: disc;
}

.ac-list-circle > li {
  list-style-type: circle;
}

/*=== ac-rounded ====*/
.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/*=== ac-modal ====*/
.ac-modal .modal-header,
.ac-modal .modal-content {
  border: none;
  border-radius: 0.3rem;
  padding: 0;
}

.ac-modal .modal-title {
  font-size: 16px;
}

.ac-modal button.close {
  cursor: pointer;
  text-align: initial;
  height: initial;
  background: none;
  position: absolute;
  right: 4px;
  top: 4px;
  width: auto;
  height: auto;
  padding: 0;
  font-size: 24px;
  text-align: center;
  border-radius: none;
  opacity: 1;
  border: none;
  margin: 0;
  color: #a7a2a2 !important;
  z-index: 99;
}

.ac-modal button.close span {
  background: none;
  height: 24px;
  width: 24px;
  border: none;
  color: #8f8b8b;
  font-size: 0;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ac-modal button.close span:before {
  content: "";
  background-image: url("/assets/img/close.svg");
  height: 100%;
  width: 100%;
  background-size: cover;
}

.ac-modal button.close:hover {
  background: none;
}

.ac-stroke-white-1 {
  -webkit-text-stroke: 1px white;
}

.ac-stroke-black-1 {
  -webkit-text-stroke: 1px black;
}

@media screen and (max-width: 768px) {
  .modal.ac-modal .modal-dialog {
    margin-top: 60px;
  }
}

/*=== ac-pagination ====*/
.ac-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ac-pagination > li {
  height: auto !important;
  width: auto !important;
  margin-right: 16px !important;
}

.ac-pagination > li:last-child {
  margin-right: 0 !important;
}

.ac-pagination a.paginator_p:first-child {
  opacity: 0 !important;
  visibility: hidden !important;
}

.ac-pagination .previous,
.ac-pagination .next {
  float: none;
  height: auto;
  width: auto;
  display: flex;
  align-items: center;
}

.ac-pagination > li > a,
.ac-pagination li em,
.ac-pagination a.paginator_p,
.ac-pagination .previous a,
.ac-pagination .next a {
  padding: 8px !important;
  display: block;
  border-top: 3px solid transparent !important;
  border-bottom: 3px solid transparent !important;
  transition: 0.3s;
  background-color: transparent;
}

.ac-pagination > li:not(.previous, .next) > a:hover,
.ac-pagination a.paginator_p:hover {
  border-bottom-color: #3C9275 !important;
  transition: 0.3s;
  text-decoration: none;
}

.ac-pagination > li.active > button,
.ac-pagination > li.active > a,
.ac-pagination > li.current > a,
.ac-pagination a.paginator_p.selected,
.ac-pagination li em {
  border-bottom: 3px solid #3C9275 !important;
}

/*=== ac-custom-radio ====*/
.ac-custom-radio {
  padding-left: 28px;
}

.ac-custom-radio.custom-radio-sm {
  padding-left: 24px;
}

.ac-custom-radio .custom-control-input {
  z-index: 9;
}

.ac-custom-radio .custom-control-label::before {
  height: 20px;
  width: 20px;
  left: -28px;
  top: 0;
  box-shadow: none;
}

.ac-custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: transparent;
  border-color: #000;
}

.ac-custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  height: 8px;
  width: 8px;
  left: -22px;
  top: 6px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjEuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMjkuMTA3IDI5LjEwNyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkuMTA3IDI5LjEwNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGcgaWQ9ImMxNDdfZnVsbF9tb29uIj4NCgkJPGc+DQoJCQk8cGF0aCBkPSJNMTQuNTU0LDBDNi41NjEsMCwwLDYuNTYyLDAsMTQuNTUyYzAsNy45OTYsNi41NjEsMTQuNTU1LDE0LjU1NCwxNC41NTVjNy45OTYsMCwxNC41NTMtNi41NTksMTQuNTUzLTE0LjU1NQ0KCQkJCUMyOS4xMDYsNi41NjIsMjIuNTUsMCwxNC41NTQsMHoiLz4NCgkJPC9nPg0KCTwvZz4NCgk8ZyBpZD0iQ2FwYV8xXzE0XyI+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: cover;
}

.ac-custom-radio.custom-radio-sm .custom-control-label::before {
  height: 16px;
  width: 16px;
  left: -24px;
  top: 2px;
  box-shadow: none;
}

.ac-custom-radio.custom-radio-sm
  .custom-control-input:checked
  ~ .custom-control-label::after {
  height: 8px;
  width: 8px;
  left: -20px;
  top: 6px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjEuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMjkuMTA3IDI5LjEwNyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkuMTA3IDI5LjEwNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGcgaWQ9ImMxNDdfZnVsbF9tb29uIj4NCgkJPGc+DQoJCQk8cGF0aCBkPSJNMTQuNTU0LDBDNi41NjEsMCwwLDYuNTYyLDAsMTQuNTUyYzAsNy45OTYsNi41NjEsMTQuNTU1LDE0LjU1NCwxNC41NTVjNy45OTYsMCwxNC41NTMtNi41NTksMTQuNTUzLTE0LjU1NQ0KCQkJCUMyOS4xMDYsNi41NjIsMjIuNTUsMCwxNC41NTQsMHoiLz4NCgkJPC9nPg0KCTwvZz4NCgk8ZyBpZD0iQ2FwYV8xXzE0XyI+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: cover;
}

.ac-custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.ac-custom-radio
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  background-color: transparent;
  border-color: #000;
}

/*=== ac-table ====*/
.ac-table td,
.ac-table th {
  padding: 16px;
}

/*=== ac-navtabs ====*/
.ac-nav-tabs .nav-item.show .nav-link,
.ac-nav-tabs .nav-link.active {
  background-color: #d73a4e;
  color: #fff;
}

/*=== ac-switch ====*/
.ac-custom-switch-sm.custom-switch {
  padding-left: 40px;
}

.ac-custom-switch-sm.custom-switch .custom-control-label::before {
  width: 32px;
  height: 16px;
  left: -40px;
}

.ac-custom-switch-sm.custom-switch .custom-control-label::after {
  left: -38px;
}

.ac-custom-switch-sm.custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(16px);
}

.ac-custom-switch-md.custom-switch {
  padding-left: 21px;
}

.ac-custom-switch-md.custom-switch .custom-control-label::before {
  width: 48px;
  height: 24px;
  left: -56px;
  border-radius: 16px;
  top: 0;
}

.ac-custom-switch-md.custom-switch .custom-control-label::after {
  width: calc(24px - 4px);
  height: calc(24px - 4px);
  border-radius: 50%;
  left: -54px;
  top: 2px;
}

.ac-custom-switch-md.custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(24px);
}

/*=== ac-tooltip ====*/
.tooltip-inner {
  padding: 4px 8px;
  text-align: left;
  border-radius: 4px;
}

/*=== ac-popover ====*/
.ac-popover .popover-body {
  padding: 4px 8px;
}

/*=== ac-navigation-link ====*/
.ac-navigation-link a {
  padding: 8px 16px;
  display: block;
  border-bottom: solid 1px #eee;
}

.ac-navigation-link a:hover,
.ac-navigation-link a:focus {
  background-color: #f1f1f1;
  text-decoration: none;
}

.ac-navigation-link a i {
  transition: 0.25s;
}

.ac-navigation-link a[aria-expanded="true"] i {
  transform: rotate(90deg);
  transition: 0.25s;
}

/*=== ac-row-list-items ====*/
.ac-row-list-items .inner .advertising-icon {
  top: 8px;
  left: 8px;
}

.ac-row-list-items .inner .advertising-icon:nth-child(2) {
  top: 42px;
}

.ac-row-list-items .inner .img-hover-actions .img-data-info {
  padding: 0 8px 8px 8px;
}

/*=== ac-bg ====*/
.bg-f9e2e5 {
  background-color: #f9e2e5;
}

.bg-e6e6e6 {
  background-color: #e6e6e6 !important;
}

.bg-666 {
  background-color: #666 !important;
}

.bg-f1f1f1 {
  background-color: #f1f1f1 !important;
}

.bg-f5f5f5 {
  background-color: #f5f5f5 !important;
}

.bg-fcdee3 {
  background-color: #fcdee3 !important;
}

.bg-56bb3A {
  background-color: #56bb3a !important;
}

.bg-f7f9fa {
  background-color: #f7f9fa !important;
}

/*=== ac-on-hover ====*/
.on-hover-border-danger:hover {
  border-color: #d73a4e !important;
}

.on-hover-e6e6e6:hover {
  background-color: #e6e6e6 !important;
}

.on-hover-ccc:hover {
  background-color: #ccc !important;
}

.on-hover-f8f9fa:hover {
  background-color: #f8f9fa !important;
}

/*=== ac-overide ====*/
form .row {
  overflow: unset;
}

/*=== ac-display ====*/
.ac-d-none {
  display: none !important;
}

.ac-d-flex {
  display: flex !important;
}

.ac-d-inline-flex {
  display: inline-flex !important;
}

.ac-d-inline {
  display: inline !important;
}

.ac-d-inline-block {
  display: inline-block !important;
}

.ac-d-block {
  display: block !important;
}

.ac-d-table-cell {
  display: table-cell !important;
}

.ac-d-table-row {
  display: table-row !important;
}

.ac-flex-row {
  flex-direction: row !important;
}

.ac-flex-column {
  flex-direction: column !important;
}

.ac-flex-column-reverse {
  flex-direction: column-reverse !important;
}

.ac-flex-grow-0 {
  flex-grow: 0 !important;
}

.ac-flex-grow-1 {
  flex-grow: 1 !important;
}

.ac-flex-wrap {
  flex-wrap: wrap !important;
}

.ac-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.ac-flex-nowrap {
  flex-wrap: nowrap !important;
}

.ac-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.ac-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.ac-align-items-baseline {
  align-items: baseline !important;
}

.ac-align-items-center {
  align-items: center !important;
}

.ac-align-items-start {
  align-items: flex-start !important;
}

.ac-align-items-end {
  align-items: flex-end !important;
}

.ac-align-items-stretch {
  align-items: stretch !important;
}

.ac-justify-content-center {
  justify-content: center !important;
}

.ac-justify-content-start {
  justify-content: flex-start !important;
}

.ac-justify-content-end {
  justify-content: flex-end !important;
}

.ac-justify-content-around {
  justify-content: space-around !important;
}

.ac-justify-content-between {
  justify-content: space-between !important;
}

/*=== ac-custom-text-position ====*/
.ac-text-center {
  text-align: center !important;
}

.ac-text-left {
  text-align: left !important;
}

.ac-text-right {
  text-align: right !important;
}

/*=== ac-custom-position ====*/
.ac-position-relative {
  position: relative !important;
}

.ac-position-absolute {
  position: absolute !important;
}

.ac-position-fixed {
  position: fixed !important;
}

/*=== ac-custom-row ====*/
.ac-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -8px !important;
  margin-left: -8px !important;
}

.ac-row [class*="ac-col"] {
  position: relative;
  width: 100%;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.ac-no-gutters {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ac-no-gutters > [class*="ac-col"] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.ac-col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.ac-col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.ac-col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.ac-col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.ac-col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.ac-col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.ac-col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.ac-col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.ac-col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.ac-col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.ac-col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.ac-col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

/*=== ac-col-offset ====*/
.ac-offset-1 {
  margin-left: 8.333333%;
}

.ac-offset-2 {
  margin-left: 16.666667%;
}

.ac-offset-3 {
  margin-left: 25%;
}

.ac-offset-4 {
  margin-left: 33.333333%;
}

.ac-offset-5 {
  margin-left: 41.666667%;
}

.ac-offset-6 {
  margin-left: 50%;
}

.ac-offset-7 {
  margin-left: 58.333333%;
}

.ac-offset-8 {
  margin-left: 66.666667%;
}

.ac-offset-9 {
  margin-left: 75%;
}

.ac-offset-10 {
  margin-left: 83.333333%;
}

.ac-offset-11 {
  margin-left: 91.666667%;
}

/*=== ac-padding ====*/
.ac-p-0 {
  padding: 0 !important;
}

.ac-pt-0,
.ac-py-0 {
  padding-top: 0 !important;
}

.ac-pr-0,
.ac-px-0 {
  padding-right: 0 !important;
}

.ac-pb-0,
.ac-py-0 {
  padding-bottom: 0 !important;
}

.ac-pl-0,
.ac-px-0 {
  padding-left: 0 !important;
}

.ac-p-1 {
  padding: 8px !important;
}

.ac-pt-1,
.ac-py-1 {
  padding-top: 8px !important;
}

.ac-pr-1,
.ac-px-1 {
  padding-right: 8px !important;
}

.ac-pb-1,
.ac-py-1 {
  padding-bottom: 8px !important;
}

.ac-pl-1,
.ac-px-1 {
  padding-left: 8px !important;
}

.ac-p-2 {
  padding: 16px !important;
}

.ac-pt-2,
.ac-py-2 {
  padding-top: 16px !important;
}

.ac-pr-2,
.ac-px-2 {
  padding-right: 16px !important;
}

.ac-pb-2,
.ac-py-2 {
  padding-bottom: 16px !important;
}

.ac-pl-2,
.ac-px-2 {
  padding-left: 16px !important;
}

.ac-p-3 {
  padding: 24px !important;
}

.ac-pt-3,
.ac-py-3 {
  padding-top: 24px !important;
}

.ac-pr-3,
.ac-px-3 {
  padding-right: 24px !important;
}

.ac-pb-3,
.ac-py-3 {
  padding-bottom: 24px !important;
}

.ac-pl-3,
.ac-px-3 {
  padding-left: 24px !important;
}

.ac-p-4 {
  padding: 32px !important;
}

.ac-pt-4,
.ac-py-4 {
  padding-top: 32px !important;
}

.ac-pr-4,
.ac-px-4 {
  padding-right: 32px !important;
}

.ac-pb-4,
.ac-py-4 {
  padding-bottom: 32px !important;
}

.ac-pl-4,
.ac-px-4 {
  padding-left: 32px !important;
}

.ac-p-5 {
  padding: 48px !important;
}

.ac-pt-5,
.ac-py-5 {
  padding-top: 48px !important;
}

.ac-pr-5,
.ac-px-5 {
  padding-right: 48px !important;
}

.ac-pb-5,
.ac-py-5 {
  padding-bottom: 48px !important;
}

.ac-pl-5,
.ac-px-5 {
  padding-left: 48px !important;
}

/*=== ac-margin ====*/
.ac-m-auto {
  margin: auto !important;
}

.ac-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.ac-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.ac-m-0 {
  margin: 0 !important;
}

.ac-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.ac-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ac-mt-0,
.ac-my-0 {
  margin-top: 0 !important;
}

.ac-mr-0,
.ac-mx-0 {
  margin-right: 0 !important;
}

.ac-mb-0,
.ac-my-0 {
  margin-bottom: 0 !important;
}

.ac-ml-0,
.ac-mx-0 {
  margin-left: 0 !important;
}

.ac-m-1 {
  margin: 8px !important;
}

.ac-mt-1,
.ac-my-1 {
  margin-top: 8px !important;
}

.ac-mr-1,
.ac-mx-1 {
  margin-right: 8px !important;
}

.ac-mb-1,
.ac-my-1 {
  margin-bottom: 8px !important;
}

.ac-ml-1,
.ac-mx-1 {
  margin-left: 8px !important;
}

.ac-m-2 {
  margin: 16px !important;
}

.ac-mt-2,
.ac-my-2 {
  margin-top: 16px !important;
}

.ac-mr-2,
.ac-mx-2 {
  margin-right: 16px !important;
}

.ac-mb-2,
.ac-my-2 {
  margin-bottom: 16px !important;
}

.ac-ml-2,
.ac-mx-2 {
  margin-left: 16px !important;
}

.ac-m-3 {
  margin: 24px !important;
}

.ac-mt-3,
.ac-my-3 {
  margin-top: 24px !important;
}

.ac-mr-3,
.ac-mx-3 {
  margin-right: 24px !important;
}

.ac-mb-3,
.ac-my-3 {
  margin-bottom: 24px !important;
}

.ac-ml-3,
.ac-mx-3 {
  margin-left: 24px !important;
}

.ac-m-4 {
  margin: 32px !important;
}

.ac-mt-4,
.ac-my-4 {
  margin-top: 32px !important;
}

.ac-mr-4,
.ac-mx-4 {
  margin-right: 32px !important;
}

.ac-mb-4,
.ac-my-4 {
  margin-bottom: 32px !important;
}

.ac-ml-4,
.ac-mx-4 {
  margin-left: 32px !important;
}

.ac-m-5 {
  margin: 48px !important;
}

.ac-mt-5,
.ac-my-5 {
  margin-top: 48px !important;
}

.ac-mr-5,
.ac-mx-5 {
  margin-right: 48px !important;
}

.ac-mb-5,
.ac-my-5 {
  margin-bottom: 48px !important;
}

.ac-ml-5,
.ac-mx-5 {
  margin-left: 48px !important;
}

.ac-m-n1 {
  margin: -8px !important;
}

.ac-mt-n1,
.ac-my-n1 {
  margin-top: -8px !important;
}

.ac-mr-n1,
.ac-mx-n1 {
  margin-right: -8px !important;
}

.ac-mb-n1,
.ac-my-n1 {
  margin-bottom: -8px !important;
}

.ac-ml-n1,
.ac-mx-n1 {
  margin-left: -8px !important;
}

.ac-m-n2 {
  margin: -16px !important;
}

.ac-mt-n2,
.ac-my-n2 {
  margin-top: -16px !important;
}

.ac-mr-n2,
.ac-mx-n2 {
  margin-right: -16px !important;
}

.ac-mb-n2,
.ac-my-n2 {
  margin-bottom: -16px !important;
}

.ac-ml-n2,
.ac-mx-n2 {
  margin-left: -16px !important;
}

.ac-m-n3 {
  margin: -24px !important;
}

.ac-mt-n3,
.ac-my-n3 {
  margin-top: -24px !important;
}

.ac-mr-n3,
.ac-mx-n3 {
  margin-right: -24px !important;
}

.ac-mb-n3,
.ac-my-n3 {
  margin-bottom: -24px !important;
}

.ac-ml-n3,
.ac-mx-n3 {
  margin-left: -24px !important;
}

.ac-m-n4 {
  margin: -32px !important;
}

.ac-mt-n4,
.ac-my-n4 {
  margin-top: -32px !important;
}

.ac-mr-n4,
.ac-mx-n4 {
  margin-right: -32px !important;
}

.ac-mb-n4,
.ac-my-n4 {
  margin-bottom: -32px !important;
}

.ac-ml-n4,
.ac-mx-n4 {
  margin-left: -32px !important;
}

.ac-m-n5 {
  margin: -48px !important;
}

.ac-mt-n5,
.ac-my-n5 {
  margin-top: -48px !important;
}

.ac-mr-n5,
.ac-mx-n5 {
  margin-right: -48px !important;
}

.ac-mb-n5,
.ac-my-n5 {
  margin-bottom: -48px !important;
}

.ac-ml-n5,
.ac-mx-n5 {
  margin-left: -48px !important;
}

/*=== ac-breakpoint ====*/
@media (min-width: 320px) {
  .ac-m-sm-auto {
    margin: auto !important;
  }

  .ac-mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .ac-my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .ac-m-sm-0 {
    margin: 0 !important;
  }

  .ac-mt-sm-0,
  .ac-my-sm-0 {
    margin-top: 0 !important;
  }

  .ac-mr-sm-0,
  .ac-mx-sm-0 {
    margin-right: 0 !important;
  }

  .ac-mb-sm-0,
  .ac-my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ac-ml-sm-0,
  .ac-mx-sm-0 {
    margin-left: 0 !important;
  }

  .ac-m-sm-1 {
    margin: 8px !important;
  }

  .ac-mt-sm-1,
  .ac-my-sm-1 {
    margin-top: 8px !important;
  }

  .ac-mr-sm-1,
  .ac-mx-sm-1 {
    margin-right: 8px !important;
  }

  .ac-mb-sm-1,
  .ac-my-sm-1 {
    margin-bottom: 8px !important;
  }

  .ac-ml-sm-1,
  .ac-mx-sm-1 {
    margin-left: 8px !important;
  }

  .ac-m-sm-2 {
    margin: 16px !important;
  }

  .ac-mt-sm-2,
  .ac-my-sm-2 {
    margin-top: 16px !important;
  }

  .ac-mr-sm-2,
  .ac-mx-sm-2 {
    margin-right: 16px !important;
  }

  .ac-mb-sm-2,
  .ac-my-sm-2 {
    margin-bottom: 16px !important;
  }

  .ac-ml-sm-2,
  .ac-mx-sm-2 {
    margin-left: 16px !important;
  }

  .ac-m-sm-3 {
    margin: 24px !important;
  }

  .ac-mt-sm-3,
  .ac-my-sm-3 {
    margin-top: 24px !important;
  }

  .ac-mr-sm-3,
  .ac-mx-sm-3 {
    margin-right: 24px !important;
  }

  .ac-mb-sm-3,
  .ac-my-sm-3 {
    margin-bottom: 24px !important;
  }

  .ac-ml-sm-3,
  .ac-mx-sm-3 {
    margin-left: 24px !important;
  }

  .ac-m-sm-4 {
    margin: 32px !important;
  }

  .ac-mt-sm-4,
  .ac-my-sm-4 {
    margin-top: 32px !important;
  }

  .ac-mr-sm-4,
  .ac-mx-sm-4 {
    margin-right: 32px !important;
  }

  .ac-mb-sm-4,
  .ac-my-sm-4 {
    margin-bottom: 32px !important;
  }

  .ac-ml-sm-4,
  .ac-mx-sm-4 {
    margin-left: 32px !important;
  }

  .ac-m-sm-5 {
    margin: 48px !important;
  }

  .ac-mt-sm-5,
  .ac-my-sm-5 {
    margin-top: 48px !important;
  }

  .ac-mr-sm-5,
  .ac-mx-sm-5 {
    margin-right: 48px !important;
  }

  .ac-mb-sm-5,
  .ac-my-sm-5 {
    margin-bottom: 48px !important;
  }

  .ac-ml-sm-5,
  .ac-mx-sm-5 {
    margin-left: 48px !important;
  }

  .ac-p-sm-0 {
    padding: 0 !important;
  }

  .ac-pt-sm-0,
  .ac-py-sm-0 {
    padding-top: 0 !important;
  }

  .ac-pr-sm-0,
  .ac-px-sm-0 {
    padding-right: 0 !important;
  }

  .ac-pb-sm-0,
  .ac-py-sm-0 {
    padding-bottom: 0 !important;
  }

  .ac-pl-sm-0,
  .ac-px-sm-0 {
    padding-left: 0 !important;
  }

  .ac-p-sm-1 {
    padding: 8px !important;
  }

  .ac-pt-sm-1,
  .ac-py-sm-1 {
    padding-top: 8px !important;
  }

  .ac-pr-sm-1,
  .ac-px-sm-1 {
    padding-right: 8px !important;
  }

  .ac-pb-sm-1,
  .ac-py-sm-1 {
    padding-bottom: 8px !important;
  }

  .ac-pl-sm-1,
  .ac-px-sm-1 {
    padding-left: 8px !important;
  }

  .ac-p-sm-2 {
    padding: 16px !important;
  }

  .ac-pt-sm-2,
  .ac-py-sm-2 {
    padding-top: 16px !important;
  }

  .ac-pr-sm-2,
  .ac-px-sm-2 {
    padding-right: 16px !important;
  }

  .ac-pb-sm-2,
  .ac-py-sm-2 {
    padding-bottom: 16px !important;
  }

  .ac-pl-sm-2,
  .ac-px-sm-2 {
    padding-left: 16px !important;
  }

  .ac-p-sm-3 {
    padding: 24px !important;
  }

  .ac-pt-sm-3,
  .ac-py-sm-3 {
    padding-top: 24px !important;
  }

  .ac-pr-sm-3,
  .ac-px-sm-3 {
    padding-right: 24px !important;
  }

  .ac-pb-sm-3,
  .ac-py-sm-3 {
    padding-bottom: 24px !important;
  }

  .ac-pl-sm-3,
  .ac-px-sm-3 {
    padding-left: 24px !important;
  }

  .ac-p-sm-4 {
    padding: 32px !important;
  }

  .ac-pt-sm-4,
  .ac-py-sm-4 {
    padding-top: 32px !important;
  }

  .ac-pr-sm-4,
  .ac-px-sm-4 {
    padding-right: 32px !important;
  }

  .ac-pb-sm-4,
  .ac-py-sm-4 {
    padding-bottom: 32px !important;
  }

  .ac-pl-sm-4,
  .ac-px-sm-4 {
    padding-left: 32px !important;
  }

  .ac-p-sm-5 {
    padding: 48px !important;
  }

  .ac-pt-sm-5,
  .ac-py-sm-5 {
    padding-top: 48px !important;
  }

  .ac-pr-sm-5,
  .ac-px-sm-5 {
    padding-right: 48px !important;
  }

  .ac-pb-sm-5,
  .ac-py-sm-5 {
    padding-bottom: 48px !important;
  }

  .ac-pl-sm-5,
  .ac-px-sm-5 {
    padding-left: 48px !important;
  }

  .ac-m-sm-n1 {
    margin: -8px !important;
  }

  .ac-mt-sm-n1,
  .ac-my-sm-n1 {
    margin-top: -8px !important;
  }

  .ac-mr-sm-n1,
  .ac-mx-sm-n1 {
    margin-right: -8px !important;
  }

  .ac-mb-sm-n1,
  .ac-my-sm-n1 {
    margin-bottom: -8px !important;
  }

  .ac-ml-sm-n1,
  .ac-mx-sm-n1 {
    margin-left: -8px !important;
  }

  .ac-m-sm-n2 {
    margin: -16px !important;
  }

  .ac-mt-sm-n2,
  .ac-my-sm-n2 {
    margin-top: -16px !important;
  }

  .ac-mr-sm-n2,
  .ac-mx-sm-n2 {
    margin-right: -16px !important;
  }

  .ac-mb-sm-n2,
  .ac-my-sm-n2 {
    margin-bottom: -16px !important;
  }

  .ac-ml-sm-n2,
  .ac-mx-sm-n2 {
    margin-left: -16px !important;
  }

  .ac-m-sm-n3 {
    margin: -24px !important;
  }

  .ac-mt-sm-n3,
  .ac-my-sm-n3 {
    margin-top: -24px !important;
  }

  .ac-mr-sm-n3,
  .ac-mx-sm-n3 {
    margin-right: -24px !important;
  }

  .ac-mb-sm-n3,
  .ac-my-sm-n3 {
    margin-bottom: -24px !important;
  }

  .ac-ml-sm-n3,
  .ac-mx-sm-n3 {
    margin-left: -24px !important;
  }

  .ac-m-sm-n4 {
    margin: -32px !important;
  }

  .ac-mt-sm-n4,
  .ac-my-sm-n4 {
    margin-top: -32px !important;
  }

  .ac-mr-sm-n4,
  .ac-mx-sm-n4 {
    margin-right: -32px !important;
  }

  .ac-mb-sm-n4,
  .ac-my-sm-n4 {
    margin-bottom: -32px !important;
  }

  .ac-ml-sm-n4,
  .ac-mx-sm-n4 {
    margin-left: -32px !important;
  }

  .ac-m-sm-n5 {
    margin: -48px !important;
  }

  .ac-mt-sm-n5,
  .ac-my-sm-n5 {
    margin-top: -48px !important;
  }

  .ac-mr-sm-n5,
  .ac-mx-sm-n5 {
    margin-right: -48px !important;
  }

  .ac-mb-sm-n5,
  .ac-my-sm-n5 {
    margin-bottom: -48px !important;
  }

  .ac-ml-sm-n5,
  .ac-mx-sm-n5 {
    margin-left: -48px !important;
  }

  .ac-m-sm-auto {
    margin: auto !important;
  }

  .ac-mt-sm-auto,
  .ac-my-sm-auto {
    margin-top: auto !important;
  }

  .ac-mr-sm-auto,
  .ac-mx-sm-auto {
    margin-right: auto !important;
  }

  .ac-mb-sm-auto,
  .ac-my-sm-auto {
    margin-bottom: auto !important;
  }

  .ac-ml-sm-auto,
  .ac-mx-sm-auto {
    margin-left: auto !important;
  }

  .ac-d-sm-none {
    display: none !important;
  }

  .ac-d-sm-flex {
    display: flex !important;
  }

  .ac-d-sm-inline-flex {
    display: inline-flex !important;
  }

  .ac-d-sm-inline {
    display: inline !important;
  }

  .ac-d-sm-inline-block {
    display: inline-block !important;
  }

  .ac-d-sm-block {
    display: block !important;
  }

  .ac-d-sm-table-cell {
    display: table-cell !important;
  }

  .ac-d-lg-table-row {
    display: table-row !important;
  }

  .ac-flex-sm-row {
    flex-direction: row !important;
  }

  .ac-flex-sm-column {
    flex-direction: column !important;
  }

  .ac-flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .ac-flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .ac-flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .ac-flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .ac-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .ac-flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .ac-flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .ac-flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .ac-align-items-sm-baseline {
    align-items: baseline !important;
  }

  .ac-align-items-sm-center {
    align-items: center !important;
  }

  .ac-align-items-sm-start {
    align-items: flex-start !important;
  }

  .ac-align-items-sm-end {
    align-items: flex-end !important;
  }

  .ac-align-items-sm-stretch {
    align-items: stretch !important;
  }

  .ac-justify-content-sm-center {
    justify-content: center !important;
  }

  .ac-justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .ac-justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .ac-justify-content-sm-around {
    justify-content: space-around !important;
  }

  .ac-justify-content-sm-between {
    justify-content: space-between !important;
  }

  .ac-text-sm-center {
    text-align: center !important;
  }

  .ac-text-sm-left {
    text-align: left !important;
  }

  .ac-text-sm-right {
    text-align: right !important;
  }

  .ac-position-sm-relative {
    position: relative !important;
  }

  .ac-position-sm-absolute {
    position: absolute !important;
  }

  .ac-position-sm-fixed {
    position: fixed !important;
  }

  .ac-col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .ac-col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .ac-col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ac-col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .ac-col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .ac-col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ac-col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .ac-col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .ac-col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ac-col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .ac-col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .ac-col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ac-offset-sm-0 {
    margin-left: 0%;
  }

  .ac-offset-sm-1 {
    margin-left: 8.333333%;
  }

  .ac-offset-sm-2 {
    margin-left: 16.666667%;
  }

  .ac-offset-sm-3 {
    margin-left: 25%;
  }

  .ac-offset-sm-4 {
    margin-left: 33.333333%;
  }

  .ac-offset-sm-5 {
    margin-left: 41.666667%;
  }

  .ac-offset-sm-6 {
    margin-left: 50%;
  }

  .ac-offset-sm-7 {
    margin-left: 58.333333%;
  }

  .ac-offset-sm-8 {
    margin-left: 66.666667%;
  }

  .ac-offset-sm-9 {
    margin-left: 75%;
  }

  .ac-offset-sm-10 {
    margin-left: 83.333333%;
  }

  .ac-offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .ac-m-md-auto {
    margin: auto !important;
  }

  .ac-mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .ac-my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .ac-m-md-0 {
    margin: 0 !important;
  }

  .ac-mt-md-0,
  .ac-my-md-0 {
    margin-top: 0 !important;
  }

  .ac-mr-md-0,
  .ac-mx-md-0 {
    margin-right: 0 !important;
  }

  .ac-mb-md-0,
  .ac-my-md-0 {
    margin-bottom: 0 !important;
  }

  .ac-ml-md-0,
  .ac-mx-md-0 {
    margin-left: 0 !important;
  }

  .ac-m-md-1 {
    margin: 8px !important;
  }

  .ac-mt-md-1,
  .ac-my-md-1 {
    margin-top: 8px !important;
  }

  .ac-mr-md-1,
  .ac-mx-md-1 {
    margin-right: 8px !important;
  }

  .ac-mb-md-1,
  .ac-my-md-1 {
    margin-bottom: 8px !important;
  }

  .ac-ml-md-1,
  .ac-mx-md-1 {
    margin-left: 8px !important;
  }

  .ac-m-md-2 {
    margin: 16px !important;
  }

  .ac-mt-md-2,
  .ac-my-md-2 {
    margin-top: 16px !important;
  }

  .ac-mr-md-2,
  .ac-mx-md-2 {
    margin-right: 16px !important;
  }

  .ac-mb-md-2,
  .ac-my-md-2 {
    margin-bottom: 16px !important;
  }

  .ac-ml-md-2,
  .ac-mx-md-2 {
    margin-left: 16px !important;
  }

  .ac-m-md-3 {
    margin: 24px !important;
  }

  .ac-mt-md-3,
  .ac-my-md-3 {
    margin-top: 24px !important;
  }

  .ac-mr-md-3,
  .ac-mx-md-3 {
    margin-right: 24px !important;
  }

  .ac-mb-md-3,
  .ac-my-md-3 {
    margin-bottom: 24px !important;
  }

  .ac-ml-md-3,
  .ac-mx-md-3 {
    margin-left: 24px !important;
  }

  .ac-m-md-4 {
    margin: 32px !important;
  }

  .ac-mt-md-4,
  .ac-my-md-4 {
    margin-top: 32px !important;
  }

  .ac-mr-md-4,
  .ac-mx-md-4 {
    margin-right: 32px !important;
  }

  .ac-mb-md-4,
  .ac-my-md-4 {
    margin-bottom: 32px !important;
  }

  .ac-ml-md-4,
  .ac-mx-md-4 {
    margin-left: 32px !important;
  }

  .ac-m-md-5 {
    margin: 48px !important;
  }

  .ac-mt-md-5,
  .ac-my-md-5 {
    margin-top: 48px !important;
  }

  .ac-mr-md-5,
  .ac-mx-md-5 {
    margin-right: 48px !important;
  }

  .ac-mb-md-5,
  .ac-my-md-5 {
    margin-bottom: 48px !important;
  }

  .ac-ml-md-5,
  .ac-mx-md-5 {
    margin-left: 48px !important;
  }

  .ac-p-md-0 {
    padding: 0 !important;
  }

  .ac-pt-md-0,
  .ac-py-md-0 {
    padding-top: 0 !important;
  }

  .ac-pr-md-0,
  .ac-px-md-0 {
    padding-right: 0 !important;
  }

  .ac-pb-md-0,
  .ac-py-md-0 {
    padding-bottom: 0 !important;
  }

  .ac-pl-md-0,
  .ac-px-md-0 {
    padding-left: 0 !important;
  }

  .ac-p-md-1 {
    padding: 8px !important;
  }

  .ac-pt-md-1,
  .ac-py-md-1 {
    padding-top: 8px !important;
  }

  .ac-pr-md-1,
  .ac-px-md-1 {
    padding-right: 8px !important;
  }

  .ac-pb-md-1,
  .ac-py-md-1 {
    padding-bottom: 8px !important;
  }

  .ac-pl-md-1,
  .ac-px-md-1 {
    padding-left: 8px !important;
  }

  .ac-p-md-2 {
    padding: 16px !important;
  }

  .ac-pt-md-2,
  .ac-py-md-2 {
    padding-top: 16px !important;
  }

  .ac-pr-md-2,
  .ac-px-md-2 {
    padding-right: 16px !important;
  }

  .ac-pb-md-2,
  .ac-py-md-2 {
    padding-bottom: 16px !important;
  }

  .ac-pl-md-2,
  .ac-px-md-2 {
    padding-left: 16px !important;
  }

  .ac-p-md-3 {
    padding: 24px !important;
  }

  .ac-pt-md-3,
  .ac-py-md-3 {
    padding-top: 24px !important;
  }

  .ac-pr-md-3,
  .ac-px-md-3 {
    padding-right: 24px !important;
  }

  .ac-pb-md-3,
  .ac-py-md-3 {
    padding-bottom: 24px !important;
  }

  .ac-pl-md-3,
  .ac-px-md-3 {
    padding-left: 24px !important;
  }

  .ac-p-md-4 {
    padding: 32px !important;
  }

  .ac-pt-md-4,
  .ac-py-md-4 {
    padding-top: 32px !important;
  }

  .ac-pr-md-4,
  .ac-px-md-4 {
    padding-right: 32px !important;
  }

  .ac-pb-md-4,
  .ac-py-md-4 {
    padding-bottom: 32px !important;
  }

  .ac-pl-md-4,
  .ac-px-md-4 {
    padding-left: 32px !important;
  }

  .ac-p-md-5 {
    padding: 48px !important;
  }

  .ac-pt-md-5,
  .ac-py-md-5 {
    padding-top: 48px !important;
  }

  .ac-pr-md-5,
  .ac-px-md-5 {
    padding-right: 48px !important;
  }

  .ac-pb-md-5,
  .ac-py-md-5 {
    padding-bottom: 48px !important;
  }

  .ac-pl-md-5,
  .ac-px-md-5 {
    padding-left: 48px !important;
  }

  .ac-m-md-n1 {
    margin: -8px !important;
  }

  .ac-mt-md-n1,
  .ac-my-md-n1 {
    margin-top: -8px !important;
  }

  .ac-mr-md-n1,
  .ac-mx-md-n1 {
    margin-right: -8px !important;
  }

  .ac-mb-md-n1,
  .ac-my-md-n1 {
    margin-bottom: -8px !important;
  }

  .ac-ml-md-n1,
  .ac-mx-md-n1 {
    margin-left: -8px !important;
  }

  .ac-m-md-n2 {
    margin: -16px !important;
  }

  .ac-mt-md-n2,
  .ac-my-md-n2 {
    margin-top: -16px !important;
  }

  .ac-mr-md-n2,
  .ac-mx-md-n2 {
    margin-right: -16px !important;
  }

  .ac-mb-md-n2,
  .ac-my-md-n2 {
    margin-bottom: -16px !important;
  }

  .ac-ml-md-n2,
  .ac-mx-md-n2 {
    margin-left: -16px !important;
  }

  .ac-m-md-n3 {
    margin: -24px !important;
  }

  .ac-mt-md-n3,
  .ac-my-md-n3 {
    margin-top: -24px !important;
  }

  .ac-mr-md-n3,
  .ac-mx-md-n3 {
    margin-right: -24px !important;
  }

  .ac-mb-md-n3,
  .ac-my-md-n3 {
    margin-bottom: -24px !important;
  }

  .ac-ml-md-n3,
  .ac-mx-md-n3 {
    margin-left: -24px !important;
  }

  .ac-m-md-n4 {
    margin: -32px !important;
  }

  .ac-mt-md-n4,
  .ac-my-md-n4 {
    margin-top: -32px !important;
  }

  .ac-mr-md-n4,
  .ac-mx-md-n4 {
    margin-right: -32px !important;
  }

  .ac-mb-md-n4,
  .ac-my-md-n4 {
    margin-bottom: -32px !important;
  }

  .ac-ml-md-n4,
  .ac-mx-md-n4 {
    margin-left: -32px !important;
  }

  .ac-m-md-n5 {
    margin: -48px !important;
  }

  .ac-mt-md-n5,
  .ac-my-md-n5 {
    margin-top: -48px !important;
  }

  .ac-mr-md-n5,
  .ac-mx-md-n5 {
    margin-right: -48px !important;
  }

  .ac-mb-md-n5,
  .ac-my-md-n5 {
    margin-bottom: -48px !important;
  }

  .ac-ml-md-n5,
  .ac-mx-md-n5 {
    margin-left: -48px !important;
  }

  .ac-m-md-auto {
    margin: auto !important;
  }

  .ac-mt-md-auto,
  .ac-my-md-auto {
    margin-top: auto !important;
  }

  .ac-mr-md-auto,
  .ac-mx-md-auto {
    margin-right: auto !important;
  }

  .ac-mb-md-auto,
  .ac-my-md-auto {
    margin-bottom: auto !important;
  }

  .ac-ml-md-auto,
  .ac-mx-md-auto {
    margin-left: auto !important;
  }

  .ac-d-md-none {
    display: none !important;
  }

  .ac-d-md-flex {
    display: flex !important;
  }

  .ac-d-md-inline-flex {
    display: inline-flex !important;
  }

  .ac-d-md-inline {
    display: inline !important;
  }

  .ac-d-md-inline-block {
    display: inline-block !important;
  }

  .ac-d-md-block {
    display: block !important;
  }

  .ac-d-md-table-cell {
    display: table-cell !important;
  }

  .ac-d-lg-table-row {
    display: table-row !important;
  }

  .ac-flex-md-row {
    flex-direction: row !important;
  }

  .ac-flex-md-column {
    flex-direction: column !important;
  }

  .ac-flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .ac-flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .ac-flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .ac-flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .ac-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .ac-flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .ac-flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .ac-flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .ac-align-items-md-baseline {
    align-items: baseline !important;
  }

  .ac-align-items-md-center {
    align-items: center !important;
  }

  .ac-align-items-md-start {
    align-items: flex-start !important;
  }

  .ac-align-items-md-end {
    align-items: flex-end !important;
  }

  .ac-align-items-md-stretch {
    align-items: stretch !important;
  }

  .ac-justify-content-md-center {
    justify-content: center !important;
  }

  .ac-justify-content-md-start {
    justify-content: flex-start !important;
  }

  .ac-justify-content-md-end {
    justify-content: flex-end !important;
  }

  .ac-justify-content-md-around {
    justify-content: space-around !important;
  }

  .ac-justify-content-md-between {
    justify-content: space-between !important;
  }

  .ac-text-md-center {
    text-align: center !important;
  }

  .ac-text-md-left {
    text-align: left !important;
  }

  .ac-text-md-right {
    text-align: right !important;
  }

  .ac-position-md-relative {
    position: relative !important;
  }

  .ac-position-md-absolute {
    position: absolute !important;
  }

  .ac-position-md-fixed {
    position: fixed !important;
  }

  .ac-col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .ac-col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .ac-col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ac-col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .ac-col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .ac-col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ac-col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .ac-col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .ac-col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ac-col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .ac-col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .ac-col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ac-offset-md-0 {
    margin-left: 0%;
  }

  .ac-offset-md-1 {
    margin-left: 8.333333%;
  }

  .ac-offset-md-2 {
    margin-left: 16.666667%;
  }

  .ac-offset-md-3 {
    margin-left: 25%;
  }

  .ac-offset-md-4 {
    margin-left: 33.333333%;
  }

  .ac-offset-md-5 {
    margin-left: 41.666667%;
  }

  .ac-offset-md-6 {
    margin-left: 50%;
  }

  .ac-offset-md-7 {
    margin-left: 58.333333%;
  }

  .ac-offset-md-8 {
    margin-left: 66.666667%;
  }

  .ac-offset-md-9 {
    margin-left: 75%;
  }

  .ac-offset-md-10 {
    margin-left: 83.333333%;
  }

  .ac-offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1024px) {
  .ac-m-lg-auto {
    margin: auto !important;
  }

  .ac-mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .ac-my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .ac-m-lg-0 {
    margin: 0 !important;
  }

  .ac-mt-lg-0,
  .ac-my-lg-0 {
    margin-top: 0 !important;
  }

  .ac-mr-lg-0,
  .ac-mx-lg-0 {
    margin-right: 0 !important;
  }

  .ac-mb-lg-0,
  .ac-my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ac-ml-lg-0,
  .ac-mx-lg-0 {
    margin-left: 0 !important;
  }

  .ac-m-lg-1 {
    margin: 8px !important;
  }

  .ac-mt-lg-1,
  .ac-my-lg-1 {
    margin-top: 8px !important;
  }

  .ac-mr-lg-1,
  .ac-mx-lg-1 {
    margin-right: 8px !important;
  }

  .ac-mb-lg-1,
  .ac-my-lg-1 {
    margin-bottom: 8px !important;
  }

  .ac-ml-lg-1,
  .ac-mx-lg-1 {
    margin-left: 8px !important;
  }

  .ac-m-lg-2 {
    margin: 16px !important;
  }

  .ac-mt-lg-2,
  .ac-my-lg-2 {
    margin-top: 16px !important;
  }

  .ac-mr-lg-2,
  .ac-mx-lg-2 {
    margin-right: 16px !important;
  }

  .ac-mb-lg-2,
  .ac-my-lg-2 {
    margin-bottom: 16px !important;
  }

  .ac-ml-lg-2,
  .ac-mx-lg-2 {
    margin-left: 16px !important;
  }

  .ac-m-lg-3 {
    margin: 24px !important;
  }

  .ac-mt-lg-3,
  .ac-my-lg-3 {
    margin-top: 24px !important;
  }

  .ac-mr-lg-3,
  .ac-mx-lg-3 {
    margin-right: 24px !important;
  }

  .ac-mb-lg-3,
  .ac-my-lg-3 {
    margin-bottom: 24px !important;
  }

  .ac-ml-lg-3,
  .ac-mx-lg-3 {
    margin-left: 24px !important;
  }

  .ac-m-lg-4 {
    margin: 32px !important;
  }

  .ac-mt-lg-4,
  .ac-my-lg-4 {
    margin-top: 32px !important;
  }

  .ac-mr-lg-4,
  .ac-mx-lg-4 {
    margin-right: 32px !important;
  }

  .ac-mb-lg-4,
  .ac-my-lg-4 {
    margin-bottom: 32px !important;
  }

  .ac-ml-lg-4,
  .ac-mx-lg-4 {
    margin-left: 32px !important;
  }

  .ac-m-lg-5 {
    margin: 48px !important;
  }

  .ac-mt-lg-5,
  .ac-my-lg-5 {
    margin-top: 48px !important;
  }

  .ac-mr-lg-5,
  .ac-mx-lg-5 {
    margin-right: 48px !important;
  }

  .ac-mb-lg-5,
  .ac-my-lg-5 {
    margin-bottom: 48px !important;
  }

  .ac-ml-lg-5,
  .ac-mx-lg-5 {
    margin-left: 48px !important;
  }

  .ac-p-lg-0 {
    padding: 0 !important;
  }

  .ac-pt-lg-0,
  .ac-py-lg-0 {
    padding-top: 0 !important;
  }

  .ac-pr-lg-0,
  .ac-px-lg-0 {
    padding-right: 0 !important;
  }

  .ac-pb-lg-0,
  .ac-py-lg-0 {
    padding-bottom: 0 !important;
  }

  .ac-pl-lg-0,
  .ac-px-lg-0 {
    padding-left: 0 !important;
  }

  .ac-p-lg-1 {
    padding: 8px !important;
  }

  .ac-pt-lg-1,
  .ac-py-lg-1 {
    padding-top: 8px !important;
  }

  .ac-pr-lg-1,
  .ac-px-lg-1 {
    padding-right: 8px !important;
  }

  .ac-pb-lg-1,
  .ac-py-lg-1 {
    padding-bottom: 8px !important;
  }

  .ac-pl-lg-1,
  .ac-px-lg-1 {
    padding-left: 8px !important;
  }

  .ac-p-lg-2 {
    padding: 16px !important;
  }

  .ac-pt-lg-2,
  .ac-py-lg-2 {
    padding-top: 16px !important;
  }

  .ac-pr-lg-2,
  .ac-px-lg-2 {
    padding-right: 16px !important;
  }

  .ac-pb-lg-2,
  .ac-py-lg-2 {
    padding-bottom: 16px !important;
  }

  .ac-pl-lg-2,
  .ac-px-lg-2 {
    padding-left: 16px !important;
  }

  .ac-p-lg-3 {
    padding: 24px !important;
  }

  .ac-pt-lg-3,
  .ac-py-lg-3 {
    padding-top: 24px !important;
  }

  .ac-pr-lg-3,
  .ac-px-lg-3 {
    padding-right: 24px !important;
  }

  .ac-pb-lg-3,
  .ac-py-lg-3 {
    padding-bottom: 24px !important;
  }

  .ac-pl-lg-3,
  .ac-px-lg-3 {
    padding-left: 24px !important;
  }

  .ac-p-lg-4 {
    padding: 32px !important;
  }

  .ac-pt-lg-4,
  .ac-py-lg-4 {
    padding-top: 32px !important;
  }

  .ac-pr-lg-4,
  .ac-px-lg-4 {
    padding-right: 32px !important;
  }

  .ac-pb-lg-4,
  .ac-py-lg-4 {
    padding-bottom: 32px !important;
  }

  .ac-pl-lg-4,
  .ac-px-lg-4 {
    padding-left: 32px !important;
  }

  .ac-p-lg-5 {
    padding: 48px !important;
  }

  .ac-pt-lg-5,
  .ac-py-lg-5 {
    padding-top: 48px !important;
  }

  .ac-pr-lg-5,
  .ac-px-lg-5 {
    padding-right: 48px !important;
  }

  .ac-pb-lg-5,
  .ac-py-lg-5 {
    padding-bottom: 48px !important;
  }

  .ac-pl-lg-5,
  .ac-px-lg-5 {
    padding-left: 48px !important;
  }

  .ac-m-lg-n1 {
    margin: -8px !important;
  }

  .ac-mt-lg-n1,
  .ac-my-lg-n1 {
    margin-top: -8px !important;
  }

  .ac-mr-lg-n1,
  .ac-mx-lg-n1 {
    margin-right: -8px !important;
  }

  .ac-mb-lg-n1,
  .ac-my-lg-n1 {
    margin-bottom: -8px !important;
  }

  .ac-ml-lg-n1,
  .ac-mx-lg-n1 {
    margin-left: -8px !important;
  }

  .ac-m-lg-n2 {
    margin: -16px !important;
  }

  .ac-mt-lg-n2,
  .ac-my-lg-n2 {
    margin-top: -16px !important;
  }

  .ac-mr-lg-n2,
  .ac-mx-lg-n2 {
    margin-right: -16px !important;
  }

  .ac-mb-lg-n2,
  .ac-my-lg-n2 {
    margin-bottom: -16px !important;
  }

  .ac-ml-lg-n2,
  .ac-mx-lg-n2 {
    margin-left: -16px !important;
  }

  .ac-m-lg-n3 {
    margin: -24px !important;
  }

  .ac-mt-lg-n3,
  .ac-my-lg-n3 {
    margin-top: -24px !important;
  }

  .ac-mr-lg-n3,
  .ac-mx-lg-n3 {
    margin-right: -24px !important;
  }

  .ac-mb-lg-n3,
  .ac-my-lg-n3 {
    margin-bottom: -24px !important;
  }

  .ac-ml-lg-n3,
  .ac-mx-lg-n3 {
    margin-left: -24px !important;
  }

  .ac-m-lg-n4 {
    margin: -32px !important;
  }

  .ac-mt-lg-n4,
  .ac-my-lg-n4 {
    margin-top: -32px !important;
  }

  .ac-mr-lg-n4,
  .ac-mx-lg-n4 {
    margin-right: -32px !important;
  }

  .ac-mb-lg-n4,
  .ac-my-lg-n4 {
    margin-bottom: -32px !important;
  }

  .ac-ml-lg-n4,
  .ac-mx-lg-n4 {
    margin-left: -32px !important;
  }

  .ac-m-lg-n5 {
    margin: -48px !important;
  }

  .ac-mt-lg-n5,
  .ac-my-lg-n5 {
    margin-top: -48px !important;
  }

  .ac-mr-lg-n5,
  .ac-mx-lg-n5 {
    margin-right: -48px !important;
  }

  .ac-mb-lg-n5,
  .ac-my-lg-n5 {
    margin-bottom: -48px !important;
  }

  .ac-ml-lg-n5,
  .ac-mx-lg-n5 {
    margin-left: -48px !important;
  }

  .ac-m-lg-auto {
    margin: auto !important;
  }

  .ac-mt-lg-auto,
  .ac-my-lg-auto {
    margin-top: auto !important;
  }

  .ac-mr-lg-auto,
  .ac-mx-lg-auto {
    margin-right: auto !important;
  }

  .ac-mb-lg-auto,
  .ac-my-lg-auto {
    margin-bottom: auto !important;
  }

  .ac-ml-lg-auto,
  .ac-mx-lg-auto {
    margin-left: auto !important;
  }

  .ac-d-lg-none {
    display: none !important;
  }

  .ac-d-lg-flex {
    display: flex !important;
  }

  .ac-d-lg-inline-flex {
    display: inline-flex !important;
  }

  .ac-d-lg-inline {
    display: inline !important;
  }

  .ac-d-lg-inline-block {
    display: inline-block !important;
  }

  .ac-d-lg-block {
    display: block !important;
  }

  .ac-d-lg-table-cell {
    display: table-cell !important;
  }

  .ac-d-lg-table-row {
    display: table-row !important;
  }

  .ac-flex-lg-row {
    flex-direction: row !important;
  }

  .ac-flex-lg-column {
    flex-direction: column !important;
  }

  .ac-flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .ac-flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .ac-flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .ac-flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .ac-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .ac-flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .ac-flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .ac-flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .ac-align-items-lg-baseline {
    align-items: baseline !important;
  }

  .ac-align-items-lg-center {
    align-items: center !important;
  }

  .ac-align-items-lg-start {
    align-items: flex-start !important;
  }

  .ac-align-items-lg-end {
    align-items: flex-end !important;
  }

  .ac-align-items-lg-stretch {
    align-items: stretch !important;
  }

  .ac-justify-content-lg-center {
    justify-content: center !important;
  }

  .ac-justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .ac-justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .ac-justify-content-lg-around {
    justify-content: space-around !important;
  }

  .ac-justify-content-lg-between {
    justify-content: space-between !important;
  }

  .ac-text-lg-center {
    text-align: center !important;
  }

  .ac-text-lg-left {
    text-align: left !important;
  }

  .ac-text-lg-right {
    text-align: right !important;
  }

  .ac-position-lg-relative {
    position: relative !important;
  }

  .ac-position-lg-absolute {
    position: absolute !important;
  }

  .ac-position-lg-fixed {
    position: fixed !important;
  }

  .ac-col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .ac-col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .ac-col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ac-col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .ac-col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .ac-col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ac-col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .ac-col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .ac-col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    // max-width: 75%;
  }

  .ac-col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .ac-col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .ac-col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ac-offset-lg-0 {
    margin-left: 0%;
  }

  .ac-offset-lg-1 {
    margin-left: 8.333333%;
  }

  .ac-offset-lg-2 {
    margin-left: 16.666667%;
  }

  .ac-offset-lg-3 {
    margin-left: 25%;
  }

  .ac-offset-lg-4 {
    margin-left: 33.333333%;
  }

  .ac-offset-lg-5 {
    margin-left: 41.666667%;
  }

  .ac-offset-lg-6 {
    margin-left: 50%;
  }

  .ac-offset-lg-7 {
    margin-left: 58.333333%;
  }

  .ac-offset-lg-8 {
    margin-left: 66.666667%;
  }

  .ac-offset-lg-9 {
    margin-left: 75%;
  }

  .ac-offset-lg-10 {
    margin-left: 83.333333%;
  }

  .ac-offset-lg-11 {
    margin-left: 91.666667%;
  }

  .content-wrapper .container > div {
    justify-content: space-between;
  }

  #ac-navigation-left {
    flex: 0 0 auto;
    max-width: 316px;
  }

  #ac-main-content {
    flex: 0 0 auto;
    max-width: calc(100% - 324px);
  }
}

@media (min-width: 1280px) {
  .ac-m-xl-auto {
    margin: auto !important;
  }

  .ac-mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .ac-my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .ac-m-xl-0 {
    margin: 0 !important;
  }

  .ac-mt-xl-0,
  .ac-my-xl-0 {
    margin-top: 0 !important;
  }

  .ac-mr-xl-0,
  .ac-mx-xl-0 {
    margin-right: 0 !important;
  }

  .ac-mb-xl-0,
  .ac-my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ac-ml-xl-0,
  .ac-mx-xl-0 {
    margin-left: 0 !important;
  }

  .ac-m-xl-1 {
    margin: 8px !important;
  }

  .ac-mt-xl-1,
  .ac-my-xl-1 {
    margin-top: 8px !important;
  }

  .ac-mr-xl-1,
  .ac-mx-xl-1 {
    margin-right: 8px !important;
  }

  .ac-mb-xl-1,
  .ac-my-xl-1 {
    margin-bottom: 8px !important;
  }

  .ac-ml-xl-1,
  .ac-mx-xl-1 {
    margin-left: 8px !important;
  }

  .ac-m-xl-2 {
    margin: 16px !important;
  }

  .ac-mt-xl-2,
  .ac-my-xl-2 {
    margin-top: 16px !important;
  }

  .ac-mr-xl-2,
  .ac-mx-xl-2 {
    margin-right: 16px !important;
  }

  .ac-mb-xl-2,
  .ac-my-xl-2 {
    margin-bottom: 16px !important;
  }

  .ac-ml-xl-2,
  .ac-mx-xl-2 {
    margin-left: 16px !important;
  }

  .ac-m-xl-3 {
    margin: 24px !important;
  }

  .ac-mt-xl-3,
  .ac-my-xl-3 {
    margin-top: 24px !important;
  }

  .ac-mr-xl-3,
  .ac-mx-xl-3 {
    margin-right: 24px !important;
  }

  .ac-mb-xl-3,
  .ac-my-xl-3 {
    margin-bottom: 24px !important;
  }

  .ac-ml-xl-3,
  .ac-mx-xl-3 {
    margin-left: 24px !important;
  }

  .ac-m-xl-4 {
    margin: 32px !important;
  }

  .ac-mt-xl-4,
  .ac-my-xl-4 {
    margin-top: 32px !important;
  }

  .ac-mr-xl-4,
  .ac-mx-xl-4 {
    margin-right: 32px !important;
  }

  .ac-mb-xl-4,
  .ac-my-xl-4 {
    margin-bottom: 32px !important;
  }

  .ac-ml-xl-4,
  .ac-mx-xl-4 {
    margin-left: 32px !important;
  }

  .ac-m-xl-5 {
    margin: 48px !important;
  }

  .ac-mt-xl-5,
  .ac-my-xl-5 {
    margin-top: 48px !important;
  }

  .ac-mr-xl-5,
  .ac-mx-xl-5 {
    margin-right: 48px !important;
  }

  .ac-mb-xl-5,
  .ac-my-xl-5 {
    margin-bottom: 48px !important;
  }

  .ac-ml-xl-5,
  .ac-mx-xl-5 {
    margin-left: 48px !important;
  }

  .ac-p-xl-0 {
    padding: 0 !important;
  }

  .ac-pt-xl-0,
  .ac-py-xl-0 {
    padding-top: 0 !important;
  }

  .ac-pr-xl-0,
  .ac-px-xl-0 {
    padding-right: 0 !important;
  }

  .ac-pb-xl-0,
  .ac-py-xl-0 {
    padding-bottom: 0 !important;
  }

  .ac-pl-xl-0,
  .ac-px-xl-0 {
    padding-left: 0 !important;
  }

  .ac-p-xl-1 {
    padding: 8px !important;
  }

  .ac-pt-xl-1,
  .ac-py-xl-1 {
    padding-top: 8px !important;
  }

  .ac-pr-xl-1,
  .ac-px-xl-1 {
    padding-right: 8px !important;
  }

  .ac-pb-xl-1,
  .ac-py-xl-1 {
    padding-bottom: 8px !important;
  }

  .ac-pl-xl-1,
  .ac-px-xl-1 {
    padding-left: 8px !important;
  }

  .ac-p-xl-2 {
    padding: 16px !important;
  }

  .ac-pt-xl-2,
  .ac-py-xl-2 {
    padding-top: 16px !important;
  }

  .ac-pr-xl-2,
  .ac-px-xl-2 {
    padding-right: 16px !important;
  }

  .ac-pb-xl-2,
  .ac-py-xl-2 {
    padding-bottom: 16px !important;
  }

  .ac-pl-xl-2,
  .ac-px-xl-2 {
    padding-left: 16px !important;
  }

  .ac-p-xl-3 {
    padding: 24px !important;
  }

  .ac-pt-xl-3,
  .ac-py-xl-3 {
    padding-top: 24px !important;
  }

  .ac-pr-xl-3,
  .ac-px-xl-3 {
    padding-right: 24px !important;
  }

  .ac-pb-xl-3,
  .ac-py-xl-3 {
    padding-bottom: 24px !important;
  }

  .ac-pl-xl-3,
  .ac-px-xl-3 {
    padding-left: 24px !important;
  }

  .ac-p-xl-4 {
    padding: 32px !important;
  }

  .ac-pt-xl-4,
  .ac-py-xl-4 {
    padding-top: 32px !important;
  }

  .ac-pr-xl-4,
  .ac-px-xl-4 {
    padding-right: 32px !important;
  }

  .ac-pb-xl-4,
  .ac-py-xl-4 {
    padding-bottom: 32px !important;
  }

  .ac-pl-xl-4,
  .ac-px-xl-4 {
    padding-left: 32px !important;
  }

  .ac-p-xl-5 {
    padding: 48px !important;
  }

  .ac-pt-xl-5,
  .ac-py-xl-5 {
    padding-top: 48px !important;
  }

  .ac-pr-xl-5,
  .ac-px-xl-5 {
    padding-right: 48px !important;
  }

  .ac-pb-xl-5,
  .ac-py-xl-5 {
    padding-bottom: 48px !important;
  }

  .ac-pl-xl-5,
  .ac-px-xl-5 {
    padding-left: 48px !important;
  }

  .ac-m-xl-n1 {
    margin: -8px !important;
  }

  .ac-mt-xl-n1,
  .ac-my-xl-n1 {
    margin-top: -8px !important;
  }

  .ac-mr-xl-n1,
  .ac-mx-xl-n1 {
    margin-right: -8px !important;
  }

  .ac-mb-xl-n1,
  .ac-my-xl-n1 {
    margin-bottom: -8px !important;
  }

  .ac-ml-xl-n1,
  .ac-mx-xl-n1 {
    margin-left: -8px !important;
  }

  .ac-m-xl-n2 {
    margin: -16px !important;
  }

  .ac-mt-xl-n2,
  .ac-my-xl-n2 {
    margin-top: -16px !important;
  }

  .ac-mr-xl-n2,
  .ac-mx-xl-n2 {
    margin-right: -16px !important;
  }

  .ac-mb-xl-n2,
  .ac-my-xl-n2 {
    margin-bottom: -16px !important;
  }

  .ac-ml-xl-n2,
  .ac-mx-xl-n2 {
    margin-left: -16px !important;
  }

  .ac-m-xl-n3 {
    margin: -24px !important;
  }

  .ac-mt-xl-n3,
  .ac-my-xl-n3 {
    margin-top: -24px !important;
  }

  .ac-mr-xl-n3,
  .ac-mx-xl-n3 {
    margin-right: -24px !important;
  }

  .ac-mb-xl-n3,
  .ac-my-xl-n3 {
    margin-bottom: -24px !important;
  }

  .ac-ml-xl-n3,
  .ac-mx-xl-n3 {
    margin-left: -24px !important;
  }

  .ac-m-xl-n4 {
    margin: -32px !important;
  }

  .ac-mt-xl-n4,
  .ac-my-xl-n4 {
    margin-top: -32px !important;
  }

  .ac-mr-xl-n4,
  .ac-mx-xl-n4 {
    margin-right: -32px !important;
  }

  .ac-mb-xl-n4,
  .ac-my-xl-n4 {
    margin-bottom: -32px !important;
  }

  .ac-ml-xl-n4,
  .ac-mx-xl-n4 {
    margin-left: -32px !important;
  }

  .ac-m-xl-n5 {
    margin: -48px !important;
  }

  .ac-mt-xl-n5,
  .ac-my-xl-n5 {
    margin-top: -48px !important;
  }

  .ac-mr-xl-n5,
  .ac-mx-xl-n5 {
    margin-right: -48px !important;
  }

  .ac-mb-xl-n5,
  .ac-my-xl-n5 {
    margin-bottom: -48px !important;
  }

  .ac-ml-xl-n5,
  .ac-mx-xl-n5 {
    margin-left: -48px !important;
  }

  .ac-m-xl-auto {
    margin: auto !important;
  }

  .ac-mt-xl-auto,
  .ac-my-xl-auto {
    margin-top: auto !important;
  }

  .ac-mr-xl-auto,
  .ac-mx-xl-auto {
    margin-right: auto !important;
  }

  .ac-mb-xl-auto,
  .ac-my-xl-auto {
    margin-bottom: auto !important;
  }

  .ac-ml-xl-auto,
  .ac-mx-xl-auto {
    margin-left: auto !important;
  }

  .ac-d-xl-none {
    display: none !important;
  }

  .ac-d-xl-flex {
    display: flex !important;
  }

  .ac-d-xl-inline-flex {
    display: inline-flex !important;
  }

  .ac-d-xl-inline {
    display: inline !important;
  }

  .ac-d-xl-inline-block {
    display: inline-block !important;
  }

  .ac-d-xl-block {
    display: block !important;
  }

  .ac-d-xl-table-cell {
    display: table-cell !important;
  }

  .ac-d-xl-table-row {
    display: table-row !important;
  }

  .ac-flex-xl-row {
    flex-direction: row !important;
  }

  .ac-flex-xl-column {
    flex-direction: column !important;
  }

  .ac-flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .ac-flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .ac-flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .ac-flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .ac-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .ac-flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .ac-flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .ac-flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .ac-align-items-xl-baseline {
    align-items: baseline !important;
  }

  .ac-align-items-xl-center {
    align-items: center !important;
  }

  .ac-align-items-xl-start {
    align-items: flex-start !important;
  }

  .ac-align-items-xl-end {
    align-items: flex-end !important;
  }

  .ac-align-items-xl-stretch {
    align-items: stretch !important;
  }

  .ac-justify-content-xl-center {
    justify-content: center !important;
  }

  .ac-justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .ac-justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .ac-justify-content-xl-around {
    justify-content: space-around !important;
  }

  .ac-justify-content-xl-between {
    justify-content: space-between !important;
  }

  .ac-text-xl-center {
    text-align: center !important;
  }

  .ac-text-xl-left {
    text-align: left !important;
  }

  .ac-text-xl-right {
    text-align: right !important;
  }

  .ac-position-xl-relative {
    position: relative !important;
  }

  .ac-position-xl-absolute {
    position: absolute !important;
  }

  .ac-position-xl-fixed {
    position: fixed !important;
  }

  .ac-col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .ac-col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .ac-col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ac-col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .ac-col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .ac-col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ac-col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .ac-col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .ac-col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ac-col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .ac-col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .ac-col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ac-offset-xl-0 {
    margin-left: 0%;
  }

  .ac-offset-xl-1 {
    margin-left: 8.333333%;
  }

  .ac-offset-xl-2 {
    margin-left: 16.666667%;
  }

  .ac-offset-xl-3 {
    margin-left: 25%;
  }

  .ac-offset-xl-4 {
    margin-left: 33.333333%;
  }

  .ac-offset-xl-5 {
    margin-left: 41.666667%;
  }

  .ac-offset-xl-6 {
    margin-left: 50%;
  }

  .ac-offset-xl-7 {
    margin-left: 58.333333%;
  }

  .ac-offset-xl-8 {
    margin-left: 66.666667%;
  }

  .ac-offset-xl-9 {
    margin-left: 75%;
  }

  .ac-offset-xl-10 {
    margin-left: 83.333333%;
  }

  .ac-offset-xl-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1440px) {
  .ac-m-xxl-auto {
    margin: auto !important;
  }

  .ac-mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .ac-my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .ac-m-xxl-0 {
    margin: 0 !important;
  }

  .ac-mt-xxl-0,
  .ac-my-xxl-0 {
    margin-top: 0 !important;
  }

  .ac-mr-xxl-0,
  .ac-mx-xxl-0 {
    margin-right: 0 !important;
  }

  .ac-mb-xxl-0,
  .ac-my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ac-ml-xxl-0,
  .ac-mx-xxl-0 {
    margin-left: 0 !important;
  }

  .ac-m-xxl-1 {
    margin: 8px !important;
  }

  .ac-mt-xxl-1,
  .ac-my-xxl-1 {
    margin-top: 8px !important;
  }

  .ac-mr-xxl-1,
  .ac-mx-xxl-1 {
    margin-right: 8px !important;
  }

  .ac-mb-xxl-1,
  .ac-my-xxl-1 {
    margin-bottom: 8px !important;
  }

  .ac-ml-xxl-1,
  .ac-mx-xxl-1 {
    margin-left: 8px !important;
  }

  .ac-m-xxl-2 {
    margin: 16px !important;
  }

  .ac-mt-xxl-2,
  .ac-my-xxl-2 {
    margin-top: 16px !important;
  }

  .ac-mr-xxl-2,
  .ac-mx-xxl-2 {
    margin-right: 16px !important;
  }

  .ac-mb-xxl-2,
  .ac-my-xxl-2 {
    margin-bottom: 16px !important;
  }

  .ac-ml-xxl-2,
  .ac-mx-xxl-2 {
    margin-left: 16px !important;
  }

  .ac-m-xxl-3 {
    margin: 24px !important;
  }

  .ac-mt-xxl-3,
  .ac-my-xxl-3 {
    margin-top: 24px !important;
  }

  .ac-mr-xxl-3,
  .ac-mx-xxl-3 {
    margin-right: 24px !important;
  }

  .ac-mb-xxl-3,
  .ac-my-xxl-3 {
    margin-bottom: 24px !important;
  }

  .ac-ml-xxl-3,
  .ac-mx-xxl-3 {
    margin-left: 24px !important;
  }

  .ac-m-xxl-4 {
    margin: 32px !important;
  }

  .ac-mt-xxl-4,
  .ac-my-xxl-4 {
    margin-top: 32px !important;
  }

  .ac-mr-xxl-4,
  .ac-mx-xxl-4 {
    margin-right: 32px !important;
  }

  .ac-mb-xxl-4,
  .ac-my-xxl-4 {
    margin-bottom: 32px !important;
  }

  .ac-ml-xxl-4,
  .ac-mx-xxl-4 {
    margin-left: 32px !important;
  }

  .ac-m-xxl-5 {
    margin: 48px !important;
  }

  .ac-mt-xxl-5,
  .ac-my-xxl-5 {
    margin-top: 48px !important;
  }

  .ac-mr-xxl-5,
  .ac-mx-xxl-5 {
    margin-right: 48px !important;
  }

  .ac-mb-xxl-5,
  .ac-my-xxl-5 {
    margin-bottom: 48px !important;
  }

  .ac-ml-xxl-5,
  .ac-mx-xxl-5 {
    margin-left: 48px !important;
  }

  .ac-p-xxl-0 {
    padding: 0 !important;
  }

  .ac-pt-xxl-0,
  .ac-py-xxl-0 {
    padding-top: 0 !important;
  }

  .ac-pr-xxl-0,
  .ac-px-xxl-0 {
    padding-right: 0 !important;
  }

  .ac-pb-xxl-0,
  .ac-py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .ac-pl-xxl-0,
  .ac-px-xxl-0 {
    padding-left: 0 !important;
  }

  .ac-p-xxl-1 {
    padding: 8px !important;
  }

  .ac-pt-xxl-1,
  .ac-py-xxl-1 {
    padding-top: 8px !important;
  }

  .ac-pr-xxl-1,
  .ac-px-xxl-1 {
    padding-right: 8px !important;
  }

  .ac-pb-xxl-1,
  .ac-py-xxl-1 {
    padding-bottom: 8px !important;
  }

  .ac-pl-xxl-1,
  .ac-px-xxl-1 {
    padding-left: 8px !important;
  }

  .ac-p-xxl-2 {
    padding: 16px !important;
  }

  .ac-pt-xxl-2,
  .ac-py-xxl-2 {
    padding-top: 16px !important;
  }

  .ac-pr-xxl-2,
  .ac-px-xxl-2 {
    padding-right: 16px !important;
  }

  .ac-pb-xxl-2,
  .ac-py-xxl-2 {
    padding-bottom: 16px !important;
  }

  .ac-pl-xxl-2,
  .ac-px-xxl-2 {
    padding-left: 16px !important;
  }

  .ac-p-xxl-3 {
    padding: 24px !important;
  }

  .ac-pt-xxl-3,
  .ac-py-xxl-3 {
    padding-top: 24px !important;
  }

  .ac-pr-xxl-3,
  .ac-px-xxl-3 {
    padding-right: 24px !important;
  }

  .ac-pb-xxl-3,
  .ac-py-xxl-3 {
    padding-bottom: 24px !important;
  }

  .ac-pl-xxl-3,
  .ac-px-xxl-3 {
    padding-left: 24px !important;
  }

  .ac-p-xxl-4 {
    padding: 32px !important;
  }

  .ac-pt-xxl-4,
  .ac-py-xxl-4 {
    padding-top: 32px !important;
  }

  .ac-pr-xxl-4,
  .ac-px-xxl-4 {
    padding-right: 32px !important;
  }

  .ac-pb-xxl-4,
  .ac-py-xxl-4 {
    padding-bottom: 32px !important;
  }

  .ac-pl-xxl-4,
  .ac-px-xxl-4 {
    padding-left: 32px !important;
  }

  .ac-p-xxl-5 {
    padding: 48px !important;
  }

  .ac-pt-xxl-5,
  .ac-py-xxl-5 {
    padding-top: 48px !important;
  }

  .ac-pr-xxl-5,
  .ac-px-xxl-5 {
    padding-right: 48px !important;
  }

  .ac-pb-xxl-5,
  .ac-py-xxl-5 {
    padding-bottom: 48px !important;
  }

  .ac-pl-xxl-5,
  .ac-px-xxl-5 {
    padding-left: 48px !important;
  }

  .ac-m-xxl-n1 {
    margin: -8px !important;
  }

  .ac-mt-xxl-n1,
  .ac-my-xxl-n1 {
    margin-top: -8px !important;
  }

  .ac-mr-xxl-n1,
  .ac-mx-xxl-n1 {
    margin-right: -8px !important;
  }

  .ac-mb-xxl-n1,
  .ac-my-xxl-n1 {
    margin-bottom: -8px !important;
  }

  .ac-ml-xxl-n1,
  .ac-mx-xxl-n1 {
    margin-left: -8px !important;
  }

  .ac-m-xxl-n2 {
    margin: -16px !important;
  }

  .ac-mt-xxl-n2,
  .ac-my-xxl-n2 {
    margin-top: -16px !important;
  }

  .ac-mr-xxl-n2,
  .ac-mx-xxl-n2 {
    margin-right: -16px !important;
  }

  .ac-mb-xxl-n2,
  .ac-my-xxl-n2 {
    margin-bottom: -16px !important;
  }

  .ac-ml-xxl-n2,
  .ac-mx-xxl-n2 {
    margin-left: -16px !important;
  }

  .ac-m-xxl-n3 {
    margin: -24px !important;
  }

  .ac-mt-xxl-n3,
  .ac-my-xxl-n3 {
    margin-top: -24px !important;
  }

  .ac-mr-xxl-n3,
  .ac-mx-xxl-n3 {
    margin-right: -24px !important;
  }

  .ac-mb-xxl-n3,
  .ac-my-xxl-n3 {
    margin-bottom: -24px !important;
  }

  .ac-ml-xxl-n3,
  .ac-mx-xxl-n3 {
    margin-left: -24px !important;
  }

  .ac-m-xxl-n4 {
    margin: -32px !important;
  }

  .ac-mt-xxl-n4,
  .ac-my-xxl-n4 {
    margin-top: -32px !important;
  }

  .ac-mr-xxl-n4,
  .ac-mx-xxl-n4 {
    margin-right: -32px !important;
  }

  .ac-mb-xxl-n4,
  .ac-my-xxl-n4 {
    margin-bottom: -32px !important;
  }

  .ac-ml-xxl-n4,
  .ac-mx-xxl-n4 {
    margin-left: -32px !important;
  }

  .ac-m-xxl-n5 {
    margin: -48px !important;
  }

  .ac-mt-xxl-n5,
  .ac-my-xxl-n5 {
    margin-top: -48px !important;
  }

  .ac-mr-xxl-n5,
  .ac-mx-xxl-n5 {
    margin-right: -48px !important;
  }

  .ac-mb-xxl-n5,
  .ac-my-xxl-n5 {
    margin-bottom: -48px !important;
  }

  .ac-ml-xxl-n5,
  .ac-mx-xxl-n5 {
    margin-left: -48px !important;
  }

  .ac-m-xxl-auto {
    margin: auto !important;
  }

  .ac-mt-xxl-auto,
  .ac-my-xxl-auto {
    margin-top: auto !important;
  }

  .ac-mr-xxl-auto,
  .ac-mx-xxl-auto {
    margin-right: auto !important;
  }

  .ac-mb-xxl-auto,
  .ac-my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ac-ml-xxl-auto,
  .ac-mx-xxl-auto {
    margin-left: auto !important;
  }

  .ac-d-xxl-none {
    display: none !important;
  }

  .ac-d-xxl-flex {
    display: flex !important;
  }

  .ac-d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .ac-d-xxl-inline {
    display: inline !important;
  }

  .ac-d-xxl-inline-block {
    display: inline-block !important;
  }

  .ac-d-xxl-block {
    display: block !important;
  }

  .ac-d-xxl-table-cell {
    display: table-cell !important;
  }

  .ac-d-xxl-table-row {
    display: table-row !important;
  }

  .ac-flex-xxl-row {
    flex-direction: row !important;
  }

  .ac-flex-xxl-column {
    flex-direction: column !important;
  }

  .ac-flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .ac-flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .ac-flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .ac-flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .ac-flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .ac-flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .ac-flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .ac-flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .ac-align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .ac-align-items-xxl-center {
    align-items: center !important;
  }

  .ac-align-items-xxl-start {
    align-items: flex-start !important;
  }

  .ac-align-items-xxl-end {
    align-items: flex-end !important;
  }

  .ac-align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .ac-justify-content-xxl-center {
    justify-content: center !important;
  }

  .ac-justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .ac-justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .ac-justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .ac-justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .ac-text-xxl-center {
    text-align: center !important;
  }

  .ac-text-xxl-left {
    text-align: left !important;
  }

  .ac-text-xxl-right {
    text-align: right !important;
  }

  .ac-position-xxl-relative {
    position: relative !important;
  }

  .ac-position-xxl-absolute {
    position: absolute !important;
  }

  .ac-position-xxl-fixed {
    position: fixed !important;
  }

  .ac-col-xxl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .ac-col-xxl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .ac-col-xxl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ac-col-xxl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .ac-col-xxl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .ac-col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ac-col-xxl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .ac-col-xxl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .ac-col-xxl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ac-col-xxl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .ac-col-xxl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .ac-col-xxl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ac-offset-xxl-0 {
    margin-left: 0%;
  }

  .ac-offset-xxl-1 {
    margin-left: 8.333333%;
  }

  .ac-offset-xxl-2 {
    margin-left: 16.666667%;
  }

  .ac-offset-xxl-3 {
    margin-left: 25%;
  }

  .ac-offset-xxl-4 {
    margin-left: 33.333333%;
  }

  .ac-offset-xxl-5 {
    margin-left: 41.666667%;
  }

  .ac-offset-xxl-6 {
    margin-left: 50%;
  }

  .ac-offset-xxl-7 {
    margin-left: 58.333333%;
  }

  .ac-offset-xxl-8 {
    margin-left: 66.666667%;
  }

  .ac-offset-xxl-9 {
    margin-left: 75%;
  }

  .ac-offset-xxl-10 {
    margin-left: 83.333333%;
  }

  .ac-offset-xxl-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1920px) {
  .ac-m-sl-auto {
    margin: auto !important;
  }

  .ac-mx-sl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .ac-my-sl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .ac-m-sl-0 {
    margin: 0 !important;
  }

  .ac-mt-sl-0,
  .ac-my-sl-0 {
    margin-top: 0 !important;
  }

  .ac-mr-sl-0,
  .ac-mx-sl-0 {
    margin-right: 0 !important;
  }

  .ac-mb-sl-0,
  .ac-my-sl-0 {
    margin-bottom: 0 !important;
  }

  .ac-ml-sl-0,
  .ac-mx-sl-0 {
    margin-left: 0 !important;
  }

  .ac-m-sl-1 {
    margin: 8px !important;
  }

  .ac-mt-sl-1,
  .ac-my-sl-1 {
    margin-top: 8px !important;
  }

  .ac-mr-sl-1,
  .ac-mx-sl-1 {
    margin-right: 8px !important;
  }

  .ac-mb-sl-1,
  .ac-my-sl-1 {
    margin-bottom: 8px !important;
  }

  .ac-ml-sl-1,
  .ac-mx-sl-1 {
    margin-left: 8px !important;
  }

  .ac-m-sl-2 {
    margin: 16px !important;
  }

  .ac-mt-sl-2,
  .ac-my-sl-2 {
    margin-top: 16px !important;
  }

  .ac-mr-sl-2,
  .ac-mx-sl-2 {
    margin-right: 16px !important;
  }

  .ac-mb-sl-2,
  .ac-my-sl-2 {
    margin-bottom: 16px !important;
  }

  .ac-ml-sl-2,
  .ac-mx-sl-2 {
    margin-left: 16px !important;
  }

  .ac-m-sl-3 {
    margin: 24px !important;
  }

  .ac-mt-sl-3,
  .ac-my-sl-3 {
    margin-top: 24px !important;
  }

  .ac-mr-sl-3,
  .ac-mx-sl-3 {
    margin-right: 24px !important;
  }

  .ac-mb-sl-3,
  .ac-my-sl-3 {
    margin-bottom: 24px !important;
  }

  .ac-ml-sl-3,
  .ac-mx-sl-3 {
    margin-left: 24px !important;
  }

  .ac-m-sl-4 {
    margin: 32px !important;
  }

  .ac-mt-sl-4,
  .ac-my-sl-4 {
    margin-top: 32px !important;
  }

  .ac-mr-sl-4,
  .ac-mx-sl-4 {
    margin-right: 32px !important;
  }

  .ac-mb-sl-4,
  .ac-my-sl-4 {
    margin-bottom: 32px !important;
  }

  .ac-ml-sl-4,
  .ac-mx-sl-4 {
    margin-left: 32px !important;
  }

  .ac-m-sl-5 {
    margin: 48px !important;
  }

  .ac-mt-sl-5,
  .ac-my-sl-5 {
    margin-top: 48px !important;
  }

  .ac-mr-sl-5,
  .ac-mx-sl-5 {
    margin-right: 48px !important;
  }

  .ac-mb-sl-5,
  .ac-my-sl-5 {
    margin-bottom: 48px !important;
  }

  .ac-ml-sl-5,
  .ac-mx-sl-5 {
    margin-left: 48px !important;
  }

  .ac-p-sl-0 {
    padding: 0 !important;
  }

  .ac-pt-sl-0,
  .ac-py-sl-0 {
    padding-top: 0 !important;
  }

  .ac-pr-sl-0,
  .ac-px-sl-0 {
    padding-right: 0 !important;
  }

  .ac-pb-sl-0,
  .ac-py-sl-0 {
    padding-bottom: 0 !important;
  }

  .ac-pl-sl-0,
  .ac-px-sl-0 {
    padding-left: 0 !important;
  }

  .ac-p-sl-1 {
    padding: 8px !important;
  }

  .ac-pt-sl-1,
  .ac-py-sl-1 {
    padding-top: 8px !important;
  }

  .ac-pr-sl-1,
  .ac-px-sl-1 {
    padding-right: 8px !important;
  }

  .ac-pb-sl-1,
  .ac-py-sl-1 {
    padding-bottom: 8px !important;
  }

  .ac-pl-sl-1,
  .ac-px-sl-1 {
    padding-left: 8px !important;
  }

  .ac-p-sl-2 {
    padding: 16px !important;
  }

  .ac-pt-sl-2,
  .ac-py-sl-2 {
    padding-top: 16px !important;
  }

  .ac-pr-sl-2,
  .ac-px-sl-2 {
    padding-right: 16px !important;
  }

  .ac-pb-sl-2,
  .ac-py-sl-2 {
    padding-bottom: 16px !important;
  }

  .ac-pl-sl-2,
  .ac-px-sl-2 {
    padding-left: 16px !important;
  }

  .ac-p-sl-3 {
    padding: 24px !important;
  }

  .ac-pt-sl-3,
  .ac-py-sl-3 {
    padding-top: 24px !important;
  }

  .ac-pr-sl-3,
  .ac-px-sl-3 {
    padding-right: 24px !important;
  }

  .ac-pb-sl-3,
  .ac-py-sl-3 {
    padding-bottom: 24px !important;
  }

  .ac-pl-sl-3,
  .ac-px-sl-3 {
    padding-left: 24px !important;
  }

  .ac-p-sl-4 {
    padding: 32px !important;
  }

  .ac-pt-sl-4,
  .ac-py-sl-4 {
    padding-top: 32px !important;
  }

  .ac-pr-sl-4,
  .ac-px-sl-4 {
    padding-right: 32px !important;
  }

  .ac-pb-sl-4,
  .ac-py-sl-4 {
    padding-bottom: 32px !important;
  }

  .ac-pl-sl-4,
  .ac-px-sl-4 {
    padding-left: 32px !important;
  }

  .ac-p-sl-5 {
    padding: 48px !important;
  }

  .ac-pt-sl-5,
  .ac-py-sl-5 {
    padding-top: 48px !important;
  }

  .ac-pr-sl-5,
  .ac-px-sl-5 {
    padding-right: 48px !important;
  }

  .ac-pb-sl-5,
  .ac-py-sl-5 {
    padding-bottom: 48px !important;
  }

  .ac-pl-sl-5,
  .ac-px-sl-5 {
    padding-left: 48px !important;
  }

  .ac-m-sl-n1 {
    margin: -8px !important;
  }

  .ac-mt-sl-n1,
  .ac-my-sl-n1 {
    margin-top: -8px !important;
  }

  .ac-mr-sl-n1,
  .ac-mx-sl-n1 {
    margin-right: -8px !important;
  }

  .ac-mb-sl-n1,
  .ac-my-sl-n1 {
    margin-bottom: -8px !important;
  }

  .ac-ml-sl-n1,
  .ac-mx-sl-n1 {
    margin-left: -8px !important;
  }

  .ac-m-sl-n2 {
    margin: -16px !important;
  }

  .ac-mt-sl-n2,
  .ac-my-sl-n2 {
    margin-top: -16px !important;
  }

  .ac-mr-sl-n2,
  .ac-mx-sl-n2 {
    margin-right: -16px !important;
  }

  .ac-mb-sl-n2,
  .ac-my-sl-n2 {
    margin-bottom: -16px !important;
  }

  .ac-ml-sl-n2,
  .ac-mx-sl-n2 {
    margin-left: -16px !important;
  }

  .ac-m-sl-n3 {
    margin: -24px !important;
  }

  .ac-mt-sl-n3,
  .ac-my-sl-n3 {
    margin-top: -24px !important;
  }

  .ac-mr-sl-n3,
  .ac-mx-sl-n3 {
    margin-right: -24px !important;
  }

  .ac-mb-sl-n3,
  .ac-my-sl-n3 {
    margin-bottom: -24px !important;
  }

  .ac-ml-sl-n3,
  .ac-mx-sl-n3 {
    margin-left: -24px !important;
  }

  .ac-m-sl-n4 {
    margin: -32px !important;
  }

  .ac-mt-sl-n4,
  .ac-my-sl-n4 {
    margin-top: -32px !important;
  }

  .ac-mr-sl-n4,
  .ac-mx-sl-n4 {
    margin-right: -32px !important;
  }

  .ac-mb-sl-n4,
  .ac-my-sl-n4 {
    margin-bottom: -32px !important;
  }

  .ac-ml-sl-n4,
  .ac-mx-sl-n4 {
    margin-left: -32px !important;
  }

  .ac-m-sl-n5 {
    margin: -48px !important;
  }

  .ac-mt-sl-n5,
  .ac-my-sl-n5 {
    margin-top: -48px !important;
  }

  .ac-mr-sl-n5,
  .ac-mx-sl-n5 {
    margin-right: -48px !important;
  }

  .ac-mb-sl-n5,
  .ac-my-sl-n5 {
    margin-bottom: -48px !important;
  }

  .ac-ml-sl-n5,
  .ac-mx-sl-n5 {
    margin-left: -48px !important;
  }

  .ac-m-sl-auto {
    margin: auto !important;
  }

  .ac-mt-sl-auto,
  .ac-my-sl-auto {
    margin-top: auto !important;
  }

  .ac-mr-sl-auto,
  .ac-mx-sl-auto {
    margin-right: auto !important;
  }

  .ac-mb-sl-auto,
  .ac-my-sl-auto {
    margin-bottom: auto !important;
  }

  .ac-ml-sl-auto,
  .ac-mx-sl-auto {
    margin-left: auto !important;
  }

  .ac-d-sl-none {
    display: none !important;
  }

  .ac-d-sl-flex {
    display: flex !important;
  }

  .ac-d-sl-inline-flex {
    display: inline-flex !important;
  }

  .ac-d-sl-inline {
    display: inline !important;
  }

  .ac-d-sl-inline-block {
    display: inline-block !important;
  }

  .ac-d-sl-block {
    display: block !important;
  }

  .ac-d-sl-table-cell {
    display: table-cell !important;
  }

  .ac-d-sl-table-row {
    display: table-row !important;
  }

  .ac-flex-sl-row {
    flex-direction: row !important;
  }

  .ac-flex-sl-column {
    flex-direction: column !important;
  }

  .ac-flex-sl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .ac-flex-sl-grow-0 {
    flex-grow: 0 !important;
  }

  .ac-flex-sl-grow-1 {
    flex-grow: 1 !important;
  }

  .ac-flex-sl-wrap {
    flex-wrap: wrap !important;
  }

  .ac-flex-sl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .ac-flex-sl-nowrap {
    flex-wrap: nowrap !important;
  }

  .ac-flex-sl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .ac-flex-sl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .ac-align-items-sl-baseline {
    align-items: baseline !important;
  }

  .ac-align-items-sl-center {
    align-items: center !important;
  }

  .ac-align-items-sl-start {
    align-items: flex-start !important;
  }

  .ac-align-items-sl-end {
    align-items: flex-end !important;
  }

  .ac-align-items-sl-stretch {
    align-items: stretch !important;
  }

  .ac-justify-content-sl-center {
    justify-content: center !important;
  }

  .ac-justify-content-sl-start {
    justify-content: flex-start !important;
  }

  .ac-justify-content-sl-end {
    justify-content: flex-end !important;
  }

  .ac-justify-content-sl-around {
    justify-content: space-around !important;
  }

  .ac-justify-content-sl-between {
    justify-content: space-between !important;
  }

  .ac-text-sl-center {
    text-align: center !important;
  }

  .ac-text-sl-left {
    text-align: left !important;
  }

  .ac-text-sl-right {
    text-align: right !important;
  }

  .ac-position-sl-relative {
    position: relative !important;
  }

  .ac-position-sl-absolute {
    position: absolute !important;
  }

  .ac-position-sl-fixed {
    position: fixed !important;
  }

  .ac-col-sl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .ac-col-sl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .ac-col-sl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ac-col-sl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .ac-col-sl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .ac-col-sl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ac-col-sl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .ac-col-sl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .ac-col-sl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ac-col-sl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .ac-col-sl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .ac-col-sl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ac-offset-sl-0 {
    margin-left: 0%;
  }

  .ac-offset-sl-1 {
    margin-left: 8.333333%;
  }

  .ac-offset-sl-2 {
    margin-left: 16.666667%;
  }

  .ac-offset-sl-3 {
    margin-left: 25%;
  }

  .ac-offset-sl-4 {
    margin-left: 33.333333%;
  }

  .ac-offset-sl-5 {
    margin-left: 41.666667%;
  }

  .ac-offset-sl-6 {
    margin-left: 50%;
  }

  .ac-offset-sl-7 {
    margin-left: 58.333333%;
  }

  .ac-offset-sl-8 {
    margin-left: 66.666667%;
  }

  .ac-offset-sl-9 {
    margin-left: 75%;
  }

  .ac-offset-sl-10 {
    margin-left: 83.333333%;
  }

  .ac-offset-sl-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 320px) {
  .ac-accordion .card-header button::after {
    right: 8px;
  }
}

@media (max-width: 575px) {
  .ac-section-title,
  .ac-section-title {
    background-color: transparent;
    color: #333;
    padding: 0;
  }

  .ac-section-title h1,
  .ac-section-title h2 {
    color: #333;
    font-weight: bold;
  }
}

.border-width-4 {
  border-width: 4px !important;
  border-top-width: 4px !important;
  border-right-width: 4px !important;
  border-bottom-width: 4px !important;
  border-left-width: 4px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.font-size-16 {
  font-size: 16px;
}

.form-control {
  border-radius: 4px;
  input,
  select {
    border-radius: 4px;
  }
}

.form-control:focus {
  border-color: #8ebbf9 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #ccddfd;
}

.form-control::-webkit-input-placeholder {
  color: #c1bcbb !important;
}
