video.video-item-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.player-wrapper {
  position: absolute !important;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.paused-overlay-wrapper {
  position: unset !important;

  &>div {
    position: unset !important;
  }
}

.video-item {
  position: relative;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
  // margin-bottom: $grid-gutter-width;

  &-thumbnail {
    visibility: inherit;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    height: unset !important;
    position: unset !important;
  }

  .video-item-wrapper-link {
    position: relative;
    display: block;
    overflow: hidden;

    .video-item-action {
      position: absolute;
      z-index: 2;

      &-bottom {
        // bottom: -30px;
        bottom: 0;
        display: flex;
        width: 100%;
        align-items: center;
        transition: bottom .1s;
      }

      &-right {
        right: -40px;
        top: 0;
        transition: right .1s;
      }

      &-item {
        color: #e8e8e8;
        font-weight: bold;
        font-size: small;

        i {
          background: white;
          width: 30px;
          height: 30px;
          color: lightslategray;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:hover i {
          color: white;
          background-color: $primary;
        }
      }
    }

    &:hover {

      // .video-item-action-bottom {
      //   bottom: 0;
      // }
      .video-item-action-right {
        right: 0;
      }
    }
  }
}

.video-item-preview {
  &::before {
    content: "";
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
    opacity: 0;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    z-index: 3;
  }

  .video-item-action-overlay {
    transition: background .2s;
    color: white;
    font-weight: 600;
    font-size: 0.75rem;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 2px;
    margin: 0 0 6px 6px;
  }

  &:hover {
    &::before {
      opacity: 1;
      transition-delay: .1s;
    }

    .video-item-action-overlay {
      background: unset !important;
      transition-delay: .1s;
    }
  }
}


.video-item-download-info {
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
  }

  .form-check-label {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include sm {
      grid-template-columns: 2fr 4fr 2fr 1fr;
    }
  }
}

.space-align-block {
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  display: inline-flex;
}


.preview-item {
  transition: all 0.2s;

  &-fixed {
    width: calc(50vw - 30px);

    @include sm {
      width: 180px;
    }
  }

  // &:hover {
  //   background-color: #e8e8e8;
  //   cursor: pointer;
  // }

  &-title {
    display: inline-block;
    font-weight: bold;
    margin-top: 6px;
  }
}

.preview-item-imgs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: .15rem;
  overflow: hidden;
  // margin-bottom: 10px;
  height: calc(50vw - 25px);
  width: calc(50vw - 30px);
  // border-radius: 20px;

  @include sm {
    height: 160px;
    width: 180px;
  }

  &-3 {
    div:first-child {
      grid-row: 1 e("/") 3;
    }
  }

  &-2 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  &-1 {
    grid-template-columns: none;
    grid-template-rows: none;
  }
}

.preview-item-imgs-category {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  
  margin-top: 6px;
  grid-gap: .15rem;
  height: 30px;

  @include md {
    height: 50px;
  }
  @include xl {
    height: 60px;
  }
}

.preview-item-img-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.preview-item-img {
  position: absolute;
  display: block;
  // min-width: 100%;
  // min-height: 100%;
  // height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
  right: 0;
}

.video-item-tag {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5em;
  flex-direction: column;

  &-title {
    text-decoration: underline;
    font-weight: 600;
    margin-bottom: 5px;
    // color: black;
  }

  .ant-space-item {
    display: flex;
    align-items: center;
  }

  &-item {
    // box-shadow: 0 3px 5px 0 rgba(0 ,0 , 0 , 8%);
    background-color: white;
    font-weight: 600;
    // font-size: 1.2em;
    border-radius: 25px;
    padding: 5px 10px;
    display: flex;
    align-items: center;

  }

  img {
    border-radius: 50%;
  }
}


// .modal-content {
//   // border-radius: 20px;
//   padding-top: 2rem;
//   padding-bottom: 2rem;
// }

.share {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-social {
    display: inline-flex;

    .icon-social {
      margin: auto;
    }

    &-item {
      padding: 0 20px;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

  }

  &-title {
    width: 80%;
  }

  &-link {
    margin-top: 20px;
    padding: 10px;
    background: #e8e8e8;
    border-radius: 20px;
    width: 80%;
    display: flex;
    justify-content: center;
  }
}

.share-link-copy-icon {
  font-size: 1.1rem;
  opacity: 0.8;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &-success {
    font-size: 1.1rem;
    color: $success;
  }
}

.img-lazy-load {
  position: relative;
  /* border: 2px solid black; */
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.img-lazy-load-placeholder {
  // position: absolute;
  top: 0;
  left: 0;
  // background: red;
  z-index: -1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.img-lazy-load-content {
  /* border: 1px solid green; */
  position: relative;
  display: block;
  width: 100%;
  z-index: 2;
}
.max-height-68 {
  max-height: 68px;
}
.form-check-download {
  position: relative;
}
// .form-check-download::after{
//   content:"";
//   position: absolute;
//   width: 0; 
//   height: 0; 
//   border-left: 10px solid transparent;
//   border-right: 10px solid transparent;
//   border-bottom: 10px solid black;
//   left: 0%;
//   display:none;
// }
.popup{
  width: 250px;
  height: 100px;
  background-color: #282C34;
  position:absolute;
  top: 28px;
  right:70%;
  z-index: 999;
  color:#e8e8e8;
  padding:10px 10px;
  display:none;
}
.popup::after{
  content:"";
  position: absolute;
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black;
  left: 44%;
  top: -10%;
  // display:none;
}
.form-check-download:hover .popup{
  display:block;
}
.max-width-700 {
  max-width:700px;
}
.alice-carousel__stage-item {
  margin: 3px !important;
}
.alice-carousel__dots {
  display:none;
}
.alice-carousel__prev-btn-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 35%;
  bottom: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #000!important;
  color: #fff!important;
  font-weight: bold;
  box-shadow: none;
  outline: 0;
  z-index: 1;
  left: 20px;
}
.alice-carousel__next-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 14%;
  margin: auto;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #000!important;
  font-weight: 700;
  box-shadow: none;
  outline: 0;
  right: 1%;
  z-index: 1;
}
.alice-carousel__prev-btn-item span {
  padding: 5px;
}
.alice-carousel__next-btn-wrapper span {
  color: #fff!important;
  padding: 5px;
}
.modal-header-bitcoin .close {
  margin: -3rem -1rem -1rem auto !important;
}
.text-link {
  color: #3C9275;
  text-decoration: under;
}
