#apply-page-wrapper {
  h1 {
    color: #fff;
    background-color: #333;
    padding: 0.7rem 1rem;
    font-size: 18px;
    font-weight: bold;
    height: 45px;
  }

  #content-wrapper {
    padding: 27px 30px 30px;
    border: 1px solid #e6e6e6;
  }

  .small-text {
    font-size: small;
  }

  .large-text {
    font-size: 20px;
  }
  .bold-text {
    font-weight: bold;
  }

  .form-control {
    display: inline-block;
    margin: 0.2rem auto;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 4px 16px;
  }

  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #ccddfd;
    // box-shadow: 0 0 0 0.2rem rgb(0 123 255 25%);
  }

  .form-control::placeholder {
    color: #c1bcbb;
  }

  .submit-btn {
    display: block;
    // margin: 1rem auto;
    margin-left: auto;
    margin-right: auto;
    background-color: #3c9275;
    color: #fff;
  }
}
.arrow-wrapper-apply {
  .arrow-wrapper-square {
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 70px;
    background-color: #D9D9D9;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    text-align: center;
  }
  .arrow-wrapper-square-active {
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 215px;
    height: 70px;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    background-color: #3c9275;
    text-align: center;
  }
  .arrow-wrapper-triangle {
    width: 0;
    height: 0;
    border-left: 15.715px solid transparent;
    border-right: 15.715px solid transparent;
    border-bottom: 17.015px solid #D9D9D9;
    transform: rotate(90deg);
  }
  .arrow-wrapper-triangle-active {
    width: 0;
    height: 0;
    border-left: 15.715px solid transparent;
    border-right: 15.715px solid transparent;
    border-bottom: 17.015px solid #3c9275;
    transform: rotate(90deg);
  }
}
.arrow-wrapper-apply-mobile {
  display: none !important;
  .arrow-wrapper-square {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 48px;
    background-color: #D9D9D9;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    text-align: center;
  }
  .arrow-wrapper-square-active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 48px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    background-color: #3c9275;
    text-align: center;
  }
  .arrow-wrapper-triangle {
    width: 0;
    height: 0;
    border-left: 19.715px solid transparent;
    border-right: 19.715px solid transparent;
    border-bottom: 34.015px solid #D9D9D9;
    transform: rotate(180deg);
    margin: 10px auto;
  }
  .arrow-wrapper-triangle-active {
    width: 0;
    height: 0;
    border-left: 19.715px solid transparent;
    border-right: 19.715px solid transparent;
    border-bottom: 34.015px solid #3c9275;
    transform: rotate(180deg);
    margin: 10px auto;
  }
}
@media screen and (max-width: 663px) {
  .arrow-wrapper-apply {
    display: none !important;;
  }
  .arrow-wrapper-apply-mobile {
    display: block !important;
  }
}
@media screen and (max-width: 1244px) and (min-width: 992px) {
  .arrow-wrapper-square {
    font-size: 10px !important;
  }
  .arrow-wrapper-square-active {
    font-size: 10px !important;
  }
  .link-component-apply .reg-blog-wrapper {
    width: 170% !important;
  }
}
@media screen and (max-width: 991px) and (min-width: 663px) {
  .link-component-apply .reg-blog-wrapper {
    width: 70% !important;
  }
}
@media screen and (max-width: 571px) {
  .link-component-apply .reg-blog-wrapper-image  {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
}
.link-component-apply .reg-blog-wrapper-content {
  margin-left: 14px !important;
}
.arrow-wrapper-apply {
.arrow-steps .step:after,
  .arrow-steps .step:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -40px;
    width: 0;
    height: 0;
    border-top: 40px solid transparent;
    border-bottom: 47px solid transparent;
    border-left: 40px solid #D9D9D9;
    z-index: 1;
    transition: border-color 0.2s ease;
    bottom: 0;
    margin: auto;
}
.arrow-steps-wrapper .arrow-steps .step {
  width: 15% !important;
  background-color: #D9D9D9 !important;
}
.arrow-steps-wrapper .arrow-steps .step-active {
  background-color: #3c9275 !important;
  width: 26% !important;
  border-color: #3c9275;
}
.arrow-steps .step:first-child {
  background-color: #D9D9D9 !important;
}
.arrow-steps .step:first-child:after,
.arrow-steps .step:first-child:before {
  border-left: 40px solid #D9D9D9 !important ;
}
.arrow-steps .step-active:after,
  .arrow-steps .step-active:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -40px;
    width: 0;
    height: 0;
    border-top: 40px solid transparent;
    border-bottom: 47px solid transparent;
    border-left: 40px solid #3c9275;
    z-index: 1;
    transition: border-color 0.2s ease;
    bottom: 0;
    margin: auto;
}
  @media screen and (max-width: 1563px) {
    .arrow-steps-wrapper .arrow-steps .step-active {
      width: 25% !important;
    }
  }
  @media screen and (max-width: 1535px) {
    .arrow-steps-wrapper .arrow-steps .step {
      width: 15% !important;
      background-color: #D9D9D9 !important;
      // font-size: 16px;
    }
    .arrow-steps-wrapper .arrow-steps .step-active {
      background-color: #3c9275 !important;
      width: 24% !important;
    }
    .arrow-steps-wrapper .arrow-steps .step:before {
      left: -1px;
    }
    .arrow-steps .step-active:after {
      right: -39px;
    }
    .arrow-steps .step:after {
      right: -39px;
    }
  }
  @media screen and (max-width: 1223px) and (min-width: 1088px) { 
    .arrow-steps-wrapper .arrow-steps .step {
      font-size: 10px;
    }
  }
  @media screen and (max-width: 1088px) and (min-width: 840px) { 
    .arrow-steps-wrapper .arrow-steps .step {
      font-size: 8px;
    }
  }
  @media screen and (max-width: 840px) and (min-width: 719px) { 
    .arrow-steps-wrapper .arrow-steps .step {
      font-size: 10px;
    }
  }
  @media screen and (max-width: 718px) and (min-width: 663px) {
    .arrow-steps-wrapper .arrow-steps .step {
      font-size: 8px;
    }
  }
}
.link-component-apply .reg-blog-wrapper {
  border: 2px solid #3C9275;
  margin-left: 0px;
  // padding: 24px;
}
.input-step {
  border: 1px solid #333;
  padding: 4px 8px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
}
.link-component-apply {
  @media screen and (min-width: 663px) {
    .reg-blog-wrapper-content-title {
      margin-left: 53px !important;
    }
    .reg-blog-wrapper-content-content {
      margin-left: 53px !important;
      margin-right: 17px !important;
    }
  }
  .reg-blog-wrapper-image {
    margin-top: 20px !important;
    margin-bottom: 22px !important;
    margin-left: 20px !important;
  }
}
.fw-600 {
  font-weight: 600 !important;
}



