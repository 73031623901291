$sidebar-width: 900px;

.sidebar {
  // min-width: $sidebar-width;
  width: $sidebar-width;
  // max-width: $sidebar-width;
  transition: all 0.3s;
  position: fixed;
  margin-left: -$sidebar-width;
  left: 0;
  top: 0;
  background-color: white;
  padding: 12px;
  z-index: 9;
  height: 100vh;
  box-shadow: 0 5px 6px 0 rgba(32, 33, 36, 0.28);
  overflow-y: auto;
  &-right {
    margin-right: -$sidebar-width;
    left: auto;
    right: 0;
  }
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
  
  &.sidebar-right {
    margin-right: 0;
  }
}


// @for $var from 8 through 50 {
//   .w-#{$var} {
//       width: 6px * $var;
//       max-width: 6px * $var;
//       min-width: 6px * $var;
//   }
// }

.w-fit-content {
  max-width: fit-content;
}