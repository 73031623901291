.video-info-head {
  display: inline-flex;
  height: 100%;
  align-items: center;
  margin-bottom: 1rem;
}

.video-info-head-item {
  i {
    margin-right: 5px;
    // color: $secondary;
    font-size: 0.8em;
  }
  &-data {
    font-weight: 600;
  }
  &-name {
    color: $secondary;
  }
}

.download {
  // border: 1px solid rgb(221, 221, 221);
  border-radius: $border-radius;
  padding-left: 15px;
  // padding: 12px 24px;
  // box-shadow:  0px 6px 16px rgba(0, 0, 0, 0.2);
  // margin-bottom: 1em;

  .btn {
    width: 100%;
    line-height: 1.8rem;
  }
}

.download-section-premium {
  .btn-outline-premium {
    color: $secondary;

  }

  .tiny {
    color: $gray-600;
  }
}


.video-item-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;

  @include sm {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include md {
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: 0;
  }

  
  &-item {
    display: flex;

    @include md {
      display: block;
      margin-right: 18px;
    }

    &-title {
      text-transform: uppercase;
      margin-bottom: 0px;
      color: $secondary;
      font-size: 0.8rem;
      font-weight: 600;
      margin-right: 6px;
      @include md {
        display: inline-flex;
        justify-content: space-between;
      }
    }
    &-content {
      display: block;
      font-weight: 400;
    }
  }
}

.video-item-video[audio="0"] {
  &::-webkit-media-controls-mute-button {
    display: none !important;
  }
  
  &::-webkit-media-controls-volume-slider {
    display: none !important;
  }
}

#term_see_more {
  font-size: 0.75rem;
  color: $blue;
  text-decoration: underline;
  margin-top: 12px;
  display: block;
  width: fit-content;

  &:hover {
    color: $primary;
  }
}
.tooltip-download-link {
  font-weight: bold;
}
.tooltip-download-link:hover {
  text-decoration: underline;
}
.bg-f7f9fa {
  background-color: #f7f9fa!important;
}
.btn-share-video {
  border: 1px solid #DEE2E6;
  font-size:21px;
  padding: 5px 8px;
  margin-top: 5px;
  cursor: pointer;
}
.modal-show-video-download {
  .modal-content {
    padding: 3px 17px 0px 11px;
    border-radius: 10px;
  }
  .modal-header {
    padding: 0px;
    border-bottom: none;
    background-color: white;
  }
  .modal-body {
    padding:5px 10px 15px;
  }
  .close {
    padding: 15px 3px 12px 0px !important;
  }
  .close span {
    line-height: 21px !important;
    font-weight: 400 !important;
    font-family: 'Roboto';
  }
  .video-modal {
    width: 376px !important;
    height:347px !important;
  }
  @media screen and (min-width: 1231px) {
    .modal-content {
      width: 800px !important;
      height:466px;
    }
    .video-modal {
      width: auto !important;
    }
  }
  @media screen and (max-width: 1231px) {
    .video-modal {
      width: auto !important;
    }
    .download {
      margin-top: 30px;
    }
  }
}
.video-item-download-modal-info {
  .download-button-tooltip {
    position: absolute;
    background-color: #333333;
    padding: 7px 7px 6px 6px;
    color: white;
    border-radius: 3px;
    font-size: 13px;
    margin-top: -26px;
  }
  .download-button-tooltip::before {
    position: absolute;
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid #333;
    top: 30px;
    left:37px;
  }
}
.video-item-download-info {
  .download-button-tooltip {
    position: absolute;
    background-color: #333333;
    padding: 6px 7px 4px 6px;
    color: white;
    border-radius: 3px;
    font-size: 13px;
    margin-top: -32px;
    left: 43px;
  }
  .download-button-tooltip::before {
    position: absolute;
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid #333;
    top: 27px;
    left:8px;
  }
}
.download-button-tooltip {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.download-button-tooltip.show {
  opacity: 1;
  visibility: visible;
}
.tooltip-right-of-use {
  .tooltip-inner {
    max-width: 300px !important;
  }
}
.AI-badge {
  color: white;
  font-weight: bold;
  font-size: 12px;
  background: #858384;
  border-radius: 3px;
  position: absolute;
  z-index: 3;
  top: 25%;
  left: 14%;
  padding: 4px 0px 3px 6px;
}
.AI-badge-video {
  color: white;
  font-weight: bold;
  font-size: 12px;
  background: #858384;
  border-radius: 3px;
  position: absolute;
  z-index: 2;
  top: 4%;
  left: 2%;
  padding: 4px 0px 3px 6px;
}
.AI-badge-video-detail {
  color: white;
  font-weight: bold;
  font-size: 12px;
  background: #858384;
  border-radius: 3px;
  position: absolute;
  z-index: 3;
  top: 16px;
  left: 16px;
  padding: 4px 0px 3px 6px;
}
.modal-first-download {
  .modal-content {
    display: contents !important;
  }
  .modal-body {
    img {
      width: 516px !important;
      height: 503px !important;
    }
  }
}
.badge-validate {
  cursor: unset !important;
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 4px 10px;
}
.modal-cancle-account-noti {
  .modal-body {
    padding: 0px 10px !important;
  }
}
.modal-reset-tag {
  .close {
   padding: 4px !important;
   span {
     background-color: #E6E6E6 !important;
   }
  }
  button {
   border-radius: 5px;
  }
}
.modal-first-user-login {
  .modal-dialog {
    max-width: 565px;
    width: 100%;
  }
  @media only screen and (max-width: 653px) {
    .modal-dialog {
        max-width: 434px !important;
        margin: 0 auto;
    }
  }
  @media only screen and (max-width: 510px) {
    .modal-dialog {
        max-width: 304px !important;
    }
  } 
//   .window-screen .pr-designac-modal__icon_mr_b p {
//     bottom: 4.5em !important;
//     font-size: 10px;
//     left: 13px;
//   }
//   @media only screen and (max-width: 959px) {
//     .window-screen .pr-designac-modal__icon_mr_b p {
//         bottom: 15em !important;
//         left: 21px;
//     }
//   }
//   @media only screen and (max-width: 784px) {
//     .window-screen .pr-designac-modal__icon_mr_b p {
//         bottom: 13.5em !important;
//         left: 21px;
//     }
//   }
//   @media only screen and (max-width: 644px) {
//     .window-screen .pr-designac-modal__icon_mr_b p {
//       bottom: 13em !important;
//       left: 1.5em;
//       font-size: 8px;
//     }
//   }
//   @media only screen and (max-width: 480px) {
//     .window-screen .pr-designac-modal__icon_mr_b p {
//         bottom: 17.5em !important;
//         left: 2.7em;
//         font-size: 5px;
//     }
//   }
//   p {
//     margin-bottom: 0;
//   }

  .modal-body {
    padding: 0;
  }
  .modal-content {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 10px !important; 
    width: 100% !important;
  }
//   .close-button {
//     all: unset; /* Reset tất cả */
//     position: absolute;
//     background-color: #333;
//     color: #fff;
//     border: none;
//     border-radius: 50%;
//     width: 25px;
//     height: 25px;
//     font-size: 16px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     line-height: 25px;
//     cursor: pointer;
//     z-index: 1;
//     right: 4px;
//   }
// .pr-designac-modal__icon_mr_b p {
//   bottom: 3.5em !important;
//   left: 3px;
//   font-size: 12px;
// }
// @media only screen and (max-width: 959px) {
//   .pr-designac-modal__icon_mr_b p {
//       bottom: 12.5em !important;
//       left: 16px;
//       font-size: 12px;
//   }
// }
// @media only screen and (min-width: 959px) {
//       .pr-designac-modal__footer__link:hover {
//         text-decoration: underline;
//       }
// }
// @media only screen and (max-width: 870px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 37.5%;
//   }
// }
// @media only screen and (max-width: 783px) {
//   .pr-designac-modal__icon_mr_b p {
//       bottom: 13.5em !important;
//       left: 2em;
//       font-size: 10px;
//   }
// }
// @media only screen and (max-width: 645px) {
//   .pr-designac-modal__icon_mr_b p {
//       bottom: 13em !important;
//       left: 2em;
//       font-size: 8px;
//   }
// }
// @media only screen and (max-width: 611px) {
//   .pr-designac-modal__icon_mr_b p {
//     bottom: 21.5em !important;
//     left: 3em;
//     font-size: 6px;
//   }
// }
// @media only screen and (max-width: 608px) {
//   .pr-designac-modal__icon_mr_b p {
//     bottom: 17.5em !important;
//     left: 3em;
//     font-size: 6px;
//   }
// }
// @media only screen and (max-width: 580px) {
//   .pr-designac-modal__icon_mr_b p {
//       bottom: 17em !important;
//       left: 2em;
//       font-size: 6px;
//   }
//   .modal-dialog {
//     margin: 0;
//   }
//   .pr-designac-modal__icon_mr_b {
//     bottom: 43%;
//   }
// }
// @media only screen and (max-width: 480px) {
//   .pr-designac-modal__icon_mr_b p {
//       bottom: 15em !important;
//       left: 2em;
//       font-size: 6px;
//   }
// }
// @media only screen and (max-width: 783px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 36.7%;
//   }
// }
// @media only screen and (max-width: 767px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 41%;
//   }
// }
// @media only screen and (max-width: 730px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 41%;
//   }
// }
// @media only screen and (max-width: 690px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 41%;
//   }
// }
// @media only screen and (max-width: 660px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 41%;
//   }
// }
// @media only screen and (max-width: 653px) {
//   .modal-dialog {
//       max-width: 434px !important;
//   }
// }
// @media only screen and (max-width: 510px) {
//   .modal-dialog {
//       max-width: 304px !important;
//   }
// }
// @media only screen and (max-width: 611px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 40.5%;
//       right: 1em;
//   }
  
// }
// @media only screen and (max-width: 580px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 40%;
//   }
// }
// @media only screen and (max-width: 570px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 44%;
//   }
// }
// @media only screen and (max-width: 480px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 43.2%;
//   }
//   .pr-designac-modal__container__main img.dac-template {
//       width: 285px;
//   }
// }
// @media only screen and (max-width: 430px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 43.5%;
//   }
//   .modal-content {
//     height: 38vh !important;
//   }
// }
// @media only screen and (max-width: 414px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 44.1%;
//       right: 0%;
//   }
// }
// @media only screen and (max-width: 375px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 44%;
//   }
// }
// @media only screen and (max-width: 390px) {
//   .pr-designac-modal__icon_mr_b {
//       bottom: 45%;
//   }
// }
// }
}
.modal-download-video {
  .radio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
  }
  .radio-container input {
      margin-bottom: 5px;
  }
  .btn-download {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
  }
  .ac-btn-photo:hover{
    color: #fff;
    background-color: #31775f !important;
    border-color: #2d6e58 !important;
  }
}
.modal-creator-work {
  p {
    margin-bottom: 0;
  }
  .modal-header {
    background-color: #f1f1f1;
  }
  .modal-header .close {
    padding: 10px 0px;
  }
  .modal-header {
    padding: 10px 24px 3px;
    border-bottom: unset;
  }
  .modal-body {
    padding: 0px 24px 10px 24px !important;
    background-color: #f1f1f1;
  }
  .modal-content {
    border-radius: 7px;
  }
  .modal-creator-work-container {
    margin-top: -1px;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    margin-bottom: 10px;
    max-height: 80vh;
    overflow-y: auto;
  }
  .scrollable-content {
    max-height: 70vh; 
    overflow-y: auto;
    padding-right: 16px;
  }
  .modal-creator-work-header {
    width: 100%;
    height: 50px;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: linear-gradient(to top, #71bba2, #3c9275);;
  }
}
.bg-danger {
  background-color: #d73a4e !important;
  color: #fff;
  padding: 3px 3px;
  font-size: 12px;
  width: 32px;
  height: 20px;
  line-height: 21px;
}
.btn-send-mail-work {
  border: solid 1px #3c9275 !important;
  background-color: #3c9275 !important;
  color: #fff !important;
}
.btn-send-mail-work:hover {
  color: #fff;
  background-color: #31775f !important;
  border-color: #2d6e58 !important;
}
@media screen and (max-width: 600px) {
    .img-work-modal {
        display: none !important;
    }
    .capcha-work-modal iframe {
      width: 96% !important
    }
    .capcha-work-modal {
      margin-left: 13px;
    }
}
