$loading-color: white;

.spinner-play .loader {
  position: relative;
  margin: 0px auto;
  width: 50px;
  height:50px;
}
.spinner-play .loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular-loader {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loader-path {
  stroke-dasharray: 150,200;
  stroke-linecap: round;
  stroke: $loading-color;
}

.triangle {
  transition: transform 0.1s;
  transform-origin: center center;
  fill: $loading-color;
}

.spinner-play {
  &.play {
    .circular-loader {
      -webkit-animation: rotate 2s linear infinite;
      animation: rotate 2s linear infinite;
      -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
    }
    .loader-path {
      stroke-dashoffset: -10;
      -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    }
    .triangle {
      transform: scale(0);
    }
  }
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}