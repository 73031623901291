$nav-font-weight: 500;
$nav-sticky-height: 60px;

.navbar {
  // height: 38px;
  padding: 0 !important;

  &--btn--more.anticon {
    font-size: 24px;
    vertical-align: middle;
  }

  // @include md {
  //   .dropdown-toggle::after{
  //     display: none;
  //   }
  // }
}

.navbar-container-tophead {
  display: block !important;
  .container {
    display: block !important;
  }
}

.common-site-links .container {
  
}

@media screen and (max-width: 1640px) {
  .common-site-links .container {
    padding-right: 48px !important;
  }
}

.navbar-container {
  background-color: $gray-100;

  @include only("lg") {
    flex-direction: column;
  }
}

.navbar-container-sticky {
  position: -webkit-sticky;
  /* Safari & IE */
  position: sticky;
  top: -2px;
  z-index: 9;
  display: flex;
  align-items: center;
  // height: $nav-sticky-height;
}

.navbar-brand {
  font-size: $font-size-base;
  font-weight: $nav-font-weight;

  span {
    color: $primary;
  }
}

.nav-item-ai .dropdown-menu.show {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  @include md {
    width: max-content;
  }
}

.nav-item-profile {
  height: $nav-sticky-height;
}

.nav-item-profile .dropdown-toggle {
  padding: 0 !important;
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar-nav .nav-link {
  height: 50px;
  display: flex;
  font-weight: $nav-font-weight;
  align-items: center;

  // @include only('sm') {
  //   padding-left: 0!important;
  //   padding-right: 0!important;
  // }

  @include xl {
    justify-content: center;
  }

  &[aria-expanded="true"],
  &:hover {
    background-color: $gray-200;
  }
}

.nav-item-service-link {
  color: $text-color;
  padding: 5px 12px;

  &:hover {
    color: $text-color;
    background-color: $gray-200;
    text-decoration: none;
  }
}

.nav-item .dropdown-menu {
  margin: 0;
  border: none;
  min-width: 100%;
  // min-width: fit-content;
}

#omega-overlay {
  position: fixed;
  z-index: 11 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

// .common-site-links-mobile {
//   .text-decoration-none {
//     img {
//       display: none !important;
//     }
//   }
// }

.navbar-collapse {
  // background-color: $dark;
  z-index: 10005;
  position: fixed;
  top: 0;
  width: 320px;
  height: 100%;
  transition: transform 0.2s ease-in-out;

  #omega-sidebar {
    background: #fff;
    width: 100%;
    max-width: 320px;
    height: 100%;
    z-index: 10005;
  }

  #omega-sidebar-body {
    overflow-y: auto;
    padding-bottom: 50px;
    height: 100vh;
  }

  .mobile-menu {
    width: 100%;
    padding: 0;

    .mobile-menu-item {
      font-weight: 400;
      color: #333;
      padding: 8px;
      border-bottom: 1px solid #dee2e6;

      &:hover {
        background-color: $gray-200;
        cursor: pointer;
      }

      i {
        display: block;
      }
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-primary {
        color: #1d74b3;
      }
    }
  }

  // &.collapse,
  // .collapse {
  //   display: block !important;
  // }

  &.show {
    transform: translateX(0%) !important;

    .navbar-nav {
      @include md {
        // padding: 0;
      }
    }

    // .collapse {
    //   display: block ;
    //   transition: transform 0.2s ease-in-out;
    // }

    #omega-overlay {
      display: block;
    }
  }
}
.navbar-collapse-creator {
  // background-color: $dark;
  z-index: 10005;
  position: fixed;
  top: 0;
  width: 320px;
  height: 100%;
  transition: transform 0.2s ease-in-out;
  left: 0;

  #omega-sidebar {
    background: #fff;
    width: 100%;
    max-width: 320px;
    height: 100%;
    z-index: 10005;
  }

  #omega-sidebar-body {
    overflow-y: auto;
    padding-bottom: 50px;
    height: 100vh;
  }

  .mobile-menu {
    width: 100%;
    padding: 0;

    .mobile-menu-item {
      font-weight: 400;
      color: #333;
      padding: 8px;
      border-bottom: 1px solid #dee2e6;

      &:hover {
        background-color: $gray-200;
        cursor: pointer;
      }

      i {
        display: block;
      }
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-primary {
        color: #1d74b3;
      }
    }
  }

  // &.collapse,
  // .collapse {
  //   display: block !important;
  // }

  &.show {
    transform: translateX(0%) !important;

    .navbar-nav {
      @include md {
        // padding: 0;
      }
    }

    // .collapse {
    //   display: block ;
    //   transition: transform 0.2s ease-in-out;
    // }

    #omega-overlay {
      display: block;
    }
  }
}

button.navbar-toggler {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.navbar-toggler {
  border: none !important;
}



.nav-link-premium {
  color: $premium;
  border: 1px solid $premium;
  &:hover {
    color: $premium-hover;
    background-color:$premium-hover-background;
  }
}

#signup-nav-dropdown-wrapper #signup-nav-dropdown.dropdown-toggle,
.nav-btns button,
.nav-btns a {
  border-radius: 4px;
}

#signup-nav-dropdown-wrapper #signup-nav-dropdown.dropdown-toggle {
  @extend .btn;
  @extend .btn-outline-primary;
  @extend .mr-2;
}

#signup-nav-dropdown-wrapper .dropdown-item.active,
#signup-nav-dropdown-wrapper .dropdown-item:active {
  color: #000;
  background-color: #fff;
}

#signup-nav-dropdown::after {
  display: none;
}

#signup-nav-dropdown-wrapper .dropdown-menu {
  margin-top: 10px;
  border-radius: 4px;
  padding: 0;
}

#signup-nav-dropdown-wrapper .dropdown-menu a {
  padding: 4px 10px;
}

#signin-nav-dropdown {
  padding: 0;
}

#signin-nav-dropdown::after {
  display: none;
}
.ac-dropdown-apps{
  position: absolute;
  will-change: transform;top: 0px; left: 0px; 
  transform: none !important;
  right: 0;
  left: unset!important;
  top: 100%!important;
  right: 0 !important;
}
.dropdown-item-login {
  font-size:13px;
  padding-left: 15px;
}
.dropdown-item-login:hover {
  color: #3c9275;
  background-color:#F8F9FA;
}
.dropdown-item-logined:hover {
  color: #3c9275;
  background-color:#F8F9FA;
}
.dropdown-menu[aria-labelledby = signin-nav-dropdown] {
  transform: translate(0px, 40px) !important;
  padding:0px;
  border-radius:8px
}
.dropdown-item-logined {
  font-size:13px;
}
.dropdown-item-logined:hover {
  color:#3c9275 !important;
  cursor: pointer;
  background-color:#F8F9FA;
}
.dropdown-menu[aria-labelledby = basic-nav-dropdown--] {
  border-radius:5px
}
.ac-apps-button {
  padding: 6px 6px 9px 6px;
}
// .ac-apps-button:hover {
//   background-color: #eee7e7 !important;
// }
.dropdown-section {
  margin: auto;
}
.button-login-mobile {
  display: none;
  button {
    border-radius: 5px;
    border: 1px solid #F8F9FA;
  }
  .button-login-mobile-register button {
    background-color: #F8F9FA;
    color: #3c9275;
    padding: 6px 6px;
  }
  .button-login-mobile-register button:active {
    background-color: unset;
  }
  .dropdown-toggle::after {
    content: none !important;
  }
}
.button-login-mobile-register-wrapper {
  .btn-primary:not(:disabled):not(.disabled):active, 
  .btn-primary:not(:disabled):not(.disabled).active, 
  .show > .btn-primary.dropdown-toggle {
    // color: #fff;
    background-color: #F8F9FA;
    border-color: #296551;
    color: #3c9275;
    border-color: #3c9275;
    border-style: solid;
  } 
}

@media screen and (max-width: 571px) {
  .button-login-mobile {
    display: flex;
  }
}
.container {
  max-width: 100%;
  padding-left: 12px;
  padding-right: 12px;
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1600px !important;
  }
}
@media screen and (max-width: 992px) {
  .dropdown-section {
    display: none !important;
  }
}
.button-beta {
  border: 1px solid #333;
  padding: 4px 6px;
  border-radius: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
}
@media screen and (max-width: 576px) {
  .logo-description {
    display: none !important;
  }
}
.w-100 {
  width:100%!important
}
.w-50 {
  width:50%!important
}
.border-bottom {
  border-bottom: 1px solid #dee2e6!important;
}
.border-right {
  border-right: 1px solid #dee2e6!important;
}
@media screen and (max-width: 1200px) {
  .button-premium {
    display: none !important;
  }
}
.button-login-medium-green button::after{
  display: none !important;
}
.dropdown-menu-profile .dropdown-menu {
  padding: 0 !important;
  border-radius: 8px;
}
.dropdown-item-text {
  padding-left: 15px;
}
.dropdown-logined-creator .dropdown-menu {
  padding: 0 !important;
  border-radius: 8px;
}
@media screen and (max-width: 1036px) and (min-width: 992px) {
  .carousel-caption {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.navbar-toggler-icon {
  display: none !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.85%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}
@media screen and (max-width: 991px) {
  .navbar-toggler-icon {
    display: block !important;
  }
}
.creator-signup-button {
  border: none;
  color: #333;
}
.creator-signup-button:hover {
  color: #3c9275;
}
.premium-header {
  display: none !important;
}
@media screen and (max-width: 800px) and (min-width: 576px) {
  .search-container-header {
    padding-top: 30px !important;
  }
  .premium-header {
    display: block !important;
    width: 100%;
    left: 0;
    top: 42%;
  }
}
.loading-fullscreen {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading-fullscreen:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading-fullscreen:not(:required) {
  /* hide "loading-fullscreen..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading-fullscreen:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
.header-right-small-screen {
  display: none !important;
}
@media screen and (max-width: 992px) {
  .header-right-big-screen {
    display: none !important;
  }
  .header-right-small-screen {
    display: block !important;
  }
}
.dropdown-menu[aria-labelledby = dropdown-button-drop-start] {
  transform: translate(0px, 42px) !important;
  padding:0px;
  border-radius:5px
}
.dropdown-menu[aria-labelledby = dropdown-button-drop-start] a {
  padding: 4px 10px;
}
.dropleft .dropdown-toggle::before {
  display: none !important;
}

// #signup-nav-dropdown-wrapper .dropdown {
//   position: unset !important;
// }
@media screen and (max-width: 992px) {
  .dropdown-menu[aria-labelledby = signin-nav-dropdown] {
    inset: 0px auto auto -60px !important;
  }
  .header-search {
    display: none !important;
  }
  .header-search-sm {
    display: block !important;
  }
}
@media screen and (max-width: 1231px) and (min-width: 992px){
  .dropdown-menu[aria-labelledby = signin-nav-dropdown] {
    inset: 0px auto auto -2px !important;
  }
}
.dropdown-menu {
  z-index: 9999 !important;
}
.adsbygoogle {
  z-index: 9 !important;
}
@media screen and (max-width: 574px) {
  .navbar-container-sticky {
    position: unset;
  }
}
#signup-nav-dropdown {
  background-color: #F8F9FA !important;
  border: 1px solid #F8F9FA !important;
}
#signup-nav-dropdown-wrapper .show > .dropdown-toggle#signup-nav-dropdown {
  color: #3c9275 !important;
  border: 1px solid #3c9275 !important;
}
.btn-outline-primary:hover, #signup-nav-dropdown-wrapper #signup-nav-dropdown.dropdown-toggle:hover {
  color: #3c9275 !important;
}
.button-login-mobile-register {
  button:hover {
    border-color: #F8F9FA;
  }
}
.border-3C9275 {
  background-color: #F8F9FA !important;
  border-color: #F8F9FA !important;
}
.border-F8F9FA {
  background-color: #F8F9FA !important;
}
@media screen and (max-width: 1371px) {
  .avatar-creator-image {
    display: none !important;
  }
}
.logo-description-detail:hover div span {
  color: #3c9275;
}
.downloader-signup-button:hover {
  color: #fff !important;
}
.header-pagination-sort {
  button {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 5px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover,
    &:focus {
      color: #495057; 
      background-color: #fff; 
      border-color: #ced4da; 
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); 
    }

    &:active {
      color: #495057; 
      background-color: #fff !important;
      border-color: #ced4da; 
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); 
    }
  }
  .dropdown-menu {
    padding-bottom: 1px;
    padding-top: 1px;
  }
}
.dropdown-section {
  position: relative;
  display: inline-block;
  cursor: pointer !important;
}
.dropdown-section:hover .dropdown-menu-list-app {
  display: block;
}
.dropdown-section:hover .ac-apps-button:hover {
    background-color: #eee7e7 !important;
}
.dropdown-menu-list-app {
  display: none;
  position: absolute;
  top: 135%;
  left: -20rem;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0;
  z-index: 1000;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid #dee2e6 !important;
}
.dropdown-menu-list-app::before {
  content: "";
  width: 100%;
  height: 12px;
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: transparent;
}







