.RFS-StepButton {

  &.active {
    background-color: $primary !important;
  }

  &.completed {
    background-color: #295c4b !important;
  }
}


.stepper {
    .nav-link {
      &.active {
        border-color: #fff #fff $primary #fff!important;
        border-bottom-width: 3px;
        border-left-width: 0;
        border-right: 0;
      }
    }
}
@media (max-width: 991px){
  #sidebar-user-wrapper {
    display: none !important;
  }
}
@media (min-width: 992px){
  #sidebar-user-wrapper {
    flex: 0 0 auto;
    max-width: 316px;
  }
  #profile-edit-wrapper {
    flex: 0 0 auto;
    max-width: calc(100% - 324px);
  }
}
.renewal-price-information, .price-information, .trial-noads-price-information {
  position: relative;
  cursor: pointer;
}

.renewal-price-information::after , .price-information::after, .trial-noads-price-information::after {
  content: attr(title); 
  visibility: hidden; 
  width: 432px; 
  background-color: black;
  color: white; 
  text-align: center; 
  border-radius: 4px; 
  padding: 10px; 
  position: absolute; 
  bottom: 125%; 
  left: 50%; 
  transform: translateX(-50%); 
  white-space: pre-line; 
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
  font-family: Lucida Grande,Lucida Sans,Arial,sans-serif;
  line-height: 20px;
}

.renewal-price-information:hover::after, .price-information:hover::after, .trial-noads-price-information:hover::after {
  visibility: visible; 
  opacity: 1; 
}

.renewal-price-information::after::before, .price-information::after::before,  .trial-noads-price-information::after::before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%; 
  transform: translateX(-50%);
  border-width: 10px; 
  border-style: solid;
  border-color: black transparent transparent transparent; 
}
