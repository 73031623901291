// Imports SCSS theme
// Vendors variables override and path
@import "themes/theme";
@import "vendors/variables";
@import "vendors/vendor";
@import "base/base";
@import "base/path";
@import "base/grid";
@import "components/index";
@import "themes/ac-fonts.scss";
// @import "base/placeholder";
// @import "base/utility";

@import "layout/user_layout";
@import "pages/index/index";
@import "pages/video/index";
@import "pages/user/index";
@import "pages/admin/index";
@import "pages/creator/reg.scss";
@import "pages/creator/apply.scss";
@import "pages/creator/creator.scss";

@import "themes/bootstrap-default.scss";
