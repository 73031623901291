.ac-footer .menu-title {
  color: #3e3e3f;
  text-transform: uppercase;
  font-weight: 700;
}

.ac-footer .menu-items-container {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  margin-top: 7px;
}

.ac-footer {
  & .menu-item,
  & .dropdown-toggle {
    color: $gray-700;
    margin-bottom: 7px;
    padding-left: 0;
    width: fit-content;
  }
}

.ac-footer .menu-item-dropdown {
  border: none;

  .dropdown-toggle {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    background: inherit;
    font-weight: 500;
    box-shadow: none !important;

    &:focus {
      color: $gray-700;
    }


    &:hover,
    &[aria-expanded="true"],
    &:active {
      opacity: 0.8;
      color: $gray-700;
      background: none;
    }
  }
}

.ac-footer .menu-item:hover {
  opacity: 0.8;
}

.ac-footer .footer-ac-social {
  flex: 1;
  display: flex;
  flex-direction: row;
  // justify-content: flex-end;
  align-items: flex-start;
  color: white;
}

.icon-social {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 17px;
  // border-radius: 50%;
  cursor: pointer;
  margin-right: 7px;
  // border-radius: 3px;
}

.icon-social:hover {
  opacity: 0.8;
}

.icon-social.facebook {
  background: #3b5998;
  border-radius: 0.25rem;
}

.icon-social {

  &.twitter_official,
  &.twitter {
    background: #0f1419;
    border-radius: 0.25rem;
  }
}

.ac-footer .footer-ac-social .icon-social.pinterest {
  background: #e60023;
}

.ac-footer .footer-ac-social .icon-social.instagram {
  background: #c32aa3;
}

.ac-footer .payment-methods {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.ac-footer .payment-methods .payment-image {
  height: 25px !important;
  margin-right: 7px;
  width: auto !important;
  min-width: unset !important;
  max-width: unset !important;
  max-height: unset !important;
  min-height: unset !important;
}

.ac-footer .payment-methods .payment-image.visa-blank {
  height: 15px !important;
}

.ac-footer .payment-methods .payment-image.jcb {
  height: 30px !important;
}

.ac-footer .payment-methods .payment-image.mastercard,
.ac-footer .payment-methods .payment-image.maestro,
.ac-footer .payment-methods .payment-image.mastercard-debit {
  height: 35px !important;
}

.ac-footer .payment-methods .payment-image.american {
  height: 40px !important;
}

.container-footer {
  background-color: #E6E6E6;
  padding-top: 24px;
  padding-bottom: 24px;

  @include md {
    // padding: 24px;
  }

  @include lg {
    padding: 100px 80px 100px 80px;
  }
}

.statistic {
  margin-bottom: 6px;

  &-data {
    font-size: 1rem;
  }
  
  &-title {
    span {
      color: $primary;
      font-weight: 600;
    }
  }
}

.column-menu-container {
  display: flex;
  flex-direction: column;

  @include sm {
    flex-direction: row;
    justify-content: space-between;
  }

}
.btn-socials button {
  border-radius: 5px;
  background: #333;
  border: none;
  height: 30px;
}
.btn-socials button:hover{
  background: #333;
}
#button-tooltip {
  margin-bottom: 5px;
}
.checkboxFeedback {
  label {
    margin-left: 3px;
  }
  input[type=checkbox] {
    display: none;
  }
  input[type=checkbox]+label::before {
    content: "";
    display: block;
    position: absolute;
    text-align: center;
    height: 20px;
    width: 20px;
    left: 0px;
    top: 0px;
    border-radius: 2px;
    border: 1px solid #c1c1c1;
  }
  input[type=checkbox]:checked+label::before {
    content: "✓";
    display: block;
    position: absolute;
    text-align: center;
    height: 20px;
    width: 20px;
    left: 0;
    top: 0px;
    color: white;
    background-color: #3c9275;
    border-radius: 2px;
    border: none;
  }
}
.checkboxFeedbackError {
  input[type=checkbox]+label::before {
    content: "";
    display: block;
    position: absolute;
    text-align: center;
    height: 20px;
    width: 20px;
    left: 0px;
    top: 0px;
    border-radius: 2px;
    border: 1px solid red;
  }
}

