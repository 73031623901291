.upload-kyc-container {
  p {
    margin-bottom: 4px;
  }
}

.upload-kyc {
    // max-width: 700px;
    &-item {
    height: 97px;
    
    &-preview-container {
      width: 200px;

      img {
        max-height: 80px;
        max-width: 200px;
        height: auto;
        width: auto;
      }

      .pdf-preview {
        font-size: 2rem;
      }
    }
  }
}
.modal-content {
  width: 125% !important;
}
.modal-header {
  font-weight: bold;
  background-color: #EBE8E6;
}
.modal-header-upload {
  .modal-header {
    font-weight: bold;
    background-color: #EBE8E6;
    padding-left: 39%;  
  }
}
.modal-footer {
  margin-left: auto;
  margin-right: auto;
  border-top: none !important;
}
.modal-title {
  font-weight: bold
}