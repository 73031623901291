.dropzone {
  // height      : 111px;
  // border: 2px dashed $primary;
  cursor: pointer;

  width: 100%;
  border: 2px solid $primary;
  height: 80px;

  &-dash {
    position: relative;
    overflow: hidden;
    border: none;
    // border: 2px dashed $primary;
  }

  &-dash::before {
    content: "";
    position: absolute;
    border: 8px dashed #ccc;
    top: -7px;
    bottom: -7px;
    left: -7px;
    right: -7px;
    // z-index: 9;
  }

  &-center {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
  }

  .drop-icon {
    font-size: 2rem;
    color: $gray-700;
  }
}
